const Calendar = () => (
  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.4375 0.75C3.65625 0.75 3.875 0.96875 3.875 1.1875V2.5H9.125V1.1875C9.125 0.96875 9.31641 0.75 9.5625 0.75C9.78125 0.75 10 0.96875 10 1.1875V2.5H10.875C11.832 2.5 12.625 3.29297 12.625 4.25V13C12.625 13.9844 11.832 14.75 10.875 14.75H2.125C1.14062 14.75 0.375 13.9844 0.375 13V4.25C0.375 3.29297 1.14062 2.5 2.125 2.5H3V1.1875C3 0.96875 3.19141 0.75 3.4375 0.75ZM11.75 6H8.90625V7.96875H11.75V6ZM11.75 8.84375H8.90625V11.0312H11.75V8.84375ZM11.75 11.9062H8.90625V13.875H10.875C11.3398 13.875 11.75 13.4922 11.75 13V11.9062ZM8.03125 11.0312V8.84375H4.96875V11.0312H8.03125ZM4.96875 13.875H8.03125V11.9062H4.96875V13.875ZM4.09375 11.0312V8.84375H1.25V11.0312H4.09375ZM1.25 11.9062V13C1.25 13.4922 1.63281 13.875 2.125 13.875H4.09375V11.9062H1.25ZM1.25 7.96875H4.09375V6H1.25V7.96875ZM4.96875 7.96875H8.03125V6H4.96875V7.96875ZM10.875 3.375H2.125C1.63281 3.375 1.25 3.78516 1.25 4.25V5.125H11.75V4.25C11.75 3.78516 11.3398 3.375 10.875 3.375Z"
      fill="currentColor"
    />
  </svg>
);

export default Calendar;

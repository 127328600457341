const moment = require('moment');
const { keys } = require('lodash');

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

const getStartAndEndDatesFromSegments = segments => {
  const endDate = moment.max(
    segments
      .map(({ endDate }) => endDate)
      .filter(a => !!a)
      .map(a => moment(a)),
  );
  const startDate = moment.min(
    segments
      .map(({ startDate }) => startDate)
      .filter(a => !!a)
      .map(a => moment(a)),
  );
  const dates = { startDate, endDate };
  keys(dates).map(k => (dates[k] = dates[k].utc().format('YYYY-MM-DD')));
  return dates;
};

const getFormattedDateDiffText = date => moment(date).fromNow(true);

module.exports = { getStartAndEndDatesFromSegments, getFormattedDateDiffText };

import { isNaN, map, get } from 'lodash';
import logger from 'itrvl-logger';

import { PAYMENT_METHOD } from 'itrvl-types';

import Dinero from './dinero';

const log = logger(__filename);

export const getWireDiscount = (itinerary, payment) => {
  const currency = get(itinerary, 'finance.currency', 'USD');
  if (!payment) {
    // No payment selected so no discount
    return Dinero({ amount: 0, currency });
  }
  const paymentType = payment.paymentType;
  // What percentage are we paying?
  const factor = get(payment, 'amount', 0) / get(itinerary, `finance.${paymentType}`, -1);
  // Did we calculate a valid factor?
  if (isNaN(factor)) {
    log.error(`zero payment for ${paymentType} on ${get(itinerary, 'id')}`);
    return Dinero({ amount: 0, currency });
  }
  if (factor <= 0) {
    log.error(`Missing itinerary.finance.${paymentType}:`, get(itinerary, 'finance'));
    return Dinero({ amount: 0, currency });
  }
  const feeCredit = Dinero({ amount: get(payment, 'serviceFee', 0), currency });
  const feeAch = Dinero({ amount: Math.round(get(itinerary, `finance.${paymentType}_fee_ach`, 0) * factor), currency });
  return feeCredit.subtract(feeAch);
};

export const getPaymentDiscount = (itinerary, payment, paymentMethod) => {
  const currency = get(itinerary, 'finance.currency', 'USD');
  if (!itinerary) {
    log.error('Missing itinerary when calculating payment discount.');
    return Dinero({ amount: 0, currency });
  }
  return paymentMethod === PAYMENT_METHOD.CREDIT ? Dinero({ amount: 0, currency }) : getWireDiscount(itinerary, payment);
};

export const toDinero = (itinerary, path) =>
  Dinero({ amount: get(itinerary, path, 0), currency: get(itinerary, 'finance.currency', 'USD') });

// Returns a map of currencies { USD: true, ZAR: true }
export const getItineraryCurrencies = itinerary => {
  const currencies = {};
  const tripCurrency = get(itinerary, 'finance.currency', 'USD');

  // We always need USD exchange because of displaying stuff in USD in the frontend
  if (tripCurrency !== 'USD') {
    currencies['USD'] = true;
  }

  map(Object.keys(get(itinerary, 'costs.supplier', {})), currency => {
    if (currency != tripCurrency) {
      currencies[currency] = true;
    }
  });

  map(Object.keys(get(itinerary, 'deposits.supplier', {})), currency => {
    if (currency != tripCurrency) {
      currencies[currency] = true;
    }
  });

  map(get(itinerary, 'itinerary.segments', []), segment => {
    const currency = get(segment, 'currency', tripCurrency);
    if (currency !== tripCurrency) {
      currencies[currency] = true;
    }

    // Now check extras on this segment for currencies. Fixes #1390
    const extras = get(segment, 'extras', []);
    map(extras, extra => {
      const extraCurrency = get(extra, 'currency', tripCurrency);
      if (extraCurrency !== tripCurrency) {
        currencies[extraCurrency] = true;
      }
    });
  });
  return Object.keys(currencies);
};

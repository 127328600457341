import React, { useContext } from 'react';

export const UserContext = React.createContext({
  app: null,
  user: null,
  client: null,
  updateUser: () => {},
  refreshUser: () => {},
  updateClient: () => {},
  isSuperAdmin: () => {},
  isLeadAgent: () => {},
});
UserContext.displayName = 'UserContext';

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};

export default UserContext;

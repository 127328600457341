const MinusCircle = () => (
  <svg viewBox="0 0 18 19" fill="none">
    <path
      d="M0 9.25C0 4.29297 4.00781 0.25 9 0.25C13.957 0.25 18 4.29297 18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25ZM5.90625 8.40625C5.41406 8.40625 5.0625 8.79297 5.0625 9.25C5.0625 9.74219 5.41406 10.0938 5.90625 10.0938H12.0938C12.5508 10.0938 12.9375 9.74219 12.9375 9.25C12.9375 8.79297 12.5508 8.40625 12.0938 8.40625H5.90625Z"
      fill="currentColor"
    />
  </svg>
);

export default MinusCircle;

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Typography, Fade } from '@mui/material';
import TravelerInput from './TravelerInput';
import Tooltip from 'components/v2/Tooltip';
import FillRoomButton from './FillRoomButton';

import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import ChildIcon from 'components/v2/Icons/Child';
import UserIcon from 'components/v2/Icons/User';

import { useBuilderStore } from './store';
import { useSegmentAssignedTravelers } from './hooks';
import { travelersToFillRoom } from './utils';

const GUTTER = 50;
const useStyles = makeStyles(theme => ({
  root: {
    // @todo: default text styles, use Typography?
    fontFamily: 'Atyp Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4285714286,
    color: '#000000',
    '& strong': {
      fontWeight: 500,
    },
    '& header': {
      position: 'relative',
      padding: GUTTER,
      '& h1': {
        margin: 0,
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: 1.333333333333333,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
        // @todo: var me?
        color: '#221F20',
      },
    },
    '& section': {
      /* ITRVL Palette / Stone Grey / 60% White */
      background: '#F8F7F5',
      padding: GUTTER,
      display: 'flex',
      flexDirection: 'column',
      gap: 23,
    },
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    lineHeight: 1,
    cursor: 'pointer',
    '& svg': {
      fontSize: 18,
    },
  },
  inputs: {
    display: 'flex',
    gap: 10,
    '& > div': {
      flex: 1,
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
  max: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  errorIcon: {
    fontSize: 18,
  },

  deleteBtn: {
    marginRight: 'auto',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },

  travelers: {
    borderBottom: '1px dotted #000',
    cursor: 'pointer',
  },
  tooltip: {
    fontSize: 12,
    padding: 5,
    display: 'block',
  },

  //
  types: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  adult: {
    '& svg': {
      width: 20,
    },
  },
  child: {
    '& svg': {
      width: 14,
    },
  },
  hasInfo: {
    borderBottom: `1px dotted ${theme.palette.primary.main}`,
  },
  fillRoomBtn: {
    marginLeft: 'auto',
  },
}));

const AddTravelersToRoomModal = NiceModal.create(function AddTravelersToRoomModal(props) {
  const { room, segmentId, edit, deleteRoom } = props;
  const classes = useStyles();
  const modal = useModal();

  const [children, setChildren] = useState(edit?.children || 0);
  const [adults, setAdults] = useState(edit?.adults || 0);

  const { minPax, normalAdults, normalChildren, guestNormal } = room;

  const addRoomToSegment = useBuilderStore(state => state.actions.accommodations.addRoomToSegment);
  const updateRoomOnSegment = useBuilderStore(state => state.actions.accommodations.updateRoomOnSegment);
  const assignedTravelers = useSegmentAssignedTravelers(segmentId);
  const tripAdults = useBuilderStore(state => state.data.adults);
  const tripChildren = useBuilderStore(state => state.data.children);

  const adultRemainder = tripAdults - assignedTravelers.adults - adults + (edit?.adults || 0);
  const childrenRemainder = tripChildren - assignedTravelers.children - children + (edit?.children || 0);

  const hasUsers = adults > 0;

  // max assignable adults based on current constraints
  const maxAdults = Math.min(normalAdults, tripAdults - assignedTravelers.adults + (edit?.adults || 0), guestNormal - children);
  // max assignable children based on current constraints
  // @todo: allowed to fill up to guestNormal with children?
  // Math.min(normalChildren)
  const maxChildren = Math.min(tripChildren - assignedTravelers.children + (edit?.children || 0), guestNormal - adults);

  const maxAdultsReached = adults >= normalAdults;
  const maxChildrenReached = children >= normalChildren;

  const onFillRoom = () => {
    const { adults, children } = travelersToFillRoom(room, adultRemainder, childrenRemainder);
    setAdults(adults);
    setChildren(children);
  };

  // we want the +/- input buttons to not shift when errors are conditionally rendered
  // this will ensure all errors are always rendered and occupy the same space, so no "pops" occur.
  // we sort by truthiness so the true conditions will appear first and fade in vs. non-true
  // never fade in an occupy space.
  // errors = [condition, string]
  const errors = [
    [minPax && adults + children < minPax, `This room type requires a minimum of ${minPax} traveler`],
    [maxAdultsReached, 'You have reached the maximum number of adults for this room type.'],
    [normalChildren > 0 && maxChildrenReached, 'You have reached the maximum number of children for this room type.'],
    [adults + children === guestNormal, 'You have reached the maximum travelers for this room.'],
    // sort by truthy
  ].sort((a, b) => Number(b[0]) - Number(a[0]));

  return (
    <Dialog
      open={modal.visible}
      onClose={modal.hide}
      fullWidth
      TransitionProps={{
        // tear down modal so state is fresh on remount
        onExited: modal.remove,
      }}
    >
      <Typography component="div" className={classes.root}>
        <header>
          <h1>
            {edit && 'Edit '}
            {room.name}
          </h1>
          Add the number of travelers that will occupy this room
          <div className={classes.close} onClick={modal.hide}>
            <CloseIcon />
          </div>
        </header>
        <section /*ref={parent}*/>
          <div className={classes.types}>
            <Tooltip
              placement="top"
              title={
                <span className={classes.tooltip}>
                  There are {adultRemainder} adult{adultRemainder > 1 ? 's' : ''} and {childrenRemainder} child
                  {childrenRemainder > 1 ? 'ren' : ''} who have not yet been assigned to a room at this accommodation
                </span>
              }
            >
              <span>
                <span className={classes.travelers}>Travelers needing a room assignment</span>:
              </span>
            </Tooltip>
            <div className={clsx(classes.type, classes.adult)}>
              {adultRemainder}
              <UserIcon />
            </div>
            <div className={clsx(classes.type, classes.child)}>
              {childrenRemainder}
              <ChildIcon />
            </div>
            {/* <strong>{remainder} Travelers</strong> */}

            <div className={classes.fillRoomBtn}>
              <FillRoomButton onClick={onFillRoom} disabled={maxAdultsReached || maxChildrenReached} ariaLabel={room.name} />
            </div>
          </div>
          <div className={classes.inputs}>
            <TravelerInput
              input={false}
              value={adults}
              label={`${adults} Adults`}
              increment={setAdults}
              decrement={setAdults}
              maximumValue={maxAdults}
              ariaLabel="Adults"
            />

            <Tooltip title={adults === 0 ? 'Rooms must have an adult before you can add a child' : ''}>
              <div className={clsx(adults === 0 && classes.hasInfo)}>
                <TravelerInput
                  disabled={normalChildren === 0 || adults === 0}
                  input={false}
                  value={children}
                  label={`${children} Children`}
                  increment={setChildren}
                  decrement={setChildren}
                  maximumValue={maxChildren}
                  ariaLabel="Children"
                />
              </div>
            </Tooltip>
          </div>
          <div>
            {errors.map(([condition, string], index) => (
              <Fade in={condition} key={index}>
                <div className={classes.max}>
                  <ErrorIcon className={classes.errorIcon} color="primary" /> {string}
                </div>
              </Fade>
            ))}
          </div>
          <div className={classes.controls}>
            {edit && (
              <Button
                className={classes.deleteBtn}
                onClick={() => {
                  deleteRoom();
                  modal.hide();
                }}
                variant="contained"
                disableElevation
              >
                Delete Room
              </Button>
            )}
            <Button onClick={modal.hide}>Back</Button>
            <Button
              variant="contained"
              disableElevation
              disabled={!hasUsers || (minPax && adults + children < minPax)}
              onClick={() => {
                edit
                  ? updateRoomOnSegment(segmentId, edit?.id, { adults, children })
                  : addRoomToSegment(segmentId, { room, adults, children });
                modal.hide();
              }}
            >
              {edit ? 'Update Travelers' : 'Add Travelers'}
            </Button>
          </div>
        </section>
      </Typography>
    </Dialog>
  );
});

export default AddTravelersToRoomModal;

import { OWN_ACCOMMODATION_ROOM_TYPE } from './itinerary';
import { SEGMENT_TYPE } from './segmentTypes';

export const isServiceOnDepartureDay = (dayOfService = 1, nights = 1) => (dayOfService > nights ? { OnDepartureDay: true } : {});

export const isDmcArranged = segment => segment.arrangedBy === 'DMC';
export const isOwnArranged = segment => segment.arrangedBy === 'Agent' || segment.roomType === OWN_ACCOMMODATION_ROOM_TYPE;

const serviceCodeToServiceType = segment => {
  const serviceTypeLookUp = {
    VG: 'Vehicle and Driver',
    TR: 'Transfer',
    SS: 'Sightseeing',
    PV: 'Private Vehicle',
    ML: 'Meal',
    MG: 'Meet and Greet',
    GD: 'Guide',
    EF: 'Entrance Fees',
  };

  if (segment.supplierCode && segment.supplierCode.length >= 5) {
    let val;
    try {
      const serviceCode = segment.supplierCode.toUpperCase().slice(3, 5);
      val = serviceTypeLookUp[serviceCode] ?? 'Other';
    } catch {
      val = 'Other';
    } finally {
      return val;
    }
  }
  return 'Other';
};
export const segmentTitle = (segment, data = {}) => {
  switch (segment.type) {
    case SEGMENT_TYPE.ROAD:
      switch (segment.linkedSystemId) {
        case SEGMENT_TYPE.BOAT:
          return 'Boat Transfer';
        case SEGMENT_TYPE.HELICOPTER:
          return 'Helicopter Transfer';
        case SEGMENT_TYPE.TRAIN:
          return 'Train Transfer';
        case SEGMENT_TYPE.WALK:
          return 'Walk Transfer';
        default:
          return 'Road Transfer';
      }
    case SEGMENT_TYPE.FLIGHT:
      return 'Flight';
    case SEGMENT_TYPE.STAY:
      return !!(data?.region && data.country) ? `Accommodation in ${data.region}, ${data.country}` : 'Fetching';
    case SEGMENT_TYPE.POINT:
      return 'Transit Point';

    default:
      return serviceCodeToServiceType(segment);
  }
};

const ChildX = () => (
  <svg viewBox="0 0 15 15">
    <path
      d="M7.4.5c-.9 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8S8.4.5 7.4.5zm0 2.6c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9zM12.9 2.7c-.2-.2-.5-.2-.6 0L9.5 5.5c-.6-.4-1.3-.7-2-.7-1.4.1-2.5.7-3.2 1.8l-1.2 2c-.1.2 0 .5.2.6.2.1.5 0 .6-.1l1.2-2c0-.1.1-.2.1-.3v2.9L2.1 13c-.2.2-.2.5 0 .6.2.2.4.2.6 0L5.2 11v3c0 .2.2.4.4.4s.4-.2.4-.4v-3h2.6v3.1c0 .2.2.4.4.4s.4-.2.4-.4V6.8c.3.1.3.2.4.3l1.2 2c.1.2.4.3.6.1.2-.1.3-.4.1-.6l-1.2-2c-.1-.2-.2-.4-.4-.5l2.8-2.8c.2-.2.2-.5 0-.6zm-4.2 7.4H6.1l2.6-2.6v2.6zm0-3.8L6.1 8.9V6.1c.4-.2.8-.4 1.3-.4s.9.1 1.3.4v.2z"
      fill="currentColor"
    />
  </svg>
);

export default ChildX;

const ChevronDown = () => (
  <svg viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.484375 0.226562C0.566406 0.117188 0.675781 0.0625 0.8125 0.0625C0.894531 0.0625 1.00391 0.0898438 1.08594 0.171875L6.47266 5.12109L11.8867 0.171875C12.0508 0.0078125 12.3242 0.0078125 12.4883 0.199219C12.6523 0.363281 12.6523 0.636719 12.4609 0.800781L6.77344 6.05078C6.60938 6.21484 6.36328 6.21484 6.19922 6.05078L0.511719 0.800781C0.320312 0.664062 0.320312 0.390625 0.484375 0.226562Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDown;

import React from 'react';
import retry from 'async-retry';

const opts = {
  retries: 5,
  minTimeout: 500,
};

function retryLazyLoad(fn, config) {
  return React.lazy(() => retry(fn, opts), config);
}

export default retryLazyLoad;

import { toPairs, forEach, isEmpty, cloneDeep, get } from 'lodash';

export const ITRVL = ['ITR006'];
export const JACADA = ['JAC007', 'JAC028', 'JAC031']; // JAC031 is the real deal.
export const YELLOW_ZEBRA = ['YEL002', 'YEL003'];

export const WILDERNESS = ['WIL290', 'WIL245', 'YEL003', 'WIL010'];
export const YELLOW_ZEBRA2 = ['YEL002'];

class Agency {
  constructor(props) {
    // map json values to actual instance keys
    forEach(toPairs(props), ([k, v]) => {
      this[k] = v;
    });
  }
}

class YellowZebra extends Agency {
  hasProblems(itinerary) {
    // make a copy for immutability
    const problems = cloneDeep(get(itinerary, 'problems', {}));
    // check if we have a margin problem
    if (get(problems, 'margins.total')) {
      delete problems.margins.total;
      if (isEmpty(problems.margins)) {
        delete problems.margins;
      }
    }
    // this is default logic
    return !isEmpty(problems);
  }
}

export const getAgency = agency => {
  if (YELLOW_ZEBRA.includes(agency.agencyCode)) {
    return new YellowZebra(agency);
  }
  return agency;
};

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';

import { noop } from 'common';

const styles = {
  root: {
    margin: '0 auto',
  },
};

function SingleFileUploadButton({ classes, type, subType, onFileDrop = noop, label }) {
  let accepted = '';
  switch (type) {
    case 'video':
      switch (subType) {
        case 'quicktime':
          accepted = 'video/quicktime';
          break;
        default:
          accepted = 'video/*';
          break;
      }
      break;
    case 'png':
      accepted = '.png';
      break;
    case 'image':
    default:
      accepted = 'image/*';
      break;
  }
  const { getInputProps, open } = useDropzone({
    accept: accepted,
    multiple: false,
    onDrop: onFileDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div className={classes.root}>
      <input {...getInputProps()} data-test-id={label} />
      <Button variant="link" className={clsx(classes.button, 'edit')} onClick={open} aria-label={`upload ${label}`}>
        {type === 'video' ? 'Upload Video' : 'Upload Photo'}
      </Button>
    </div>
  );
}

export default withStyles(styles)(SingleFileUploadButton);

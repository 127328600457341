import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { groupBy, map, size, orderBy, reduce } from 'lodash';
import { OWN_ACCOMMODATION_SUPPLIER_CODE, rateKey } from 'itrvl-types';
import { CircularProgress } from '@mui/material';
import RoomCard from './RoomCard';
import SupportIcon from './icons/Support';
import { useBuilderStore } from './store';
import { useRoomsForSupplierQuery, useInclusionsBySupplierQuery } from './queries';
import { useQuoteOnly, useSupplierBounds } from './hooks';
import StayOverview from './StayOverview';

import logger from 'itrvl-logger';

const log = logger(__filename);
log.trace(__filename);

const GUTTER = 37;
const useStyles = makeStyles(
  theme => ({
    root: {
      inset: 0,
      backgroundColor: '#f7f4f1',
      zIndex: 3,
      overflowY: 'auto',
      // so we can scroll the container with the horizontal nav below
      paddingBottom: 100,
      position: 'fixed',
      // unsure why this is needed, chrome was crossing out the style
      top: '69px !important',
      '& .ib2-ac-current-selection': {
        '& > div > span': {
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
        },
      },
    },
    content: {
      position: 'static',
    },
    hero: {
      position: 'relative',
      display: 'flex',
      gap: 40,
      padding: GUTTER,
    },
    data: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      gap: 40,
    },
    info: {
      position: 'relative',
      flex: 1,
      '& h1': {
        color: '#fff',
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 48,
        lineHeight: 1.1666666667,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
    },
    back: {
      color: '#fff',
    },
    image: {
      position: 'absolute',
      inset: 0,
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    overlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    calendar: {
      position: 'relative',
      backgroundColor: '#fff',
    },
    // about
    about: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
      gap: 80,
      padding: GUTTER,
      borderBottom: '1px solid #E9E1D7',
      '& h2': {
        margin: 0,
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 36,
        lineHeight: 1.1666666667,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
    },
    //
    rooms: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    room: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      justifyContent: 'flex-end',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
    },
    support: {
      paddingBottom: 50,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      '& svg': {
        color: theme.palette.primary.main,
        width: 40,
      },
    },
    features: {
      display: 'flex',
      gap: 20,
    },
    featuresSection: {
      paddingTop: 25,
      marginTop: 12,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    onlineIcon: {
      width: 25,
      height: 'auto',
    },
  }),
  {
    name: 'ItineraryBuilderDetailsContent',
  },
);

const SelectRoomsContent = props => {
  const classes = useStyles();
  const { id } = props;
  const segment = useBuilderStore(state => state.data.segmentsById[id]);
  const { supplierCode, dmcArrangedOnly } = segment;
  const isQuoteOnly = useQuoteOnly();
  const { startBound, endBound } = useSupplierBounds(supplierCode);
  const roomsQuery = useRoomsForSupplierQuery(supplierCode, {
    startDate: segment.startDateString,
    endDate: segment.endDateString,
  });

  const selectedRoomsMap = reduce(
    segment.rooms,
    (acc, room) => {
      const key = rateKey(room.room);
      if (!acc?.[key]) {
        acc[key] = 1;
      } else {
        acc[key]++;
      }
      return acc;
    },
    {},
  );

  const availableRooms = useMemo(() => {
    return roomsQuery?.data?.[supplierCode]?.Any?.options || [];
  }, [roomsQuery?.data, supplierCode]);

  const inclusionsQuery = useInclusionsBySupplierQuery(supplierCode, startBound, endBound);

  const roomsByType = useMemo(() => {
    let data = availableRooms;
    data = orderBy(data, ['systemType', 'name'], ['desc', 'asc']);
    data = groupBy(data, 'systemType');
    // rename _Double to Double
    if (data._Double) {
      data.Double = data._Double;
      delete data._Double;
    }
    return data;
  }, [availableRooms]);

  const hasNoAvailableRooms = roomsQuery?.data && size(roomsQuery.data) === 0;
  const noAvailableRoomSelections = dmcArrangedOnly || hasNoAvailableRooms;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <StayOverview segment={segment} />
      <div className="inner" style={{ display: 'flex', flexDirection: 'column', gap: 20 }} data-test-id="rooms-types-container">
        {noAvailableRoomSelections && supplierCode !== OWN_ACCOMMODATION_SUPPLIER_CODE && (
          <div className={classes.support}>
            <SupportIcon /> Please contact your agent to discuss room booking options at this accommodation.
          </div>
        )}
        {!noAvailableRoomSelections && roomsQuery.isFetching && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        {!noAvailableRoomSelections && !roomsQuery.isFetching && size(availableRooms) > 0 && (
          <>
            <h3 style={{ margin: 0 }}>Available Room Types ({size(availableRooms)})</h3>
            {map(roomsByType, (rooms, key) => {
              return (
                <div key={key} data-test-id={`room-type-${key}`} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                  <h4 style={{ margin: 0 }}>
                    {key} ({size(rooms)})
                  </h4>
                  <div className={classes.rooms}>
                    {map(rooms, room => {
                      const key = rateKey(room);
                      return (
                        <div className={classes.room} key={key} data-test-id={`room-card-${key}`}>
                          <RoomCard
                            canFill={segment?.supplierCode === supplierCode}
                            segmentId={segment?.id}
                            disabled={room.disabled}
                            numAlreadySelected={selectedRoomsMap?.[key] || 0}
                            hasDates={segment?.startDate && segment?.endDate}
                            room={room}
                            key={room.roomTypeId}
                            inclusionsLoading={inclusionsQuery.isFetching}
                            inclusions={inclusionsQuery?.data && inclusionsQuery.data[key]}
                            isQuoteOnly={isQuoteOnly}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default SelectRoomsContent;

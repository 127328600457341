import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { size } from 'lodash';

import ItineraryDrawer from './ItineraryDrawer';
import Header from './Header';
import InfoSection from './InfoSection';
import SubFilters from './SubFilters';
import Content from './Content';

import { useBuilderStore } from './store';
import { useApi } from 'common/hooks/api';
import { useUser } from 'common/hooks/user';
import { useGetQuoteMutation, useAccommodationsListQuery, useCopyToBuilderQuery, useCopyToBuilderOwnQuery } from './queries';
import { FEATURE_FLAG } from 'itrvl-types';
import { ownAccommodationProps } from './AccommodationsList';
import { useAutoPlaceTwoTravelers } from './hooks';

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: '#f7f4f1',
      flex: 1,
      position: 'relative',
      fontFamily: 'Atyp Display',
      '& .inner': {
        maxWidth: 1440,
        margin: '0 auto',
      },
      // @todo: spread this out to where it's used
      '& .hoverable': {
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
        cursor: 'pointer',
      },
    },
    loadingOverlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(255,255,255,0)',
      zIndex: 5,
    },
    '@global': {
      '*': {
        boxSizing: 'border-box',
      },

      // tooltips
      '.tippy-box[data-theme~="ib2"]': {
        backgroundColor: '#292929',
        '& .tippy-arrow': {
          color: '#292929',
        },
      },

      // snackbars
      '[class*="SnackbarItem-variantSuccess"]': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
  {
    name: 'ItineraryBuilder',
  },
);

function ItineraryBuilder() {
  const classes = useStyles();
  const Api = useApi();

  useAccommodationsListQuery();

  const toggleAccommodationPin = useBuilderStore(state => state.actions.accommodations.toggleAccommodationPin);
  const copyToBuilderQuery = useCopyToBuilderQuery();
  const copyToBuilderOwnQuery = useCopyToBuilderOwnQuery();
  const setCampSegment = useBuilderStore(state => state.actions.copyToBuilder.setCampSegment);
  const setOwnSegment = useBuilderStore(state => state.actions.copyToBuilder.setOwnSegment);
  const clearPin = useBuilderStore(state => state.actions.copyToBuilder.clearPin);
  const clearOwn = useBuilderStore(state => state.actions.copyToBuilder.clearOwn);
  useEffect(() => {
    if (copyToBuilderQuery.isLoading) return;
    for (let camp of copyToBuilderQuery.data) {
      toggleAccommodationPin(camp, { keep: true });
      setCampSegment(camp);
    }
    if (copyToBuilderQuery.data.length) clearPin();
  }, [copyToBuilderQuery, toggleAccommodationPin, setCampSegment, clearPin]);
  useEffect(() => {
    if (copyToBuilderOwnQuery.isLoading) return;
    if (copyToBuilderOwnQuery.data.length) toggleAccommodationPin(ownAccommodationProps, { keep: true });
    for (let region of copyToBuilderOwnQuery.data) {
      setOwnSegment(region);
    }

    if (copyToBuilderOwnQuery.data.length) clearOwn();
  }, [copyToBuilderOwnQuery, toggleAccommodationPin, setOwnSegment, clearOwn]);

  const setCountries = useBuilderStore(state => state.actions.countries.setCountries);

  const mutation = useGetQuoteMutation();
  const user = useUser();

  /**
   * This fires the initial background fetch for the RegionSelector
   * @todo: refactor to useQuery
   */
  useEffect(() => {
    (async () => {
      // @todo: we should filter based on regions that actually have camps via API, filtering in memory for now...
      // should probably filter out camps that have no regions with no camps either

      // @todo: filter out "unknown country"? Z0
      const response = await Api.getCountries({
        where: {
          ...(user?.agency?.[FEATURE_FLAG.REST_OF_WORLD] !== true
            ? {
                restOfWorld: {
                  neq: true,
                },
              }
            : {}),
        },
        fields: {
          countryCode: true,
          name: true,
        },
        order: 'name ASC',
        include: {
          relation: 'regions',
          scope: {
            fields: ['name', 'countryRegionCode'],
            include: {
              relation: 'camps',
              scope: {
                fields: ['supplierCode', 'campName', 'countryRegionCode'],
                where: {
                  disabled: {
                    neq: true,
                  },
                },
              },
            },
          },
        },
      });

      // @todo: it'd be nice to only return data and not the response
      const countries = response?.data || [];

      const options = countries
        .filter(country => size(country?.regions || []) > 0)
        .map(country => {
          const children = (country?.regions || [])
            .filter(region => size(region?.camps || []) > 0)
            .map(region => {
              return {
                label: region.name,
                value: region.countryRegionCode,
                parentValue: country.countryCode,
              };
            });
          if (size(children)) {
            children.unshift({
              parentValue: country.countryCode,
              label: 'Add all',
              value: 'all-children',
              alternateLabel: 'Remove all',
              values: children.slice(),
            });
          }
          return {
            label: country.name,
            value: country.countryCode,
            children,
          };
        });
      setCountries(options);
    })();
  }, [user?.agency, setCountries, Api]);

  // @todo: refactor to useQuery
  // useEffect(() => {
  //   fetchAccommodations(term);
  // }, [term, fetchAccommodations]);

  // useAccommodationsListQuery();
  useAutoPlaceTwoTravelers();

  return (
    <div className={classes.root}>
      {(mutation?.isLoading || copyToBuilderQuery.isLoading || copyToBuilderOwnQuery.isLoading) && (
        <div className={classes.loadingOverlay} />
      )}
      <Header />
      <InfoSection />
      <SubFilters />
      <Content />
      <ItineraryDrawer />
    </div>
  );
}

export default ItineraryBuilder;

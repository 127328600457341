import CalendarIcon from './icons/Calendar';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateRange } from 'utils/dates';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  icon: {
    lineHeight: 1,
  },
});

export default function SegmentDates({ segment = {} }) {
  const { startDate, endDate } = segment;
  const classes = useStyles();
  const dateText = formatDateRange(startDate, endDate, { appendSameYear: true });
  return (
    <div className={classes.root} data-test-id="stay-date">
      <div className={classes.icon}>
        <CalendarIcon />
      </div>
      {dateText || 'No Dates Selected'}
    </div>
  );
}

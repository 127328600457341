import { useContext } from 'react';
import { CircularProgress } from '@mui/material';
import { useGetAgentDetailsQuery } from 'api';
import { UserContext } from 'common';
import { useParams } from 'react-router-dom';

import clsx from 'clsx';
import MyProfileMain from './MyProfileMain';
import sharedStyles from 'styles/shared';
import logger from 'itrvl-logger';
const log = logger(__filename);

function MyProfileResolver() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const loggedInUserId = user?.userId;
  const s = sharedStyles();
  const agentId = id || loggedInUserId;
  const isEditingOwnProfile = agentId === loggedInUserId;
  const { data, isLoading, isError } = useGetAgentDetailsQuery(agentId);

  if (isError) {
    log.error(JSON.stringify(isError));
    return <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Unable to locate agent.</div>;
  }

  if (isLoading) {
    return (
      <div className={clsx(s.cover, s.flex, s.itemsCenter, s.justifyCenter)}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  return <MyProfileMain agent={data} agentId={agentId} isEditingOwnProfile={isEditingOwnProfile} />;
}

export default function MyProfile({ agentId }) {
  return <MyProfileResolver agentId={agentId} />;
}

import { saveAs as fsSaveAs } from 'file-saver';
import ReactGA from 'react-ga4';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const saveAs = (blob, name, opts) => {
  if (process.env.REACT_APP_GA4_KEY) {
    // https://support.google.com/analytics/answer/9216061
    // https://support.google.com/analytics/answer/9234069
    ReactGA.event('file_download', {
      file_extension: name?.split('.').pop(), // eslint-disable-line camelcase
      file_name: name, // eslint-disable-line camelcase
      //link_classes,
      //link_domain,
      //link_id,
      //link_text,
      //link_url,
    });
  }

  return fsSaveAs(blob, name, opts);
};

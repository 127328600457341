import React from 'react';
import { Dinero } from 'itrvl-pricing';

const RoomCost = ({ cost }) => {
  return (
    <>{cost ? <>{Dinero({ amount: Math.round(cost * 100), currency: 'USD' }).toFormat('$0,0')} per person</> : 'No pricing available'}</>
  );
};

export default RoomCost;

export const SEGMENT_TYPE = {
  ENTRY: 'entry',
  EXIT: 'exit',
  FLIGHT: 'flight',
  CHARTERED: 'chartered',
  SCHEDULED: 'scheduled',
  POINT: 'point',
  PRICE: 'price',
  ROAD: 'road',
  SERVICE: 'service',
  STAY: 'stay',

  BOAT: 'boat', // Road subtype.
  TRAIN: 'train', // Road subtype.
  WALK: 'walk', // Road subtype.
  HELICOPTER: 'helicopter', // Road subtype.
};

export const FLIGHT_TYPES = [SEGMENT_TYPE.FLIGHT, SEGMENT_TYPE.CHARTERED, SEGMENT_TYPE.SCHEDULED];

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import BaseModal from 'components/v2/Modals/BaseModal';
import { useBuilderStore } from './store';
import SegmentDates from './SegmentDates';
import { Paper, TextField } from '@mui/material';
import { useState } from 'react';
import RegionAutocomplete from './RegionAutocomplete';
import { toPairs } from 'lodash';

export default NiceModal.create(function SelectLocationModal() {
  const modal = useModal();
  const ownAccommodations = useBuilderStore(state => state.ownAccommodations());
  const patchSegment = useBuilderStore(state => state.actions.accommodations.patchSegment);
  // could be a form, if we want error handling
  const [data, setData] = useState(
    ownAccommodations.reduce((acc, val) => {
      acc[val.id] = {
        title: val.title ?? '',
        // expected data structure for autocomplete
        region: val.regionCode && val.regionName ? { regionCode: val.regionCode, name: val.regionName } : null,
      };
      return acc;
    }, {}),
  );

  const onConfirm = () => {
    // override all
    toPairs(data).forEach(([k, v]) => {
      patchSegment(k, {
        title: v.title,
        regionCode: v.region?.regionCode,
        regionName: v.region?.name,
        country: v.region?.country?.name,
      });
    });
    modal.hide();
  };

  return (
    <BaseModal
      title="SELECT LOCATION FOR OWN ACCOMMODATION"
      subtext="Specify a location and name Own Accommodation stays"
      maxWidth="lg"
      fullWidth
      confirmText="Done"
      onConfirm={onConfirm}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        {ownAccommodations.map(segment => {
          return (
            <div key={segment.id} data-test-id={`segment-${segment.id}`}>
              <Paper
                sx={{
                  padding: '20px 40px',
                  border: '2px solid #718588',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '20px',
                }}
                elevation={0}
              >
                <SegmentDates segment={segment} />
                <RegionAutocomplete
                  value={data[segment.id].region}
                  onChange={(_event, value) => {
                    setData(data => ({ ...data, [segment.id]: { ...data[segment.id], region: value, country: value?.country?.name } }));
                  }}
                />
                <TextField
                  variant="standard"
                  placeholder="Add a description"
                  value={data[segment.id].title}
                  onChange={e => {
                    setData(data => ({ ...data, [segment.id]: { ...data[segment.id], title: e?.target?.value } }));
                  }}
                  inputProps={{ 'aria-label': 'add a description' }}
                />
              </Paper>
            </div>
          );
        })}
      </div>
    </BaseModal>
  );
});

import React, { useContext } from 'react';

export const ItinerariesContext = React.createContext({
  itineraries: null,
  refreshItineraries: () => {},
});

export const useItineraries = () => {
  const context = useContext(ItinerariesContext);
  return context;
};

ItinerariesContext.displayName = 'ItinerariesContext';

export default ItinerariesContext;

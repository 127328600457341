import { useCallback, useEffect, useMemo, useRef } from 'react';
import { forEach, reduce, size } from 'lodash';
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { queryClient } from 'components/ItineraryBuilder/ItineraryBuilderEntry';
// @todo: deprecate
import { QUOTE_ONLY_MODE } from './constants';
import { formatDate, generateDatesByWeek, getCalendarBounds } from './utils';
import { useBuilderStore } from './store';
import { useApi } from 'common/hooks/api';
import { weekByWeekDefaultQueryArgs, useCampFeaturesMapQuery } from './queries';
import { useMountedState, useUnmount, useRafLoop, useRafState, useSearchParam } from 'react-use';
import logger from 'itrvl-logger';
import { CircularProgress } from '@mui/material';
const log = logger(__filename);
log.trace(__filename);

export const useSegmentsOriginal = () => {
  const segmentsOriginal = useBuilderStore(state => state.data.segmentsOriginal);
  return segmentsOriginal;
};

export const useSegments = () => {
  const segmentsById = useBuilderStore(state => state.data.segmentsById);
  const segmentIds = useBuilderStore(state => state.data.segmentIds);
  return useMemo(() => segmentIds.map(id => segmentsById[id]).filter(Boolean), [segmentsById, segmentIds]);
};

export const useSupplierSegments = supplierCode => {
  const segments = useSegments();
  return useMemo(() => segments.filter(segment => segment.supplierCode === supplierCode), [segments, supplierCode]);
};

export const useSegmentAssignedTravelers = segmentId => {
  const segment = useBuilderStore(state => state.data.segmentsById[segmentId]);
  return useMemo(() => {
    return reduce(
      segment?.rooms,
      (totals, room) => {
        totals.adults += room.adults || 0;
        totals.children += room.children || 0;
        return totals;
      },
      {
        adults: 0,
        children: 0,
      },
    );
  }, [segment.rooms]);
};

export const useSegmentRemainingTravelers = segmentId => {
  const adults = useBuilderStore(state => state.data.adults);
  const children = useBuilderStore(state => state.data.children);
  const segment = useBuilderStore(state => state.data.segmentsById[segmentId]);
  return useMemo(() => {
    return reduce(
      segment?.rooms,
      (totals, room) => {
        totals.adults -= room.adults || 0;
        totals.children -= room.children || 0;
        return totals;
      },
      {
        adults: adults || 0,
        children: children || 0,
      },
    );
  }, [segment?.rooms, adults, children]);
};

export const useSupplierBounds = supplierCode => {
  const globalDate = useBuilderStore(state => state.data.date);
  const supplierMonth = useBuilderStore(state => state.ui.supplierMonth);
  const date = supplierMonth?.[supplierCode] || globalDate || new Date();
  const [startBound, endBound] = getCalendarBounds(date);
  return { date, startBound, endBound };
};

export const useCustomMutation = (mutationKey, mutationFn, options) => {
  const query = useQuery(['CustomMutation', mutationKey], async () => await Promise.resolve(false), {
    retry: false,
    cacheTime: Number.Infinity,
    staleTime: Number.Infinity,
  });
  const queryError = useQuery(['CustomMutationError', mutationKey], async () => await Promise.resolve(false), {
    retry: false,
    cacheTime: Number.Infinity,
    staleTime: Number.Infinity,
  });
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (...params) => {
      queryClient.setQueryData(['CustomMutationError', mutationKey], false);
      return await mutationFn(...params);
    },
    ...Object.assign(Object.assign({}, options), {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(['CustomMutation', mutationKey], data);
        if (options === null || options === void 0 ? void 0 : options.onSuccess) options.onSuccess(data, variables, context);
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(['CustomMutationError', mutationKey], err);
        if (options === null || options === void 0 ? void 0 : options.onError) options.onError(err, variables, context);
      },
    }),
  });
  const isLoading = useIsMutating(mutationKey);
  // We need typecasting here due the ADT about the mutation result, and as we're using a data not related to the mutation result
  // The typescript can't infer the type correctly.
  return Object.assign(Object.assign({}, mutation), {
    data: query.data,
    isLoading: !!isLoading,
    error: queryError.data,
    isError: !!queryError.data,
  });
};

export const useQuoteOnly = () => {
  const quoteMode = useBuilderStore(state => state.data.quoteMode);
  return quoteMode === QUOTE_ONLY_MODE;
};

export const usePrefetchMonth = supplierCode => {
  const Api = useApi();
  const isQuoteOnly = useQuoteOnly();
  const adults = useBuilderStore(state => state.data.adults);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);

  return useCallback(
    (dates, fromStart) => {
      forEach(generateDatesByWeek(dates, fromStart), date => {
        const dateString = formatDate(date);
        queryClient.prefetchQuery(weekByWeekDefaultQueryArgs(supplierCode, dateString, adults, childrenAges, [], Api, !isQuoteOnly));
      });
    },
    [Api, supplierCode, isQuoteOnly, adults, childrenAges],
  );
};

export const useBackgroundAvailabilityPoll = supplierCode => {
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useRafState(false);
  useRafLoop(() => {
    const isFetching = queryClient.isFetching({ queryKey: ['availability', supplierCode] }) > 0;
    if (isFetching !== isLoading) {
      isMounted() && setIsLoading(isFetching);
    }
  });
};

export const useCampFeatures = (featureIds = []) => {
  const query = useCampFeaturesMapQuery();
  let features = [];
  if (size(featureIds) > 0 && query.isFetched && size(query.data) > 0) {
    features = featureIds.map(featureId => query.data[featureId]).filter(Boolean);
  }
  return {
    ...query,
    features,
  };
};

/**
 * Identifies changes between previous and current sets of segments.
 *
 * @param {Array<Object>} prevSegments - The previous array of segment objects.
 * @param {Array<Object>} currentSegments - The current array of segment objects.
 * @param {Array<string>} [changeTypes=['added', 'modified']] - The types of changes to track. Options are:
 *    - 'added': Detect segments that are in `currentSegments` but not in `prevSegments`.
 *    - 'modified': Detect segments that exist in both but have different values.
 * @returns {Array<Object>} An array of change objects, each containing:
 *    - `type` {string}: The type of change ('added' or 'modified').
 *    - `segment` {Object}: The segment object that was added or modified.
 */
export function getChangedSegments(prevSegments, currentSegments, changeTypes = ['added', 'modified']) {
  const changes = [];

  const prevSegmentsMap = new Map(prevSegments.map(segment => [segment.id, segment]));
  const currentSegmentsMap = new Map(currentSegments.map(segment => [segment.id, segment]));

  // Detect added and modified segments
  for (const [id, currentSegment] of currentSegmentsMap.entries()) {
    const prevSegment = prevSegmentsMap.get(id);

    if (!prevSegment && changeTypes.includes('added')) {
      changes.push({ type: 'added', segment: currentSegment });
    } else if (prevSegment && !areSegmentsEqual(prevSegment, currentSegment) && changeTypes.includes('modified')) {
      changes.push({ type: 'modified', segment: currentSegment });
    }
  }

  return changes;
}

function areSegmentsEqual(seg1, seg2) {
  return seg1.startDate === seg2.startDate && seg1.endDate === seg2.endDate && seg1.supplierCode === seg2.supplierCode;
}

export const useFetchAvailability = () => {
  const Api = useApi();

  // @todo: this is for testing, remove me
  const slowParam = useSearchParam('slow');
  const slow = slowParam !== null;

  return useCallback(
    async segment => {
      let { supplierCode, startDate, endDate } = segment;
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);

      // @todo: for testing, remove me
      if (slow) {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve({});
          }, 5000);
        });
      }

      const response = await Api.getRoomsBySupplierWithDates(supplierCode, startDate, endDate);
      return response.data;
    },
    [Api, slow],
  );
};

export function processAvailabilityData(segment, data = {}, isQuoteOnly) {
  const { supplierCode, startDate, endDate, rooms = [] } = segment;
  const availability = data[supplierCode];

  // Early return if availability data is missing
  if (!availability) {
    return { room: undefined, supplierCode, startDate, endDate, rooms };
  }

  const bypassAvailability = isQuoteOnly;
  let room;

  for (const target of ['Twin', '_Double']) {
    const defaultOption = availability?.[target]?.default;
    if (defaultOption) {
      const { optionKey: defaultOptionKey, roomTypeId: defaultRoomTypeId } = defaultOption;
      if (defaultOptionKey && defaultRoomTypeId) {
        const options = availability?.Any?.options || [];

        const match = options.find(
          // @todo: room.disabled is kind of broken at the moment and is unhandled here. We are just matching on count only for the time being
          // once we handle this edge case, we can add logic in here to determine how we want to match the rooms based on bypassAvailability or not
          o => {
            // -1 is unknown, so should be allowed if quote only mode
            // -2 means on request, so I guess we’re supposed to auto-place them even if not in quote only mode in those cases.
            // -3 is closed, i think should be denied, regardless of quote only.
            const hasAvailability = (bypassAvailability && o.count >= -2) || (!bypassAvailability && (o.count > 0 || o.count === -2));
            return o.optionKey === defaultOptionKey && o.roomTypeId === defaultRoomTypeId && hasAvailability;
          },
        );

        if (match) {
          room = match;
          break;
        }
      }
    }
  }

  return { room, supplierCode, startDate, endDate, rooms };
}

/**
 * Custom React hook that automatically assigns two travelers to a room when a segment is added or modified,
 * given that there are exactly two adults and no children in the booking.
 *
 * - Monitors the number of adults and children in the booking, acting only when there are 2 adults and 0 children.
 * - Listens for changes to travel segments (added or modified).
 * - Initiates an availability check for the affected segment(s)
 * - If availability data includes a suitable room ('Twin' or '_Double') with matching criteria, assigns the travelers to that room.
 * - Manages asynchronous requests to handle outdated responses and prevent race conditions.
 * - Provides user feedback via snackbars during the process (e.g., "Attempting to place travelers", "Travelers assigned to room", "No rooms available").
 * - Spawns a loading toast if the initial or subsequent requests take longer than 1000ms
 *
 * **Cleanup:**
 * - Clears pending timers and requests when the component using this hook unmounts.
 *
 * **Usage:**
 * - Intended to be used within the IB2 component hierarchy and is dependent on its data structures
 *
 * @param {integer} delay - delay before showing "pending toast", defaults to 1000
 */
export function useAutoPlaceTwoTravelers({
  delay = 1000,
  segmentsHook = useSegments,
  isQuoteOnlyHook = useQuoteOnly,
  builderStoreHook = useBuilderStore,
  fetchAvailabilityHook = useFetchAvailability,
  snackbarHook = useSnackbar,
} = {}) {
  const segments = segmentsHook();
  const isQuoteOnly = isQuoteOnlyHook();
  const adults = builderStoreHook(state => state.data.adults);
  const children = builderStoreHook(state => state.data.children);
  const setDefaultRoom = builderStoreHook(state => state.actions.segments.setDefaultRoom);
  const fetchAvailability = fetchAvailabilityHook();
  const { enqueueSnackbar, closeSnackbar } = snackbarHook();

  // for segment change handling
  const prevSegmentsRef = useRef();
  // for request tracking
  const pendingRequestsRef = useRef({});

  // clear all timers when we unmount the hook
  useUnmount(() => {
    Object.values(pendingRequestsRef.current).forEach(({ timeoutId }) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    });
  });

  const handleAvailabilitySuccess = useCallback(
    (segmentId, data, requestId) => {
      const pending = pendingRequestsRef.current[segmentId];

      if (!pending || pending.requestId !== requestId) {
        return;
      }

      if (pending.timeoutId) {
        clearTimeout(pending.timeoutId);
      }

      if (pending.attemptingSnackbarKey) {
        closeSnackbar(pending.attemptingSnackbarKey);
      }

      const { segment } = pending;
      const { room, supplierCode, startDate, endDate } = processAvailabilityData(segment, data, isQuoteOnly);

      if (room && size(segment.rooms) === 0) {
        enqueueSnackbar(`Travelers assigned to room.`, {
          variant: 'success',
        });
      }

      const sok = segment.rooms?.[0]?.room?.optionKey;
      const srt = segment.rooms?.[0]?.room?.roomTypeId;
      if (room && sok && sok !== room.optionKey && srt && srt !== room.roomTypeId) {
        enqueueSnackbar(`Travelers room assignment changed.`, {
          variant: 'success',
        });
      }

      if (!room && size(segment.rooms) > 0) {
        enqueueSnackbar(`Room match not found.`, {
          variant: 'info',
        });
      }

      setDefaultRoom({
        supplierCode,
        startDate,
        endDate,
        room,
      });

      // Clean up the pending request entry
      delete pendingRequestsRef.current[segmentId];
    },
    [setDefaultRoom, enqueueSnackbar, closeSnackbar, isQuoteOnly],
  );

  const handleAvailabilityError = useCallback(
    (segmentId, error, requestId) => {
      log.error('error with auto-place travelers: ', error);
      const pending = pendingRequestsRef.current[segmentId];
      if (!pending || pending.requestId !== requestId) {
        return;
      }

      if (pending.timeoutId) {
        clearTimeout(pending.timeoutId);
      }

      // remove pending snackbar if it was shown
      if (pending.attemptingSnackbarKey) {
        closeSnackbar(pending.attemptingSnackbarKey);
      }

      enqueueSnackbar(`No rooms available.`, {
        variant: 'error',
      });

      // Clean up the pending request entry
      delete pendingRequestsRef.current[segmentId];
    },
    [enqueueSnackbar, closeSnackbar],
  );

  useEffect(() => {
    // this only applies to 2 adults, 0 children, only
    // do nothing if this is the case
    if (!(adults === 2 && children === 0)) {
      return;
    }

    const prevSegments = prevSegmentsRef.current;
    prevSegmentsRef.current = segments;

    if (prevSegments === undefined) {
      return;
    }

    const changedSegments = getChangedSegments(prevSegments, segments, ['added', 'modified']);

    if (changedSegments.length > 0) {
      changedSegments.forEach(({ segment }) => {
        const { id: segmentId, consultantInteractionRequired, dmcArrangedOnly, assigned } = segment;

        // do nothing in these cases
        if (consultantInteractionRequired || dmcArrangedOnly || assigned === true) {
          return;
        }

        const pendingRequest = pendingRequestsRef.current[segmentId];
        // Generate a unique requestId for this request
        const requestId = Date.now() + Math.random();

        if (pendingRequest) {
          // Segment is already pending; update the segment data and requestId
          pendingRequest.segment = segment;
          pendingRequest.requestId = requestId;
          // No need to start a new toast
        } else {
          // Start the {delay} timer to show the 'Attempting to place travelers' toast
          const timeoutId = setTimeout(() => {
            // Show 'Attempting to place travelers' snackbar
            const attemptingSnackbarKey = enqueueSnackbar('Attempting to place travelers.', {
              persist: true,
              variant: 'default',
              key: `attempting-${segmentId}`,
              action: () => <CircularProgress size={18} color="inherit" style={{ marginRight: 10 }} />,
            });

            pendingRequestsRef.current[segmentId] = {
              ...pendingRequestsRef.current[segmentId],
              attemptingSnackbarKey,
            };
          }, delay);

          pendingRequestsRef.current[segmentId] = {
            segment,
            timeoutId,
            requestId,
          };
        }

        // Initiate the GET request to check availability
        fetchAvailability(segment /*, requestId*/)
          .then(data => {
            handleAvailabilitySuccess(segmentId, data, requestId);
          })
          .catch(err => {
            handleAvailabilityError(segmentId, err, requestId);
          });
      });
    }
  }, [
    segments,
    enqueueSnackbar,
    closeSnackbar,
    adults,
    children,
    handleAvailabilitySuccess,
    handleAvailabilityError,
    fetchAvailability,
    delay,
  ]);
}

import { Component } from 'react';
import PropTypes from 'prop-types';
import window from 'common/utils/window-or-global';
import { withRouter } from 'react-router-dom';
import { merge } from 'lodash';
import logger from 'itrvl-logger';
import withUser from 'common/hocs/withUser';
import compose from './utils/compose';
import Queue from './utils/Queue';
const log = logger(__filename);

/**
 * all of the available methods for fresh chat
 * taken from the react-freshchat component
 */
const availableMethods = [
  'close',
  'destroy',
  'hide',
  'init',
  'isInitialized',
  'isLoaded',
  'isOpen',
  'off',
  'on',
  'open',
  'setConfig',
  'setExternalId',
  'setFaqTags',
  'setTags',
  'track',
  'user.show',
  'user.track',
  'user.user',
  'user.clear',
  'user.create',
  'user.get',
  'user.isExists',
  'user.setEmail',
  'user.setFirstName',
  'user.setLastName',
  'user.setMeta',
  'user.setPhone',
  'user.setPhoneCountryCode',
  'user.setProperties',
  'user.update',
];
/**
 * @description Dynamically load the script
 * taken from the react-freshchat component
 * @returns script tag or the widget
 */
const loadScript = () => {
  let id = 'freshchat-lib';
  if (document.getElementById(id) || window.fcWidget) return;
  let script = document.createElement('script');
  script.async = 'true';
  script.type = 'text/javascript';
  script.src = 'https://wchat.freshchat.com/js/widget.js';
  script.id = id;
  document.head.appendChild(script);
  log.trace('script loaded');
};
/**
 * @description a simple function that either returns the actual fcwidget (if it is loaded)
 * or it returns a little stubby - if it is a stubby it will get replaced by the fcwidget when
 * it becomes available on the window
 * partially taken from the react-freshchat component - updated to our needs
 * @returns object with all of the available methods
 */
const widget = () => {
  // if the widget has been loaded then return it
  if (window.fcWidget) return window.fcWidget;
  // stub it out now
  let stubby = {};
  // loop through the available methods and add to the
  for (const method of availableMethods) {
    stubby[method] = queueMethod(method);
  }
  return stubby;
};
// the following two functions were taken directly from react-freshchat
// create a queue so that it can be added to the stubbies
const earlyCalls = new Queue();
// simple wrapper function
const queueMethod = method => (...args) => {
  earlyCalls.queue({ method, args });
};
class Freshchat extends Component {
  state = {
    unlisten: undefined,
    setUser: false,
    location: undefined,
  };
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  componentWillUnmount() {
    log.trace('unmount');
    if (this.state.unlisten) {
      log.trace('unlisten');
      this.destroy();
      this.state.unlisten();
      this.setState({ unlisten: null });
    }
  }
  // create logic to update when the user logs in
  // replacing the component will update
  componentDidUpdate(prevProps) {
    // checking the props
    if (prevProps.userContext.user !== this.props.userContext.user) {
      // call the setuser and update
      this.setUser(this.props.userContext.user);
    }
  }

  constructor(props, context) {
    super(props, context);
    log.trace('constructor', props, context);
    // Don't complain about setState while constructing for code reuse
    const oldSetState = this.setState;
    this.setState = state => {
      this.state = merge(this.state, state);
    };

    // we need to wrap this in a flag to check for the environment and the env var
    // or we need to move the flag to the routes level
    this.setup(props.history);
    // initialize the widget
    this.init({
      host: 'https://wchat.freshchat.com',
      token: process.env.REACT_APP_FRESHCHAT_TOKEN,
      config: { headerProperty: { direction: 'ltr' } }, //will move widget to left side of the screen
    });
    this.setState = oldSetState;
  }
  /**
   * @descriptionthis is from the original component, setting up the unlisten event
   * @param {object} history - history object from the react-router
   * @param {object} state - state of this component
   */
  setup(history) {
    log.trace('setup');
    this.setState({
      unlisten: history.listen(location => {
        if (window.fcWidget && this.setUser) {
          this.setLocation(location.pathname);
        }
        return undefined;
      }),
    });
  }
  /**
   * @description this is from the original component, and is setting a custom property on
   * the fresh chat admin to show where the user came from or started the chat
   * @param {*} location
   */
  setLocation(location) {
    log.trace('setLocation', location);
    if (this.state.location !== location) {
      log.info('new location', location);
      try {
        widget().user.setProperties({
          location: location,
        });
      } catch (err) {
        log.warn('Freshchat Error:', err);
      }
      this.setState({ location });
    }
  }
  /**
   * @description destroy the widget
   */
  destroy() {
    if (this.setUser) {
      this.setState({ setUser: false });
      log.trace('destroy');
      try {
        widget().destroy();
      } catch (err) {
        log.warn('Freshchat Error:', err);
      }
    }
  }
  /**
   * @description initialize the widget, if the fcWidget exist then use its init
   * otherwise lazy load the init
   * @param {object} settings
   */
  init(settings) {
    log.trace('init', window.fcWidget, this.state.setUser);
    // initializing the widget
    if (window.fcWidget) {
      window.fcWidget.init(settings);
    } else {
      this.lazyInit(settings);
    }
  }
  /**
   * @description loading the script, then set an interval to wait for the fcWidget
   * to be loaded. Once it is loaded then go through the queue
   * @param {object} settings
   */
  lazyInit(settings) {
    widget().init(settings); // Can't use window.fcSettings because sometimes it doesn't work

    loadScript();

    const interval = setInterval(() => {
      if (window.fcWidget) {
        clearInterval(interval);
        try {
          earlyCalls.dequeueAll((method, value) => {
            log.info('fcwidget method', method);
            log.info('freshchat object', window.fcWidget);
            window.fcWidget[method](...value);
          });
        } catch (err) {
          log.warn('freshchat error:', err);
        }
      }
    }, 1000);
  }
  /**
   * @description this is to update the user either on an existing chat window,
   * a new chat window
   * @param {*} user
   * @returns
   */
  setUser(user = {}) {
    // when we log out it the user will be null so we should just return this
    // should we log out from the fresh chat as well?
    if (!user) return;

    const { email, firstName = 'default', lastName = 'user' } = user;
    if (email) {
      log.info('new user', email, firstName, lastName);
      try {
        widget().user.setEmail(email);
        widget().user.setFirstName(firstName);
        widget().user.setLastName(lastName);
        widget().setExternalId(user.userId);
      } catch (err) {
        log.warn('Freschat Error:', err);
      }
      this.setState({ setUser: true });
    }
  }
  render() {
    log.trace('render', this.props.userContext.user);
    return null;
  }
}

export default compose(withRouter, withUser)(Freshchat);

const Crosshairs = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 6C8.25 7.24219 7.24219 8.25 6 8.25C4.73438 8.25 3.75 7.24219 3.75 6C3.75 4.75781 4.73438 3.75 6 3.75C7.24219 3.75 8.25 4.75781 8.25 6ZM6 4.5C5.15625 4.5 4.5 5.17969 4.5 6C4.5 6.84375 5.15625 7.5 6 7.5C6.82031 7.5 7.5 6.84375 7.5 6C7.5 5.17969 6.82031 4.5 6 4.5ZM6 0C6.1875 0 6.375 0.1875 6.375 0.375V1.52344C8.55469 1.71094 10.2891 3.44531 10.4766 5.625H11.625C11.8125 5.625 12 5.8125 12 6C12 6.21094 11.8125 6.375 11.625 6.375H10.4766C10.2891 8.57812 8.55469 10.3125 6.375 10.5V11.625C6.375 11.8359 6.1875 12 6 12C5.78906 12 5.625 11.8359 5.625 11.625V10.5C3.42188 10.3125 1.6875 8.57812 1.5 6.375H0.375C0.164062 6.375 0 6.21094 0 6C0 5.8125 0.164062 5.625 0.375 5.625H1.5C1.6875 3.44531 3.42188 1.71094 5.625 1.52344V0.375C5.625 0.1875 5.78906 0 6 0ZM2.25 6C2.25 8.08594 3.91406 9.75 6 9.75C8.0625 9.75 9.75 8.08594 9.75 6C9.75 3.9375 8.0625 2.25 6 2.25C3.91406 2.25 2.25 3.9375 2.25 6Z"
      fill="currentColor"
    />
  </svg>
);

export default Crosshairs;

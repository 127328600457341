const endpointName = 'Itineraries';
const EditItinerary = {
  /**
   * @description function to download the confirmation invoice
   * @param id {string} = the id of the itinerary to download
   * @returns a promise
   */
  downloadConfirmationInvoice: function(id) {
    return this.axios({
      method: 'GET',
      url: `/api/${endpointName}/${id}/download-confirmation-invoice`,
      responseType: 'blob',
    });
  },
};

export default EditItinerary;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { compose } from '../../utils/compose';
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { DialogContent, DialogActions } from '@mui/material';

const styles = {
  root: {},
  headerInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogTitle: {
    borderBottom: '1px solid #DADADA',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    margin: '0 0 0 20px',
  },
  content: {
    padding: '20px',
  },
  actions: {
    display: 'flex',
    padding: 20,
    borderTop: '1px solid #DADADA',
  },
};

const BaseModal = ({
  classes,
  children,
  maxWidth = 'lg',
  fullWidth = true,
  title,
  onClose = () => {},
  open = false,
  titleExtras = undefined,
  headerExtras,
  classOverrides = {},
  PaperProps,
  actions,
  ...rest
}) => (
  <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    onClose={onClose}
    open={open}
    className={classes.root}
    PaperProps={PaperProps}
    {...rest}
  >
    <header className={clsx(classes.dialogTitle, classOverrides.title)}>
      <div className={classes.headerInfo}>
        <IconButton aria-label="Close" className={clsx(classes.closeButton, 'share-modal_close-button')} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
        {title && (
          <>
            <h1 className={classes.title}>{title}</h1>
            {titleExtras}
          </>
        )}
      </div>
      {headerExtras}
    </header>
    <DialogContent className={clsx(classes.content, classOverrides.content)}>{children}</DialogContent>
    {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
  </Dialog>
);

export default compose(withStyles(styles))(BaseModal);

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Tooltip from 'components/v2/Tooltip';
import { Button } from '@mui/material';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const useStyles = makeStyles({
  root: {
    padding: '5px 10px',
    minWidth: 'auto',
    '& svg': {
      width: 22,
    },
  },
});

const FillRoomButton = ({ onClick, disabled, ariaLabel }) => {
  const classes = useStyles();
  return (
    <Tooltip title="Fill room" placement="top">
      <Button variant="outlined" className={classes.root} onClick={onClick} disabled={disabled} aria-label={`Fill room: ${ariaLabel}`}>
        <PlaylistAddIcon />
      </Button>
    </Tooltip>
  );
};

export default FillRoomButton;

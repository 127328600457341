import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Chip } from '@mui/material';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(90deg, rgb(60, 116, 221), rgb(43, 161, 133))',
    color: '#fff',
    height: 25,
  },
});

const NewChip = () => {
  const classes = useStyles();
  return <Chip className={classes.root} label="new" />;
};

export default NewChip;

const commonSnackProps = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  className: 'room-filled-snack',
};

export const handleSuccess = (message, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: 'success',
    ...commonSnackProps,
  });
};

export const handleError = (error, message, enqueueSnackbar, log) => {
  log.error(message, error);

  enqueueSnackbar(message, {
    variant: 'error',
    ...commonSnackProps,
  });
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useBuilderStore } from './store';
import { size } from 'lodash';
import { Chip } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 500,
  },
});

const PinCount = () => {
  const classes = useStyles();
  const pinCount = useBuilderStore(state => size(state.data.pinnedAccommodationMap));
  return (
    <div className={classes.root}>
      <Chip label={pinCount} color="secondary" classes={{ root: classes.pinChip }} />
      Pinned Accommodations
    </div>
  );
};

export default PinCount;

import React from 'react';
import AutoNumeric from 'autonumeric';
import { pick } from 'lodash';

export default class ReactNumeric extends React.Component {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.callEventHandler = this.callEventHandler.bind(this);
  }
  componentDidMount() {
    this.autonumeric = new AutoNumeric(this.input, this.props.value, {
      ...this.props.preDefined,
      ...this.props,
      onChange: undefined,
      onFocus: undefined,
      onBlur: undefined,
      onKeyPress: undefined,
      onKeyUp: undefined,
      onKeyDown: undefined,
      watchExternalChanges: false,
    });
  }
  componentWillUnmount() {
    if (this.autonumeric) this.autonumeric.remove();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const isOptionsChanged = JSON.stringify({ ...this.props, value: undefined }) !== JSON.stringify({ ...newProps, value: undefined });
    const isValueChanged = this.props.value !== newProps.value && this.getValue() !== newProps.value;
    if (isValueChanged) {
      this.autonumeric.set(newProps.value);
    }
    if (isOptionsChanged) {
      this.autonumeric.update({
        ...newProps.preDefined,
        ...newProps,
        onChange: undefined,
        onFocus: undefined,
        onBlur: undefined,
        onKeyPress: undefined,
        onKeyUp: undefined,
        onKeyDown: undefined,
        watchExternalChanges: false,
      });
    }
  }
  getValue() {
    if (!this.autonumeric) return;
    const valueMapper = {
      string: numeric => numeric.getNumericString(),
      number: numeric => numeric.getNumber(),
    };
    return valueMapper[this.props.outputFormat](this.autonumeric);
  }
  callEventHandler(event, eventName) {
    if (!this.props[eventName]) return;
    this.props[eventName](event, this.getValue());
  }
  render() {
    const inputProps = pick(this.props, [
      'id',
      'className',
      'style',
      'disabled',
      'type',
      'name',
      'tabIndex',
      'unselectable',
      'size',
      'autoFocus',
      'placeholder',
      'aria-label',
    ]);
    return (
      <input
        ref={ref => (this.input = ref)}
        onChange={e => this.callEventHandler(e, 'onChange')}
        onFocus={e => this.callEventHandler(e, 'onFocus')}
        onBlur={e => this.callEventHandler(e, 'onBlur')}
        onKeyPress={e => this.callEventHandler(e, 'onKeyPress')}
        onKeyUp={e => this.callEventHandler(e, 'onKeyUp')}
        onKeyDown={e => this.callEventHandler(e, 'onKeyDown')}
        {...inputProps}
      />
    );
  }
}

ReactNumeric.defaultProps = {
  type: 'text',
  outputFormat: 'number',
  preDefined: {},
};

export const predefinedOptions = AutoNumeric.getPredefinedOptions();

import { configureStore } from '@reduxjs/toolkit';
// import * as Sentry from '@sentry/react';

import { mainApi } from 'slices/index';

// TODO: add redux-persist
// const persistedState = loadState()

const prodMiddlewares = [];
// if (process.env.NODE_ENV === 'production') {
//   const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
//   prodMiddlewares.push(sentryReduxEnhancer);
// }

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(mainApi.middleware)
      .concat(prodMiddlewares),
  reducer: {
    [mainApi.reducerPath]: mainApi.reducer,
    // other reducers ...
  },
  preloadedState: {},
});

export { store };

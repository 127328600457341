import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { shallow } from 'zustand/shallow';
import { size, values } from 'lodash';

import { useBuilderStore } from './store';
import { useAccommodationCountQuery } from './queries';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(
  {
    root: {
      fontFamily: 'Atyp Display',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      /* ITRVL Palette / Dark Brown / Normal */
      color: '#221F20',
      // marginBottom: 30,
    },
  },
  {
    name: __filename,
  },
);

const AccommodationCount = () => {
  const classes = useStyles();
  const term = useBuilderStore(state => state.ui.accommodations.term);
  const searchMode = term !== '';
  const accommodationCount = useAccommodationCountQuery(searchMode);
  const selectedRegions = useBuilderStore(state => values(state.ui.countries.selectedMap), shallow);
  const showAll = useMemo(() => !term && size(selectedRegions) === 1 && selectedRegions[0].value === 'all', [term, selectedRegions]);
  return (
    <div className={classes.root}>
      {showAll ? 'All' : accommodationCount?.isLoading ? <CircularProgress size={10} /> : accommodationCount?.data || 0} Accommodations
      {term ? ' Found' : ''}
    </div>
  );
};

export default AccommodationCount;

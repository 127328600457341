import { Dinero } from 'itrvl-pricing';
import logger from 'itrvl-logger';
const { getCosting } = require('./availability');
const { find, get, set, cloneDeep, reduce, map, forEach, uniq } = require('lodash');
const moment = require('moment');
const log = logger(__filename);

const ALL_SYSTEM_TYPES = ['Single', 'Staff', 'Twin', '_Double', 'Family'];

const getCurrencySymbol = currency =>
  Dinero({ amount: 0, currency })
    .toFormat()
    .replace(/[\d,.]/g, '')
    .trim();

const formatApproximateValue = dinero => {
  const symbol = getCurrencySymbol(dinero.getCurrency());
  const num = dinero.toUnit();
  if (num >= 1000000000) {
    return symbol + (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'b';
  }
  if (num >= 1000000) {
    return symbol + (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
  }
  if (num >= 100000) {
    return symbol + (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return symbol + num.toFixed(0);
};

const optionKeysForSupplierCode = (supplierCode, roomTypesConfig) => {
  let roomTypes = get(roomTypesConfig, supplierCode);
  let optionKeys = reduce(
    ['Twin', 'Family', '_Double'],
    (acc, systemType) => {
      let optionKeysForSystemType = map(get(roomTypes, `${systemType}.options`), option => {
        return `${option.optionKey}-${option.roomTypeId}`;
      });
      acc = acc.concat(uniq(optionKeysForSystemType));
      return acc;
    },
    [],
  );
  return optionKeys;
};

const rateForRoomWithRule = (room, rule) => {
  const costing = getCosting([room]);

  const rate = {
    amount: 0,
    currency: 'USD',
    name: 'Unknown',
    detail: 'No pricing rule found',
    ruleFound: false,
    isClosed: false,
    invalidPax: false,
  };

  if (!rule) {
    return rate;
  }
  if (rule.prov === 'C') {
    rate.isClosed = true;
    return rate;
  }

  const roomPax = room.adults + get(room, 'children', []).length;
  if (room.roomType === 'fr' && get(rule, 'guestNormal') && roomPax > rule.guestNormal) {
    rate.invalidPax = true;
    return rate;
  }
  // GH #2609
  if (get(rule, 'normalAdults') && room.adults > Number(rule.normalAdults)) {
    rate.invalidPax = true;
    return rate;
  }
  let derivedRate;
  let detail;
  if (rule.perPerson === true) {
    derivedRate = costing.single * rule.singleRate + (costing.share * rule.twinRate) / 2 + costing.children * rule.childRate;
    detail = `Pricing per person: ${costing.single} single * ${rule.singleRate}, ${costing.share} share * ${rule.twinRate} / 2, ${costing.children} children * ${rule.childRate} === ${derivedRate}`;
  } else {
    derivedRate = Math.ceil(costing.pax / rule.maxUnits) * rule.groupRate;
    detail = `Pricing per room: ${costing.pax} pax / ${rule.maxUnits} max pax per unit * ${rule.groupRate} === ${derivedRate}`;
  }
  return { amount: derivedRate, currency: rule.currency, name: rule.room, detail, ruleFound: true, isClosed: false };
};

const calculateRateForRooms = (supplierCode, rates, rooms, _roomTypes) => {
  // @TODO: special case for wilderness properties that may have twin/double/family capable rooms + suites (JAO001)
  //    const isWilderness = get(lodgeList, `${supplierCode}.availabilityInterface`) === 'Wish' ? true : false;

  // console.log(`calculateRateForRooms: ${supplierCode}, rates: `,rates,', rooms, ',rooms,', _roomTypes: ',_roomTypes)
  const roomTypes = cloneDeep(get(_roomTypes, supplierCode));
  //    if (isWilderness) {
  if (!get(roomTypes, '_Double.default.optionKey')) {
    set(roomTypes, '_Double.default', get(roomTypes, 'Twin.default'));
    set(roomTypes, '_Double.options', get(roomTypes, 'Twin.options'));
  }
  //    }

  let rateForAllRooms = reduce(
    rooms,
    (acc, room) => {
      let systemTypeForRoom = 'Twin';
      switch (room.roomType) {
        case 'dr':
          // Doubles are okay with twins
          systemTypeForRoom = '_Double';
          break;
        case 'sr':
          systemTypeForRoom = '_Double';
          break;
        case 'tr':
          // Twins are not okay with doubles
          systemTypeForRoom = 'Twin';
          break;
        case 'fr':
          systemTypeForRoom = 'Family';
          break;
        default:
          systemTypeForRoom = 'Twin';
          break;
      }

      let optionKeyForRoom = get(roomTypes, `${systemTypeForRoom}.default.optionKey`);
      let roomTypeIdForRoom = get(roomTypes, `${systemTypeForRoom}.default.roomTypeId`);
      systemTypeForRoom = get(roomTypes, `${systemTypeForRoom}.default.systemType`, systemTypeForRoom);

      if (room.roomTypeId && room.optionKey) {
        let selectedRoomTypeId = get(room.roomTypeId, supplierCode);
        let selectedOptionKey = get(room.optionKey, supplierCode);
        let roomOptions = get(roomTypes, `Any.options`);
        let roomOption = find(roomOptions, { roomTypeId: String(selectedRoomTypeId), optionKey: String(selectedOptionKey) });

        if (roomOption) {
          optionKeyForRoom = roomOption.optionKey;
          roomTypeIdForRoom = roomOption.roomTypeId;
          systemTypeForRoom = roomOption.systemType;
        }
      }
      // console.log(`optionKeyForRoom: ${optionKeyForRoom}, roomTypeIdForRoom: ${roomTypeIdForRoom}`);
      if (!optionKeyForRoom) {
        acc.roomTypeNotPresent = true;
      }
      if (!optionKeyForRoom || acc.configNotAvailable === true) {
        acc.configNotAvailable = true;
        acc.ruleFound = false;
        return acc;
      }
      let provForRoom = get(
        find(get(roomTypes, `Any.options`, []), { roomTypeId: roomTypeIdForRoom, optionKey: optionKeyForRoom }),
        'prov',
      );
      let lastRateDateForRoom = get(
        find(get(roomTypes, `Any.options`, []), { roomTypeId: roomTypeIdForRoom, optionKey: optionKeyForRoom }),
        'lastRateDate',
      );

      acc.lastRateDate = lastRateDateForRoom;
      acc.lastRateProv = provForRoom;

      let rateForRoom = rateForRoomWithRule(room, find(rates, { optionKey: optionKeyForRoom, roomTypeId: roomTypeIdForRoom }));
      if (rateForRoom.isClosed) {
        acc.configNotAvailable = true;
        acc.isClosed = true;
        return acc;
      }
      if (rateForRoom.invalidPax) {
        acc.invalidPax = true;
        acc.configNotAvailable = true;
        return acc;
      }
      if (rateForRoom.ruleFound === false) {
        acc.ruleFound = false;
        if (acc.lastRateProv === 'T') {
          acc.configNotAvailable = true;
          acc.isClosed = true;
          return acc;
        }
      }

      acc.rawRate.amount += rateForRoom.amount;
      acc.rawRate.currency = rateForRoom.currency;
      acc.detail.push(rateForRoom.detail);
      acc.roomName.push(rateForRoom.name);
      acc.roomTypeId.push(roomTypeIdForRoom);
      acc.optionKey.push(optionKeyForRoom);
      acc.systemType.push(systemTypeForRoom);

      return acc;
    },
    {
      configNotAvailable: false,
      roomTypeNotPresent: false,
      invalidPax: false,
      isClosed: false,
      rawRate: { amount: 0, currency: 'USD' },
      ruleFound: true,
      roomTypeId: [],
      optionKey: [],
      systemType: [],
      roomName: [],
      detail: [],
    },
  );

  if (rateForAllRooms.ruleFound === false) {
    rateForAllRooms.rawRate = undefined;
  }
  return rateForAllRooms;
};

const ratesForDateForOptionKeys = (ratesMap, date, optionKeys) => {
  let rate = undefined;
  let rates = map(optionKeys, optionKey => {
    forEach(get(ratesMap, optionKey), (value, key) => {
      const fromDate = moment(key.split('_')[0], 'YYYY-MM-DD').utc();
      const toDate = moment(key.split('_')[1], 'YYYY-MM-DD').utc();
      if (
        moment(date, 'YYYY-MM-DD')
          .utc()
          .isBetween(fromDate, toDate, 'day', '[]')
      ) {
        rate = value;
        return false;
      }
    });

    return rate;
  });
  return rates;
};

const minimumAdultsForRoomTypeId = roomTypeId => {
  switch (roomTypeId) {
    // Special case for Wilderness room types 3 & 4 -- family rooms.  They don't allow one adult in a family.
    case '3':
    case '4':
      return 2;
    default:
      return 1;
  }
};

const normalizeRoomType = roomType => {
  switch (roomType) {
    case 'Honeymoon':
    case 'Hmoon':
    case 'Double':
      return '_Double';
    case 'Fam6':
    case 'Fam4':
    case 'Villa':
    case 'Suite':
      return 'Family';
    default:
      return roomType;
  }
};

const rateKey = ({ optionKey, roomTypeId }) => `${optionKey}-${roomTypeId}`;

const formatRatesRow = (rawRow, exchangeRates) => {
  if (!rawRow) return;
  if (rawRow['Supplier Code'] === '' || rawRow['Supplier Code'] === '-') return;
  if (rawRow['Date From'] === '') return;
  if (rawRow['OptionKey'] === '') return;
  let groupRate = Number(String(rawRow['Non AC Unit']).replace(',', ''));
  if (Number.isNaN(groupRate)) groupRate = false;
  let twinRate = Number(String(rawRow['TW [Inc]']).replace(',', ''));
  if (Number.isNaN(twinRate)) twinRate = false;
  let singleRate = Number(String(rawRow['Single [Inc]']).replace(',', ''));
  if (Number.isNaN(singleRate)) singleRate = false;
  let infantRate = Number(String(rawRow['Infant [Inc]']).replace(',', ''));
  if (Number.isNaN(infantRate)) infantRate = false;
  let childRate = Number(String(rawRow['Child [Inc]']).replace(',', ''));
  if (Number.isNaN(childRate)) childRate = false;

  let specialOption = false;
  let overrideOption = false;
  let overrideRoom = false;
  const rawSpecialOption = rawRow['specialoption'];
  const rawOverrideOption = rawRow['overrideoption'];
  const rawOverrideRoom = rawRow['overrideroom'];
  /* @TODO: specialoption, overrideoption, overrideroom are kinda-boolean and should probably be sent through
     isWildernessTruthy or otherwise normalized along with other types assumed to be kinda-boolean.  */
  if (rawSpecialOption === '1' || rawSpecialOption === true) {
    specialOption = true;
  }
  if (rawOverrideOption === '1' || rawOverrideOption === true) {
    overrideOption = true;
  }
  if (rawOverrideRoom === '1' || rawOverrideRoom === true) {
    overrideRoom = true;
  }
  const cleanRow = {
    optionKey: rawRow['OptionKey'],
    supplierCode: rawRow['Supplier Code'],
    lodge: rawRow['Supplier'],
    date: moment.utc(rawRow['Updated'], 'DD-MMM-YYYY').format('YYYY-MM-DD'),
    dateFrom: moment.utc(rawRow['Date From'], 'DD-MMM-YY').format('YYYY-MM-DD'),
    dateTo: moment.utc(rawRow['Date To'], 'DD-MMM-YY').format('YYYY-MM-DD'),
    currency: rawRow['Currency'],
    room: rawRow['Room'].replace(/\/s/g, '').replace(/\./g, ''),
    systemType: normalizeRoomType(rawRow['roomtype']),
    priceId: rawRow['PriceRecordID'],
    minLOS: rawRow['MinLOS'],
    maxLOS: rawRow['MaxLOS'],
    perPerson: rawRow['AC'] === 'Y' ? true : false,
    adultFrom: Number(rawRow['AD FR']),
    adultTo: Number(rawRow['AD TO']),
    childFrom: Number(rawRow['CH FR']),
    childTo: Number(rawRow['CH TO']),
    infantFrom: Number(rawRow['IN FR']),
    infantTo: Number(rawRow['IN TO']),
    twinAdultMax: rawRow['Twin Adult Max'],
    twinMax: rawRow['Twin Max'],
    maxUnits: rawRow['Max Units'],
    guestNormal: Number(rawRow['guestnormal']),
    normalAdults: Number(rawRow['normaladults']),
    normalChildren: Math.max(Number(rawRow['guestnormal']) - 1, 0), // At least one adult needed
    inclusions: rawRow['Inclusions'],
    groupRate: groupRate,
    twinRate: twinRate,
    singleRate: singleRate,
    infantRate: infantRate,
    childRate: childRate,
    agentUsage: rawRow['All Agent Usage'],
    prov: rawRow['PROV'],
    periods: Number(rawRow['PERIODS']),
    periodsBase: rawRow['PERIODS_BASE'],
    priceCode: rawRow['Price Code'],
    specialOption: specialOption,
    overrideOption: overrideOption,
    overrideRoom: overrideRoom,
    extraName: rawRow['Room Name'].replace(/\/s/g, '').replace(/\./g, ''),
    optComment: rawRow['Opt Comment'],
    optDescription: rawRow['Opt Description'].replace(/\/s/g, '').replace(/\./g, ''),
    description: rawRow['Room Type Description'].replace(/\/s/g, '').replace(/\./g, ''),
    roomTypeId: String(rawRow['Room Type id']),
    minPax: minimumAdultsForRoomTypeId(rawRow['Room Type id']),
    mealBasis: rawRow['Meal Basis'],
    b2cInExclusions: rawRow['b2c_inclusions'],
  };

  // min age:
  // adultFrom as default
  // if childTo !== 0, prefer childFrom
  // if infantTo !== 0, prefer infantFrom

  cleanRow.minChildAge = cleanRow.adultFrom;
  if (cleanRow.childTo !== 0) {
    cleanRow.minChildAge = cleanRow.childFrom;
  }
  if (cleanRow.infantTo !== 0) {
    cleanRow.minChildAge = cleanRow.infantFrom;
  }

  // log.info(`[${cleanRow.supplierCode}-${cleanRow.optionKey}-${cleanRow.roomTypeId}] (${cleanRow.systemType}) minChildAge: IN: ${cleanRow.infantFrom}-${cleanRow.infantTo}, CH: ${cleanRow.childFrom}-${cleanRow.childTo}, AD: ${cleanRow.adultFrom}-${cleanRow.adultTo} ==> ${cleanRow.minChildAge}`);

  if (exchangeRates) {
    const room = {
      adults: cleanRow.normalAdults,
      children: [],
    };
    const remainingNormalPax = cleanRow.guestNormal - cleanRow.normalAdults;
    if (remainingNormalPax > 0) {
      for (let i = 0; i < remainingNormalPax; i++) {
        if (room.children.length < 2) {
          room.children.push(12);
        }
      }
    }
    switch (cleanRow.systemType) {
      case 'Twin':
        room.roomType = 'tr';
        break;
      case '_Double':
        room.roomType = 'dr';
        break;
      case 'Family':
        room.roomType = 'fr';
        break;
      case 'Single':
        room.roomType = 'sr';
        break;
      case 'Staff':
        room.roomType = 'gr';
        break;
      default:
        room.roomType = '';
        log.warn(`${cleanRow.optionKey}-${cleanRow.roomTypeId} system type ${cleanRow.systemType} not something we handle`);
        break;
    }

    if (cleanRow.guestNormal === 0) {
      log.warn(`${cleanRow.optionKey}-${cleanRow.roomTypeId} guestNormal is 0`);
    }
    if (room.roomType !== '' && cleanRow.guestNormal !== 0) {
      const thisRate = rateForRoomWithRule(room, cleanRow);
      const roomRatePP = Dinero({ amount: thisRate.amount * 100, currency: thisRate.currency }).divide(cleanRow.guestNormal);
      cleanRow.roomRatePP = Dinero.exchangeSync(roomRatePP, 'USD', { USD: exchangeRates }).toUnit();
    }
  }
  return cleanRow;
};

export {
  rateForRoomWithRule,
  formatRatesRow,
  calculateRateForRooms,
  ratesForDateForOptionKeys,
  optionKeysForSupplierCode,
  formatApproximateValue,
  getCurrencySymbol,
  normalizeRoomType,
  minimumAdultsForRoomTypeId,
  ALL_SYSTEM_TYPES,
  rateKey,
};

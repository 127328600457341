// AgencyDetailsTab.styles.js
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    maxWidth: '1920px',
    margin: 'auto',
    padding: theme.spacing(4, 8),
    '@media (min-width: 864px)': {
      padding: theme.spacing(4, 8),
    },
  },
  formButtonRow: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionsLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: 'auto',
    gap: 40,
  },
  contactInfoSection: {
    gap: 20,
    '@media(min-width: 1080px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridTemplateRows: '1fr 1fr',
    },
  },
  firstNameInput: {
    gridColumn: 'span 6',
  },
  lastNameInput: {
    gridColumn: '7 / span 6',
  },
  phoneInput: {
    gridRow: '2',
    gridColumn: 'span 6',
  },
  emailInput: {
    gridRow: '2',
    gridColumn: '7 / span 6',
  },
  bioInput: {
    gridColumn: 'span 12',
  },
  agentMediaSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  mediaUploader: {
    flex: '1 1 auto',
    maxWidth: '300px',
    width: '100%',
    boxSizing: 'border-box',
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(4),
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      marginRight: 0, // No right margin when stacked vertically
    },
    '&:last-child': {
      marginRight: 0, // Remove right margin from last child
    },
  },
}));

export default useStyles;

export default function getCampEndpoints(build) {
  return {
    getCamp: build.query({
      query: id => `/Camps/${id}/camp-info`,
      providesTags: ['Camp'],
    }),
    getAccommodation: build.query({
      query: supplierCode => `/Camps/${supplierCode}/accommodation-info/`,
    }),
  };
}

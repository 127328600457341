import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { useContext } from 'react';
import { UserContext } from 'common';
import { useSnackbar } from 'notistack';
import { handleError } from 'components/handleSnackBarMessages';
import { agencyAgentsKey } from 'api/queries';
import { agencyDetailsKey } from 'queries/agency';

import logger from 'itrvl-logger';
const log = logger(__filename);

export const usePatchAgentMutation = () => {
  const Api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ agentId, data }) => {
      return Api.patchAgent(agentId, data);
    },
    onSuccess: (_, { agentId, agencyId }) => {
      queryClient.invalidateQueries(agencyAgentsKey(agentId));
      queryClient.refetchQueries(agencyDetailsKey(agencyId));
    },
    onError: err => {
      handleError(err, 'An error occurred while updating the agent profile.', enqueueSnackbar, log);
    },
  });
};

export const useAgentSetPasswordMutation = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ newPassword }) => {
      return Api.agentSetPassword(newPassword);
    },
    onError: err => {
      log.error('Password setting failed', err);
    },
  });
  return mutation;
};

export const useAgentLoginMutation = () => {
  const Api = useApi();
  const userContext = useContext(UserContext);

  const mutation = useMutation({
    mutationFn: async ({ userEmail, newPassword }) => {
      return Api.agentLogin(userEmail, newPassword);
    },
    onSuccess: data => {
      // Sets new token id and ttl and user stays logged in
      userContext.updateUser(data.data.id, data.data.ttl);
    },
    onError: err => {
      log.error('Login failed', err);
    },
  });
  return mutation;
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Chip } from '@mui/material';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(90deg, #FF6E00, #A83279)',
    color: '#fff',
    height: 25,
    marginLeft: 'auto',
  },
});

const BetaChip = () => {
  const classes = useStyles();
  return <Chip className={classes.root} label="beta" />;
};

export default BetaChip;

const Settings = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
    <circle cx="17.2" cy="16.9" r="16.5" fill="none" stroke="currentColor" />
    <path
      d="M24.8 19.9c.2 0 .5.2.5.5s-.2.5-.5.5H14.2c-.2 1.2-1.2 2-2.4 2-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5c1.2 0 2.2.9 2.4 2h10.6zm-14.6.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5zm10.1-7.5c.2-1.1 1.2-2 2.5-2 1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5c-1.2 0-2.2-.8-2.5-2H9.8c-.3 0-.5-.2-.5-.5 0-.2.2-.5.5-.5h10.5zm2.5 2c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5-1.5.7-1.5 1.5c-.1.9.6 1.5 1.5 1.5z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);

export default Settings;

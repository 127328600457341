export const getCookieOptions = ttl => {
  const options = { path: '/' };
  if (process.env.NODE_ENV !== 'development' && !process.env.REACT_APP_INSECURE_COOKIES) {
    options.secure = true;
    options.sameSite = 'strict';
  }
  if (ttl) {
    options.maxAge = ttl;
  }
  return options;
};

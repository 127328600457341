import React, { Component } from 'react';
import AsyncButton from './Buttons/AsyncButton';
import PasswordField from 'material-ui-password-field';
import withStyles from '@mui/styles/withStyles';

import withUser from '../hocs/withUser';

import { Typography } from '@mui/material';
import logger from 'itrvl-logger';

const log = logger(__filename);

const styles = {
  root: {},
  form: {
    display: 'flex',
    padding: '0 40px',
    '& .MuiInputBase-root': {
      // unfocused bottom border
      borderBottom: '1px solid #ccc',
    },
    '& .MuiInput-underline::after': {
      // focused & animated bottom "border"
      border: '2px solid #fff',
    },
    '& .MuiSvgIcon-root': {
      // password icon, e.g. crossed-out eye
      color: 'rgba(255,255,255,0.7)',
    },
  },
  button: {
    marginLeft: 40,
  },
};

export class ResetPassword extends Component {
  state = {
    error: false,
    submitting: false,
    success: false,
  };

  resetPassword = async () => {
    this.setState({ submitting: true });
    const verificationToken = this.props.token;
    try {
      const response = await this.props.onResetPassword(verificationToken, this.state.password);
      // Set their cookie to the returned value
      if (response.status === 200) {
        await this.props.userContext.updateUser(response.data.id);
      }
      if (this.props.onSuccess && (response.status === 200 || response.status === 204)) {
        this.props.onSuccess();
      } else {
        this.setState({ error: true });
      }
    } catch (err) {
      log.warn('error resetPassword:', err);
      this.setState({ error: true });
    }
  };

  render() {
    const { error, submitting, success } = this.state;
    const { buttonText, successText, errorText, classes } = this.props;
    return (
      <div className={classes.root}>
        {success ? (
          successText
        ) : error ? (
          <Typography component="div">{errorText}</Typography>
        ) : (
          <div className={classes.form}>
            <PasswordField style={{ flex: 1, marginRight: 40 }} onChange={e => this.setState({ password: e.target.value })} />
            <AsyncButton classes={{ root: classes.button }} onClick={this.resetPassword} loading={submitting} buttonText={buttonText} />
          </div>
        )}
      </div>
    );
  }
}

ResetPassword.defaultProps = {
  buttonText: 'Save',
  errorText: 'Error setting password. Please try again.',
  successText: (
    <div>
      Your password has been set, you may now <a href={`/login`}>Log in</a>.
    </div>
  ),
};

export default withStyles(styles)(withUser(ResetPassword));

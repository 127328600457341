import React, { useContext } from 'react';
export const LodgeMappingContext = React.createContext({
  lodgeMapping: null,
  exchange: null,
});
LodgeMappingContext.displayName = 'LodgeMappingContext';

export const useLodgeMapping = () => {
  const context = useContext(LodgeMappingContext);
  return context;
};

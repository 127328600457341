import React from 'react';
import withStyles from '@mui/styles/withStyles';

import Button from '@mui/material/Button';

import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import { noop } from 'common';

const styles = {
  button: {
    padding: '10px 15px',
    marginBottom: '10px',
  },
  buttonLabel: {
    position: 'relative',
  },
  label: {
    textTransform: 'none',
  },
};

const AddButton = props => {
  const { classes, icon, label = 'Add', onClick = noop } = props;
  return (
    <Button
      fullWidth
      data-test-id={props['data-test-id']}
      variant="outlined"
      color="primary"
      classes={{ label: classes.buttonLabel }}
      className={classes.button}
      onClick={onClick}
      startIcon={<ItrvlIcon name={icon} />}
    >
      <span className={classes.label}>{label}</span>
    </Button>
  );
};

export default withStyles(styles)(AddButton);

// C(new java.lang.String[][] { new java.lang.String[] { "en_US", "Conf" } }),
// CC(new java.lang.String[][] { new java.lang.String[] { "en_US", "Canc" } }),
// CX(new java.lang.String[][] { new java.lang.String[] { "en_US", "Canc" } }),
// P(new java.lang.String[][] { new java.lang.String[] { "en_US", "Prov" } }),
// X(new java.lang.String[][] { new java.lang.String[] { "en_US", "Deleted" } }),
// D(new java.lang.String[][] { new java.lang.String[] { "en_US", "DeInv" } }),
// Draft(new java.lang.String[][] { new java.lang.String[] { "en_US", "Draft" } }),
// Traveled(new java.lang.String[][] { new java.lang.String[] { "en_US", "Trav''d" } }),
// Travelling(new java.lang.String[][] { new java.lang.String[] { "en_US", "Trav''g" } }),
// Pending(new java.lang.String[][] { new java.lang.String[] { "en_US", "Pend" } }),
// I(new java.lang.String[][] { new java.lang.String[] { "en_US", "Invoiced" } }),
// Placeholder(new java.lang.String[][] { new java.lang.String[] { "en_US", "Place" } }),
// Quote(new java.lang.String[][] { new java.lang.String[] { "en_US", "QuoteOld" } }),
// Quote_only(new java.lang.String[][] { new java.lang.String[] { "en_US", "Quote" } }),
// Paid(new java.lang.String[][] { new java.lang.String[] { "en_US", "Paid" } }),
// EX_Tourplan(new java.lang.String[][] { new java.lang.String[] { "en_US", "EX TP" } });

export const BOOKING_STATUS = {
  CONF: 'Conf',
  DRAFT: 'Draft',
  PEND: 'Pend',
  PROV: 'Prov',
  // previously unhandles statuses
  CANC: 'Canc',
  DELETED: 'Deleted',
  DEINV: 'DeInv',
  // @todo: have never seen these, dunno if they actually come over as a ''?
  TRAVELED: "Trav''d",
  TRAVELLING: "Trav''g",
  INVOICED: 'Invoiced',
  PLACE: 'Place',
  QUOTEOLD: 'QuoteOld',
  QUOTE: 'Quote',
  PAID: 'Paid',
  EX_TOURPLAN: 'EX TP',
};

export const ITRVL_BOOKING_STATUS = {
  CONFIRMED: 'confirmed',
  QUOTE: 'quote',
  UNKNOWN: 'unknown',
  PROVISIONAL: 'provisional',
  CANCELLED: 'cancelled',
};

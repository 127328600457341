import { map, findIndex, findLastIndex, remove, filter, size, cloneDeep } from 'lodash';

import { SEGMENT_TYPE } from 'itrvl-types';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const retainActivitiesTransit = ({ retainTransit, retainActivities, itinerarySegments, segmentsOriginal }) => {
  // Accommodations can have activities.  Transit(point) can have activities.
  // Runs through itinerarySegments (from ui) and segmentsOriginal (from db) and grabs any comps.
  // activity: a->b->a would look for eg: 2nd a
  // transit: a->b->c->a->b->c would look for eg: 1st b->c or 2nd a->b
  // (entry, accommodation1] + n(accommodation1->accommodation2 activities and transit) + [,exit).
  // Looping through 2nights/1transit/2nights would look like: entry.segment.activites.transit.flat.segment.activities.exit.flat.

  log.trace('retainActivitiesTransit', { retainActivities, retainTransit });
  let segmentsActivitiesTransit;

  if (retainTransit || retainActivities) {
    //let supplier1, supplier2; // Placeholder vars for itinerarySegments.
    let accommodation1, accommodation2; // Placeholder vars for segmentsOriginal.

    const getSupplier = seg => seg.supplierCode || seg.locationCode; // Camp vs own accommodation.

    segmentsActivitiesTransit = itinerarySegments.flatMap((segment, idx) => {
      // Expand itinerarySegments to include activities/transit from segmentsOriginal.
      let segmentsToPush = [],
        segmentsTemp = [];

      if (idx === 0) {
        // Check entry->accommodation1 transit.
        let supplier1 = getSupplier(segment);
        accommodation1 = findIndex(segmentsOriginal, { type: SEGMENT_TYPE.STAY }); // First stay.
        if (getSupplier(segmentsOriginal[accommodation1]) === supplier1) {
          segmentsTemp = segmentsOriginal.slice(1, accommodation1); // Slice from `entry`.
          if (!retainActivities) {
            remove(segmentsTemp, { type: SEGMENT_TYPE.SERVICE });
          }
          if (!retainTransit) {
            segmentsTemp = []; // Only transit + activities.
          }
          segmentsToPush.push(...segmentsTemp);
          log.trace('entry', [...segmentsToPush]);
        }
      }

      if (idx >= 0) {
        segmentsToPush.push(segment); // supplier1
        log.trace('segment', [...segmentsToPush]);
      }

      if (retainActivities) {
        // Check ordinal accommodation activities.
        let supplier1 = getSupplier(segment);
        let idx1; // Placeholder vars for chasing accommodation positions/links.
        let ordinal; // First, second, third, ....
        let found = false;

        accommodation1 = accommodation2 = idx1 = ordinal = 0;
        segmentsTemp = [];
        while (idx1 <= idx) {
          if (getSupplier(itinerarySegments[idx1]) === supplier1) {
            ordinal++;
            found = true;
          }
          idx1++;
        }
        idx1 = 0;
        while (idx1 !== -1 && idx1 < size(segmentsOriginal) && ordinal > 0) {
          idx1 = findIndex(segmentsOriginal, so => so.type === SEGMENT_TYPE.STAY && getSupplier(so) === supplier1, idx1 + 1);
          if (idx1 !== -1) {
            ordinal--;
            accommodation1 = idx1;
            accommodation2 = findIndex(segmentsOriginal, { type: SEGMENT_TYPE.STAY }, idx1 + 1);
          }
        }
        if (!ordinal && found) {
          segmentsTemp = segmentsOriginal.slice(accommodation1 + 1, accommodation2);
          segmentsTemp = filter(segmentsTemp, { type: SEGMENT_TYPE.SERVICE }); // type:service (activity) only.
          segmentsTemp = map(segmentsTemp, st => {
            // accommodation1 activities.
            if (st.parentBySequence !== segmentsOriginal[accommodation1].sequence) {
              // Activity on transit.
              return false;
            }
            if (st.day === segmentsOriginal[accommodation1].nights + 1) {
              // Keep activity on day of leave; sticky.
              st = cloneDeep(st); // ugh
              st.day = segment.nights + 1;
              return st;
            }
            if (st.day <= segment.nights) {
              // Keep activity day <= nights.
              return st;
            }
            return false;
          }).filter(Boolean);

          segmentsToPush.push(...segmentsTemp);
          log.trace('activities', [...segmentsToPush]);
        }
      }

      if (retainTransit && idx < size(itinerarySegments) - 1) {
        // Check ordinal accommodation->accommodation transit.
        let supplier1 = getSupplier(segment);
        let supplier2 = getSupplier(itinerarySegments[idx + 1]);
        let idx1, idx2, idxq; // Placeholder vars for chasing accommodation positions/links.
        let ordinal; // First, second, third, ....
        let found = false;

        accommodation1 = accommodation2 = idx1 = idx2 = idxq = ordinal = 0;
        segmentsTemp = [];
        while (idx1 <= idx && idx1 !== -1) {
          idx1 = findIndex(itinerarySegments, is => getSupplier(is) === supplier1, idx2);
          idx2 = idx1 + 1;
          if (idx2 !== 0 && idx2 < size(itinerarySegments) && getSupplier(itinerarySegments[idx2]) === supplier2) {
            ordinal++;
            found = true;
          }
        }
        idx1 = idx2 = 0;
        while (idx1 !== -1 && idx2 !== -1 && idx1 < size(segmentsOriginal) && idx2 < size(segmentsOriginal) && ordinal > 0) {
          idx1 = findIndex(segmentsOriginal, so => so.type === SEGMENT_TYPE.STAY && getSupplier(so) === supplier1, idx2);
          idx2 = findIndex(segmentsOriginal, so => so.type === SEGMENT_TYPE.STAY && getSupplier(so) === supplier2, idx1);
          idxq = findIndex(segmentsOriginal, { type: SEGMENT_TYPE.STAY }, idx1 + 1); // idx1->idx[2q] : idx1->idxq->idx2 :: yay : nay
          if (idx1 !== -1 && idxq === idx2) {
            ordinal--;
            accommodation1 = idx1;
            accommodation2 = idx2;
          }
        }
        if (!ordinal && found) {
          segmentsTemp = segmentsOriginal.slice(accommodation1 + 1, accommodation2);
          if (!retainActivities) {
            remove(segmentsTemp, { type: SEGMENT_TYPE.SERVICE });
          } else {
            segmentsTemp = map(segmentsTemp, st => {
              // accommodation1 -> accommodation2 transit.
              if (st.parentBySequence === segmentsOriginal[accommodation1].sequence && st.type === SEGMENT_TYPE.SERVICE) {
                // Activity on accommodation.
                return false;
              }
              return st;
            }).filter(Boolean);
          }

          segmentsToPush.push(...segmentsTemp);
          log.trace('transit', [...segmentsToPush]);
        }
      }

      if (idx === size(itinerarySegments) - 1) {
        // Check accommodation->exit transit.
        let supplier1 = getSupplier(segment);

        accommodation1 = findLastIndex(segmentsOriginal, { type: SEGMENT_TYPE.STAY });
        segmentsTemp = [];
        if (getSupplier(segmentsOriginal[accommodation1]) === supplier1) {
          segmentsTemp = segmentsOriginal.slice(accommodation1 + 1, -1); // Slice till `exit`.
          if (!retainActivities) {
            remove(segmentsTemp, { type: SEGMENT_TYPE.SERVICE });
          } else {
            segmentsTemp = map(segmentsTemp, st => {
              // accommodation1 -> exit activities (already included).
              if (st.parentBySequence === segmentsOriginal[accommodation1].sequence) {
                return false;
              }
              return st;
            }).filter(Boolean);
          }
          if (!retainTransit) {
            segmentsTemp = []; // Accommodation activities already included so only transit + activities.
          }
          segmentsToPush.push(...segmentsTemp);
          log.trace('exit', [...segmentsToPush]);
        }
      }

      remove(segmentsToPush, segment => segment.sequence >= 100); // Remove dmc lines.
      log.trace('flat', [...segmentsToPush]);
      return size(segmentsToPush) ? segmentsToPush : segment;
    });
  }

  segmentsActivitiesTransit = cloneDeep(segmentsActivitiesTransit); // Remove consts.
  segmentsActivitiesTransit?.map(segment => delete segment.currency); // Trick reprice.
  return segmentsActivitiesTransit;
};

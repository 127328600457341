import React from 'react';
import withStyles from '@mui/styles/withStyles';

import withUser from 'common/hocs/withUser';

import { Typography } from '@mui/material';

const styles = {
  root: {
    color: '#fff',
    '& a': {
      color: '#fff',
    },
  },
};

const Verified = ({ classes, loginLink }) => {
  return <Typography className={classes.root}>Your account has been verified. Click {loginLink}</Typography>;
};

export default withStyles(styles)(withUser(Verified));

import logger from 'itrvl-logger';
const log = logger(__filename);

export const ZAPS = {
  AGENCY_LOGIN: 'agencyLogin',
  QUOTE_CREATED: 'quoteCreated',
  QUOTE_IMPORTED: 'quoteImported',
  CLIENT_PORTAL_SHARED: 'clientPortalShared',
  HOLD_REQUESTED: 'holdRequested',
  PAYMENT_MADE: 'paymentMade',
  VIDEO_VIEWED: 'videoViewed',
  ENQUIRE_TRIP: 'enquireTrip',
  COST_CHANGES: 'costChanges',
  SELL_PRICE_CHANGED: 'sellPriceChanged',
};

export const zapToUrl = zap => {
  const zapUrl = {
    [ZAPS.AGENCY_LOGIN]: 'https://hooks.zapier.com/hooks/catch/7993579/ocojjii/',
    [ZAPS.QUOTE_CREATED]: 'https://hooks.zapier.com/hooks/catch/7993579/ocovufw/',
    [ZAPS.QUOTE_IMPORTED]: 'https://hooks.zapier.com/hooks/catch/7993579/ocovhvd/',
    [ZAPS.CLIENT_PORTAL_SHARED]: 'https://hooks.zapier.com/hooks/catch/7993579/ocbeuty/',
    [ZAPS.HOLD_REQUESTED]: 'https://hooks.zapier.com/hooks/catch/7993579/ocovmiw/',
    [ZAPS.PAYMENT_MADE]: 'https://hooks.zapier.com/hooks/catch/7993579/ocov11w/',
    [ZAPS.VIDEO_VIEWED]: 'https://hooks.zapier.com/hooks/catch/9283904/o0lv0b4/',
    [ZAPS.ENQUIRE_TRIP]: 'https://hooks.zapier.com/hooks/catch/9283904/ons5vsi/',
    [ZAPS.COST_CHANGES]: 'https://hooks.zapier.com/hooks/catch/9283904/bsxs4lr/',
    [ZAPS.SELL_PRICE_CHANGED]: 'https://hooks.zapier.com/hooks/catch/7993579/bphcfll/',
  }[zap];

  if (zapUrl) {
    return zapUrl;
  } else {
    log.error('No such zap!', zap);
  }
};

export const zapDealId = client => {
  // Use like: `...zapDealId(client)`.
  if (client?.dealIdSource === 'pipedrive') {
    return { pipedriveDealId: client?.dealId };
  }
  if (client?.dealIdSource === 'hubspot') {
    return { hubspotDealId: client?.dealId };
  }
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useControlled } from '@mui/material';

import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Plus from './icons/Plus';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#fff',
    minWidth: 214,
    height: 40,
    fontSize: 12,
    color: '#00000099',
    padding: '13px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid transparent',
    transition: theme.transitions.create(['border-bottom']),
    '&:focus': {
      borderBottom: '1px solid green',
    },
    '&:focus-within': {
      borderBottom: '1px solid green',
    },
  },
  active: {
    borderBottom: '1px solid #FF6E00',
  },
  filled: {
    borderBottom: '1px solid #525E6A',
  },
  adornment: {
    width: 14,
    height: 14,
    lineHeight: 1,
    '& > *': {
      width: '100%',
      height: 'auto',
    },
  },
  selector: {
    marginTop: 10,
  },
  popper: {
    // renders lower than modal but needs same z to pop on top
    zIndex: 1300,
    maxHeight: '75%',
    overflowY: 'auto',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

const InputSelector = props => {
  const classes = useStyles();
  const { text, adornment = <Plus />, filled = false, open: openProp, onClick, onClickAway, children, disabled, ...rest } = props;

  const [open, setOpen] = useControlled({
    controlled: openProp,
    default: false,
    name: 'InputSelector',
    state: 'open',
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickAway = () => {
    if (!open) return;
    if (openProp !== undefined) {
      onClickAway();
    } else {
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (disabled) return;
    if (openProp !== undefined) {
      onClick();
    } else {
      setOpen(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseUp">
      <div className={clsx(classes.root, disabled && classes.disabled)} data-test-id="change-selling-currency-container">
        <div
          ref={setAnchorEl}
          className={clsx(classes.input, open && classes.active, filled && classes.filled)}
          onClick={handleClick}
          {...rest}
          aria-label={props.label}
          role="button"
        >
          {text}
          {adornment && <div className={classes.adornment}>{adornment}</div>}
        </div>
        {anchorEl && open && (
          <Popper open anchorEl={anchorEl} className={classes.popper} placement="bottom-start">
            <div className={classes.selector}>{children && children.constructor === Function ? children(setOpen) : children}</div>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default InputSelector;

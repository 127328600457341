export default function getAgencyEndpoints(build) {
  return {
    getAgency: build.query({
      query: id => `/Agencies/${id}`,
      providesTags: ['Agency'],
    }),
    patchAgency: build.mutation({
      query: updateObj => ({
        url: `/Agencies/${updateObj.id}`,
        method: 'PATCH',
        body: updateObj,
      }),
      invalidatesTags: ['Agency'],
    }),
  };
}

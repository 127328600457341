import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.tippy-box': {
      backgroundColor: '#221F20',
      padding: 20,
      fontFamily: theme.typography.fontFamily,
    },
    '.tippy-arrow': {
      color: '#221F20',
    },
    '.tippy-content': {
      padding: 0,
    },
  },
}));

export default function Tooltip(props) {
  const { children, title, delay = [0, 100], theme = 'ib2', ...rest } = props;
  useStyles();
  return (
    <Tippy content={title} disabled={!title} delay={delay} theme={theme} {...rest}>
      {children}
    </Tippy>
  );
}

const endpointName = 'TransitPoints';
const Endpoints = {
  /**
   * @description a simple search function
   * @param prop {string} = the property on which you would like to search
   * @param term {string} = the term you would like to search on
   * @returns a promise
   */
  search: function(prop, term) {
    // where = {
    //   [prop]: {
    //     like: term,
    //     options: "i"
    //   }
    // };

    const filter = {
      where: { and: [{ [prop]: { like: term, options: 'i' } }, { disabled: { neq: true } }] },
    };

    return this.get(`/api/${endpointName}?filter=${JSON.stringify(filter)}`);
  },
  /**
   * @description fetch a list of transit points
   * @returns a promise
   */
  fetchList: function() {
    return this.getData(`/${endpointName}`);
  },
};

export default Endpoints;

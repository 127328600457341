import { styled } from '@mui/material/styles';
import Bed from './icons/Bed';
import Tooltip from 'components/v2/Tooltip';
import User from 'components/v2/Icons/User';
import Child from 'components/v2/Icons/Child';
import Bullseye from 'components/v2/Icons/Bullseye';
import { size } from 'lodash';
import { supplierIsOwnAccommodation } from 'itrvl-types';
import { useBuilderStore } from './store';
import { formatDateRange } from 'utils/dates';
import { Button } from '@mui/material';

const BedIcon = styled('div')({
  lineHeight: 0,
  '& svg': {
    top: 2,
    position: 'relative',
    width: '1.5em',
    height: 'auto',
  },
});

const ChildIcon = styled(Child)({
  width: 10,
});

const UserIcon = styled(User)({
  width: 12,
});

const RoomHeader = styled('div')(({ theme }) => ({
  marginBottom: 10,
  paddingBottom: 10,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  fontSize: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  '& span': {
    fontSize: 12,
  },
}));

const BullseyeIcon = styled(Bullseye)({
  width: 25,
});

const MAX_ROOMS_TO_SHOW = 2;
function RoomsTooltip({ segments = [] }) {
  const isMulti = size(segments) > 1;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      {segments.map((segment = {}) => {
        const rooms = segment.rooms ?? [];
        const count = size(rooms);
        const roomsToShow = rooms.slice(0, MAX_ROOMS_TO_SHOW);
        const delta = count - MAX_ROOMS_TO_SHOW;
        return (
          <div key={segment.id}>
            <RoomHeader>
              {isMulti && <span>{formatDateRange(segment.startDate, segment.endDate)}</span>}
              {count} {count === 0 || count > 1 ? 'Rooms Selected' : 'Room Selected'}
            </RoomHeader>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              {roomsToShow.map((room = {}) => {
                const data = room.room ?? {};
                return (
                  <div key={room.id}>
                    <div>
                      {data.uniqueName}: {data.name}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                      {room.adults > 0 && (
                        <span>
                          {room.adults} <UserIcon />
                        </span>
                      )}
                      {room.children > 0 && (
                        <span>
                          {room.children} <ChildIcon />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
              {delta === 1 && 'And 1 more room...'}
              {delta > 1 && `And ${delta} more rooms...`}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function SelectRoomsButton({ segments = [] }) {
  const selectRooms = useBuilderStore(state => state.actions.accommodations.selectRooms);
  const editSegmentById = useBuilderStore(state => state.actions.accommodations.editSegmentById);

  if (segments.length === 0) return;

  const { supplierCode, consultantInteractionRequired, dmcArrangedOnly, id } = segments[0];

  const onClick = () => {
    if (segments.length === 1) {
      editSegmentById(id);
    } else {
      selectRooms(supplierCode);
    }
  };

  const isOwn = supplierIsOwnAccommodation(supplierCode);

  const roomCount = isOwn
    ? segments.reduce((acc, segment) => {
        // if they've selected something
        if (segment.regionCode && segment.regionName) {
          acc += 1;
        }
        return acc;
      }, 0)
    : segments.reduce((acc, segment) => {
        acc += segment?.rooms?.length ?? 0;
        return acc;
      }, 0);

  if (isOwn && roomCount === 0) {
    return (
      <Button variant="link" onClick={onClick} aria-label="select location">
        <BullseyeIcon />
        Select Location
      </Button>
    );
  }

  if (isOwn && roomCount > 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, textTransform: 'uppercase' }}>
        <Tooltip
          title={
            <>
              {segments.map(segment => (
                <div key={segment.id}>{[segment.regionName, segment.title].filter(Boolean).join(', ')}</div>
              ))}
            </>
          }
        >
          <span style={{ cursor: 'default' }}>
            {roomCount} {roomCount === 1 ? 'Location Selected' : 'Locations Selected'}
          </span>
        </Tooltip>
        <span>&bull;</span>
        <Button variant="link" onClick={onClick}>
          Edit
        </Button>
      </div>
    );
  }

  if (consultantInteractionRequired || dmcArrangedOnly) {
    return (
      <Tooltip title="Room selection not required">
        <div style={{ textTransform: 'uppercase' }}>Dates Selected</div>
      </Tooltip>
    );
  }

  if (roomCount === 0) {
    return (
      <Button variant="link" onClick={onClick} aria-label="select rooms">
        <BedIcon>
          <Bed />
        </BedIcon>
        <span>Select Rooms</span>
      </Button>
    );
  }

  if (roomCount > 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, textTransform: 'uppercase' }}>
        <Tooltip title={<RoomsTooltip segments={segments} />}>
          <span style={{ cursor: 'default' }}>
            {roomCount} {roomCount === 1 ? 'Room Selected' : 'Rooms Selected'}
          </span>
        </Tooltip>
        <span>&bull;</span>
        <Button variant="link" onClick={onClick}>
          Edit
        </Button>
      </div>
    );
  }
}

import React from 'react';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

export default TabPanel;

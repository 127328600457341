import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import compose from '../utils/compose';
import { get as _get } from 'lodash';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { withSnackbar } from 'notistack';

import {
  Typography,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  FormControl,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import TextInput from 'common/components/TextInput';

import withApi from 'common/hocs/withApi';
import { VALIDATION_EXPRESSIONS } from 'common/constants/validationExpressions';

const styles = {
  card: {
    padding: '20px',
  },
  dialogTitle: {
    borderBottom: '1px solid #DADADA',
    display: 'flex',
    justifyContent: 'space - between',
    alignItems: 'center',
    padding: '0',
  },
  title: {
    margin: '3px 0 0 0',
    fontWeight: 'bold',
    fontSize: '1.5em',
  },
  content: {
    minHeight: '300px',
    padding: '0 20px 10px',
    overflowX: 'hidden',
  },
  modalActions: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    padding: '20px',
    margin: 0,
    boxShadow: '0px 0px 5px -1px rgba(0,0,0,0.5)',
    overflow: 'hidden',
  },
  cancelButton: {
    border: '1px solid #CFD0D1',
    textTransform: 'none',
  },
  saveButton: {
    color: '#fff',
    textTransform: 'none',
  },
  formControl: {
    width: '100%',
    margin: '0 10px 0 0',
  },
  textInput: {
    margin: '15px 0 0 0',
  },
  labelOutlined: {
    transform: 'translate(14px, 35px) scale(1) !important',
  },
  labelShrink: {
    transform: 'translate(15px, 8px) scale(0.75) !important',
  },
};

export class AddClientModal extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    loading: false,
    error: {},
  };

  reset = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      loading: false,
      error: {},
    });
  };

  handleChange = () => event => {
    let { error } = this.state;
    error = { ...error, ...VALIDATION_EXPRESSIONS.validate(event.target) };
    this.setState({ ...this.state, [event.target.id]: event.target.value, error });
  };

  submit = async () => {
    const { firstName, lastName, email, phone, address } = this.state;

    if (!firstName || !lastName) {
      this.props.enqueueSnackbar('Please enter all required fields.', { variant: 'error' });
      // @todo: throw validation here
      return;
    }
    try {
      this.setState({ loading: true });
      // Create the client
      const client = await this.props.Api.createClient({
        firstName,
        lastName,
        email,
        contact: {
          phone,
          address,
        },
      });
      this.props.enqueueSnackbar('Successfully created a client.', {
        variant: 'success',
      });
      this.reset();
      this.props.onSave(client);
      return;
    } catch (err) {
      this.props.Api.handleErrorMessage(err, 'Error creating client', this.props.enqueueSnackbar);
      this.setState({ error: _get(err, 'response.data.error.details.messages') });
    }
    this.setState({ loading: false });
  };

  render() {
    const { firstName, lastName, email, phone, address, error } = this.state;
    const { classes, open, onClose } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={onClose}
        aria-labelledby="new-client-dialog-title"
        open={open}
        data-test-id="add-client-modal"
      >
        <MuiDialogTitle disableTypography={true} className={classes.dialogTitle} onClose={onClose} component="div">
          <IconButton title="Close" className={classes.closeButton} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
          <Typography id="new-client-dialog-title" component="h2" className={classes.title}>
            New Client
          </Typography>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.content}>
          <FormControl className={classes.formControl}>
            <TextInput
              required={true}
              id="firstName"
              label="First Name"
              value={firstName}
              className={classes.textInput}
              inputLabelOutlined={classes.labelOutlined}
              inputLabelShrink={classes.labelShrink}
              onChange={this.handleChange()}
              onBlur={this.handleChange()}
              error={error}
            />
            <TextInput
              required={true}
              id="lastName"
              label="Last Name"
              value={lastName}
              className={classes.textInput}
              inputLabelOutlined={classes.labelOutlined}
              inputLabelShrink={classes.labelShrink}
              onChange={this.handleChange()}
              onBlur={this.handleChange()}
              error={error}
            />
            <TextInput
              id="email"
              type="email"
              label="Email"
              value={email}
              className={classes.textInput}
              inputLabelOutlined={classes.labelOutlined}
              inputLabelShrink={classes.labelShrink}
              onChange={this.handleChange()}
              onBlur={this.handleChange()}
              error={error}
            />
            <TextInput
              id="phone"
              label="Phone"
              value={phone}
              className={classes.textInput}
              inputLabelOutlined={classes.labelOutlined}
              inputLabelShrink={classes.labelShrink}
              onChange={this.handleChange()}
              onBlur={this.handleChange()}
              error={error}
            />
            <TextInput
              id="address"
              label="Address"
              value={address}
              className={classes.textInput}
              inputLabelOutlined={classes.labelOutlined}
              inputLabelShrink={classes.labelShrink}
              onChange={this.handleChange()}
            />
          </FormControl>
        </MuiDialogContent>
        <MuiDialogActions className={classes.modalActions}>
          <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
            Cancel
          </Button>

          <Button
            className={classes.saveButton}
            color="secondary"
            variant="contained"
            onClick={this.submit}
            data-test-id="new-client-submit"
          >
            Continue
          </Button>
        </MuiDialogActions>
      </Dialog>
    );
  }
}

AddClientModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onlyEssential: PropTypes.bool,
};

export default compose(withApi, withSnackbar, withStyles(styles))(AddClientModal);

import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { camelCase } from 'lodash';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 0,
    padding: theme.spacing(1, 2),
    '&.Mui-disabled': {
      opacity: 0.4,
    },
    '&.MuiButton-colorPrimary': {
      '&.Mui-disabled': {
        color: theme.palette.stoneGrey.white80,
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.MuiButton-colorSecondary': {
      '&.Mui-disabled': {
        color: theme.palette.stoneGrey.black80,
        backgroundColor: `${theme.palette.secondary.main} !important`,
      },
    },
  },
  disabledPrimary: {
    '&:disabled': {
      color: theme.palette.stoneGrey.white80,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  disabledSecondary: {
    '&:disabled': {
      color: theme.palette.stoneGrey.black80,
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  variantLight: {
    border: '1px solid #000',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  variantError: {
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  variantBg: {
    // needed or else default hover fades opacity
    backgroundColor: '#F7F4F0 !important',
    '& svg': {
      color: '#000',
    },
  },
  wide: {
    width: 202,
  },
}));

export default function Button({ loading = false, children, ...props }) {
  const classes = useStyles();
  const icon = loading ? null : props.startIcon;
  const { disabled, className, isIcon, variantColor, wide, ...remainingProps } = props;
  return (
    <MuiButton
      className={clsx(
        classes.button,
        className,
        {
          [classes.disabledPrimary]: remainingProps.color === 'primary' && disabled,
        },
        {
          [classes.disabledSecondary]: remainingProps.color === 'secondary' && disabled,
        },
        variantColor && classes[camelCase(`variant-${variantColor}`)],
        wide && classes.wide,
      )}
      {...remainingProps}
      startIcon={icon}
      disabled={disabled || loading}
      disableElevation
    >
      {loading && <CircularProgress size={18} className={classes.loading} />}
      {(isIcon || !loading) && children}
    </MuiButton>
  );
}

import { size, get, isArray, reduce, forEach, isUndefined, toPairs } from 'lodash';
import { ITINERARY_STATE } from 'itrvl-types';
import Dinero from './dinero';
import logger from 'itrvl-logger';
const log = logger(__filename);

export const getDefaultPartyGroupAllocations = (parties = [], total = 100, precision = 100) => {
  if (!isArray(parties) || size(parties) === 0) {
    return {};
  }
  const paxPerParty = reduce(
    parties,
    (acc, party) => {
      acc.push(size(party.travelers));
      return acc;
    },
    [],
  );
  // no parties have travelers
  if (paxPerParty.every(count => count === 0)) {
    return {};
  }

  // @todo: do we need to support > 100 for the party group thingy?
  const amount = total * precision;
  // let Dinero do the allocation so it's consistent
  const allocations = Dinero({ amount, currency: 'USD' }).allocate(paxPerParty);

  // these are index based so just mash them back together
  return allocations.reduce((acc, allocation, index) => {
    acc[parties[index].id] = allocation.toUnit();
    return acc;
  }, {});
};

export const getPartyAllocations = (instance = {}, parties = [], hasPayments) => {
  if (instance.state === ITINERARY_STATE.ESTIMATE) {
    return {};
  }
  if (hasPayments && !size(instance.payments)) {
    log.warn('hasPayments option supplied, but no payments are attached to the itinerary instance.');
  }
  const segments = get(instance, 'itinerary.segments', []);
  if (size(segments) > 0 && size(parties) > 0) {
    const currency = get(instance, 'finance.currency', 'USD');

    const totalSellPrice = segments.reduce((total, segment) => total + segment.sellAmount, 0);
    const defaultPartyGroupAllocations = getDefaultPartyGroupAllocations(parties);

    const paymentsTotal =
      (hasPayments &&
        instance.payments.reduce((total, payment) => total + payment.amount + payment.serviceFee + -1 * (payment.discount ?? 0), 0)) ??
      0;
    const deposit = hasPayments && instance.payments.find(payment => payment.paymentType === 'deposit');

    const partyTotals = reduce(
      parties,
      (acc, party) => {
        acc[party.id] = 0;
        return acc;
      },
      {},
    );

    segments.forEach(segment => {
      forEach(parties, party => {
        let allocation = get(party, `allocations.${segment.id}.allocation`);
        if (isUndefined(allocation)) {
          allocation = defaultPartyGroupAllocations[party.id];
        }
        // weighted value
        partyTotals[party.id] += segment.sellAmount * (allocation / 100);
      });
    });

    const partyPercentages = {};
    Object.keys(partyTotals).forEach(party => {
      if (totalSellPrice > 0) {
        partyPercentages[party] = (partyTotals[party] / totalSellPrice) * 100; // as a percentage
      } else {
        partyPercentages[party] = defaultPartyGroupAllocations[party];
      }
    });

    const pairs = toPairs(partyPercentages);
    const allocations = pairs.map(([_k, v]) => v);

    const sellAmount = Dinero({ amount: hasPayments ? paymentsTotal : totalSellPrice, currency }).allocate(allocations);

    let depositAllocationAmount = 0;
    if (hasPayments && deposit) {
      depositAllocationAmount = deposit.amount + -1 * (deposit.discount || 0);
    } else if (hasPayments && !deposit) {
      depositAllocationAmount = 0;
    } else {
      depositAllocationAmount = instance.finance.deposit;
    }

    const depositAllocation = Dinero({
      amount: depositAllocationAmount,
      currency,
    }).allocate(allocations);

    let depositFeeAmount = 0;
    if (hasPayments && deposit) {
      depositFeeAmount = deposit.serviceFee;
    } else if (hasPayments && !deposit) {
      depositFeeAmount = 0;
    } else {
      depositFeeAmount = instance.finance.deposit_fee_credit;
    }

    const depositFeeCreditAllocation = Dinero({
      amount: depositFeeAmount,
      currency,
    }).allocate(allocations);

    return reduce(
      pairs,
      (acc, [partyId], index) => {
        acc[partyId] = {
          sell: sellAmount[index].getAmount(),
          deposit: depositAllocation[index].getAmount(),
          deposit_fee_credit: depositFeeCreditAllocation[index].getAmount(),
          // @todo: we don't use this anywhere
          // deposit_fee_ach: depositFeeAchAllocation[index].getAmount(),
          sell_fee_credit: 0,
          // @todo: we don't use this anywhere
          // sell_fee_ach: sellFeeAchAllocation[index].getAmount(),
          currency,
          weight: partyPercentages[partyId],
        };
        return acc;
      },
      {},
    );
  }
  return {};
};

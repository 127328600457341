import React from 'react';
import { UserContext } from 'common/context/UserContext';

function withUser(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <UserContext.Consumer>{context => <WrappedComponent {...this.props} userContext={context} />}</UserContext.Consumer>;
    }
  };
}

export default withUser;

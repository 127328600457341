import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getWeeksInMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';

import Day from './Day';
import { CircularProgress } from '@mui/material';

import ChevronLeftIcon from './icons/ChevronLeft';
import ChevronRightIcon from './icons/ChevronRight';

import { formatDate } from './utils';
import { useBackgroundAvailabilityPoll, usePrefetchMonth, useSupplierBounds } from './hooks';
import { useBuilderStore } from './store';
import { queryClient } from './ItineraryBuilderEntry';

const CELL_SIZE = 30;
const useStyles = makeStyles(
  theme => ({
    root: {
      // height: '100%',
      width: 282,
      padding: 20,
      '& header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
        '& svg': {
          width: 9,
          height: 'auto',
        },
      },
    },
    arrow: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        transition: theme.transitions.create(['color']),
      },
      '&:hover svg': {
        color: theme.palette.secondary.main,
      },
    },
    days: {
      display: 'grid',
      gridTemplateColumns: `repeat(7, ${CELL_SIZE}px)`,
      gridTemplateRows: 20,
      borderBottom: '1px solid #ccc',
      marginBottom: 10,
      gap: 5,
      alignItems: 'center',
      justifyItems: 'center',
    },
    body: {
      display: 'grid',
      gridTemplateColumns: `repeat(7, ${CELL_SIZE}px)`,
      gridAutoRows: CELL_SIZE,
      gap: 5,
      // padding: 20,
      height: '100%',
      justifyItems: 'center',
      alignItems: 'center',
    },
    header: {
      position: 'relative',
      lineHeight: 1,
    },
    headerLoader: {
      position: 'absolute',
      right: -20,
      top: 0,
    },
    buttonUnset: {
      backgroundColor: 'unset',
      border: 'unset',
      padding: 1,
    },
  }),
  {
    name: 'IB2Cal',
  },
);

const DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const Cal = props => {
  const classes = useStyles();
  const { supplierCode, enabled, data, overrides = {} } = props;
  const { date } = useSupplierBounds(supplierCode);
  const setSupplierMonth = useBuilderStore(state => state.actions.setSupplierMonth);

  // @todo: pipe in quoteOnly stuff here, for enabled
  const prefetchMonth = usePrefetchMonth(supplierCode, enabled);

  const isLoading = useBackgroundAvailabilityPoll();

  const yearMonth = format(date, 'yyyy-MM');
  const prevDate = React.useRef();
  if (prevDate?.current !== yearMonth) {
    if (prevDate?.current) {
      queryClient.cancelQueries({ queryKey: ['availability', supplierCode] });
    }
    prevDate.current = yearMonth;
    prefetchMonth(date, false);
  }

  const start = startOfWeek(startOfMonth(date));
  const weeks = getWeeksInMonth(date);
  const delta = 6 - weeks;
  const end = addWeeks(endOfWeek(endOfMonth(date)), delta);

  const days = eachDayOfInterval({
    start,
    end,
  });

  return (
    <div className={clsx(classes.root, 'ib2-cal')}>
      <header>
        <button
          aria-label="previous month"
          className={clsx(classes.arrow, classes.buttonUnset)}
          onClick={() => {
            // could DRY out more
            queryClient.cancelQueries({ queryKey: ['availability', supplierCode] });
            const next = startOfMonth(subMonths(date, 1));
            prefetchMonth(next, true);
            setSupplierMonth(supplierCode, next);
          }}
        >
          <ChevronLeftIcon />
        </button>
        <div className={classes.header}>
          {format(date, 'MMMM Y')}{' '}
          {isLoading && (
            <div className={classes.headerLoader} data-test-id="loading-camp-availability">
              <CircularProgress size={12} color="secondary" />
            </div>
          )}
        </div>
        <button
          aria-label="next month"
          className={clsx(classes.arrow, classes.buttonUnset)}
          onClick={() => {
            // could DRY out more
            queryClient.cancelQueries({ queryKey: ['availability', supplierCode] });
            const next = startOfMonth(addMonths(date, 1));
            prefetchMonth(next, true);
            setSupplierMonth(supplierCode, next);
          }}
        >
          <ChevronRightIcon />
        </button>
      </header>
      <div className={clsx(classes.days, 'ib-cal-days')}>
        {DAYS.map(day => (
          <div key={day} data-test-id={day}>
            {day}
          </div>
        ))}
      </div>
      <div className={clsx(classes.body, 'ib2-cal-body')} data-test-id="ib2-calendar">
        {days.map(date => {
          const dateString = formatDate(date);
          return <Day key={dateString} supplierCode={supplierCode} dateString={dateString} data={data} overrides={overrides} />;
        })}
      </div>
    </div>
  );
};

export default React.memo(Cal);

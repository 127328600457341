import { Controller } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@mui/material';

export default function CheckboxRhf({ name, control, label, rules, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControlLabel
            control={<Checkbox {...rest} checked={value} onChange={onChange} name={name} color="secondary" />}
            label={label}
          />
        );
      }}
    />
  );
}

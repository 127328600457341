import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { size } from 'lodash';

import { Button } from '@mui/material';
import InputSelector from '../ItineraryBuilder/InputSelector';
import TravelerInput from '../ItineraryBuilder/TravelerInput';

import UserIcon from 'components/v2/Icons/User';
import ChildIcon from 'components/v2/Icons/Child';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { CHILDREN_MAXIMUM_AGE } from '../ItineraryBuilder/constants';
import { noop } from 'common';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    padding: `24px 24px 12px 24px`,
    display: 'flex',
    flexDirection: 'column',
  },
  selector: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    maxHeight: 419,
    overflowY: 'auto',
  },
  btn: {
    marginTop: 12,
    marginLeft: 'auto',
  },
}));

export const CHILDREN_DEFAULT_AGE = 12;

const TravelersSelector = ({ adults = 0, children = 0, childrenAges = [], setTravelersByType = noop, onChildrenAgeUpdate = noop }) => {
  const classes = useStyles();
  const total = (adults || 0) + (children || 0);

  const changeChildren = value => {
    let newAges = [...childrenAges];
    const from = size(childrenAges);
    if (value > from) {
      newAges = newAges.concat(Array.from({ length: value - from }).fill(CHILDREN_DEFAULT_AGE));
    } else {
      newAges = newAges.slice(0, value);
    }
    setTravelersByType('children', value);
    onChildrenAgeUpdate(newAges);
  };

  const setChildrenAgeByIndex = (index, value) => {
    const clone = [...childrenAges];
    clone[index] = value;
    onChildrenAgeUpdate(clone);
  };

  return (
    <div className={classes.root}>
      <InputSelector
        text={total === 0 ? 'Number of Travelers' : `${total} Total Traveler${total > 1 ? 's' : ''}`}
        filled={total > 0}
        label="travelers selector"
      >
        {setOpen => (
          <div className={classes.paper}>
            <div className={classes.selector}>
              <TravelerInput
                label="# of Adults"
                value={adults}
                id="adults"
                maximumValue={100}
                increment={value => setTravelersByType('adults', value)}
                decrement={value => setTravelersByType('adults', value)}
                onChange={(_e, val) => setTravelersByType('adults', val)}
                glyph={<UserIcon />}
                ariaLabel="adults"
              />
              <TravelerInput
                label="# of Children"
                value={children}
                maximumValue={100}
                increment={value => changeChildren(value)}
                decrement={value => changeChildren(value)}
                onChange={(_e, value) => changeChildren(value)}
                glyph={<ChildIcon />}
                ariaLabel="children"
              />

              {/* @todo: we need to have input for each child */}
              {size(childrenAges) > 0 && <div>Children ages are needed</div>}
              {childrenAges.map((age, index) => (
                <div key={index}>
                  <TravelerInput
                    label={`child ${index + 1} age`}
                    value={age}
                    maximumValue={CHILDREN_MAXIMUM_AGE}
                    increment={value => setChildrenAgeByIndex(index, value)}
                    decrement={value => setChildrenAgeByIndex(index, value)}
                    onChange={e => setChildrenAgeByIndex(index, e.target.value)}
                    glyph={<SubdirectoryArrowRightIcon />}
                    ariaLabel={`child ${index + 1} age`}
                  />
                </div>
              ))}
            </div>
            <Button className={classes.btn} onClick={() => setOpen(false)}>
              Done
            </Button>
          </div>
        )}
      </InputSelector>
    </div>
  );
};

export default React.memo(TravelersSelector);

const endpointName = 'Rates';
const Rates = {
  supplierLowestPpRateForMonth: async function(date, supplierCode) {
    const response = await this.get(`/api/${endpointName}/lowest-rate`, {
      params: {
        date,
        supplierCode,
      },
    });
    return response?.status === 200 ? response.data : {};
  },
};

export default Rates;

import './MuiClassNameSetup'; // First import for override.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeContext } from './context/ThemeContext';

class ItrvlThemeProvider extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const theme = this.props.value.theme;
    const value = this.props.value;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ThemeContext.Provider value={value}>{this.props.children}</ThemeContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default ItrvlThemeProvider;

import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, CircularProgress } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'react-use';
import { useRegionsSearchPagedQuery } from 'queries/regions';

function Loader({ fetchNextPage }) {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px 300px 0px',
    // could probably use a ref
    root: document.querySelector('#region-list'),
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);
  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={18} /> Loading
    </div>
  );
}

const ITEMS_PER_PAGE = 50;
const DEBOUNCE_DELAY = 300;

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { hasNextPage, fetchNextPage, isLoading, ...rest } = props;
  return (
    <ul {...rest} ref={ref}>
      {props.children}
      {/* bolt on an end of list listener */}
      {!isLoading && hasNextPage && <Loader fetchNextPage={fetchNextPage} />}
    </ul>
  );
});

export default function RegionAutocomplete({ value, onChange }) {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const listboxRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  useDebounce(
    () => {
      setSearchTerm(inputValue);
    },
    DEBOUNCE_DELAY,
    [inputValue, setSearchTerm],
  );

  const { data, fetchNextPage, hasNextPage, isLoading } = useRegionsSearchPagedQuery({
    term: searchTerm,
    enabled: open,
    pageSize: ITEMS_PER_PAGE,
  });

  const options = React.useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap(page => page.data);
  }, [data]);

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={isLoading}
      inputValue={inputValue}
      // this disables "search as you type", needed or you get conflicting UI between
      // internal search and the delay to server roundtrip
      filterOptions={x => x}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.regionCode === value?.regionCode;
      }}
      getOptionKey={option => option?.regionCode}
      getOptionLabel={option => option?.name ?? ''}
      groupBy={option => option?.country?.name}
      renderInput={params => (
        <TextField
          variant="standard"
          InputLabelProps={{ shrink: false }}
          {...params}
          placeholder="Select a region"
          inputProps={{
            ...params.inputProps, // merge in the default input props
            'aria-label': 'select a region',
          }}
        />
      )}
      renderOption={(props, option) => {
        // spreading a "key" shows an error
        const { key, ...rest } = props;
        return (
          <Box component="li" key={key} {...rest}>
            {option.name} ({option.regionCode})
          </Box>
        );
      }}
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        ref: listboxRef,
        id: 'region-list',
        hasNextPage,
        fetchNextPage,
        isLoading,
      }}
    />
  );
}

import React from 'react';
import { Fade } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'components/v2/Button';
import AsyncButton from 'components/v2/AsyncButton';
import LoadingOverlay from 'components/v2/LoadingOverlay';
import IsDirtyPrompt from 'components/v2/FormElements/IsDirtyPrompt';

const useStyles = makeStyles({
  buttonRow: {
    position: 'sticky',
    zIndex: 2,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto auto',
    gridTemplateAreas: `
      "description"
      "buttonGroup"
      "dirtyMessage"
    `,
    gap: '0 20px',
    top: 0,
    '@media (min-width: 1200px)': {
      gridTemplateColumns: 'auto 1fr auto',
      gridTemplateAreas: `
        "description . buttonGroup"
        ". . dirtyMessage"
      `,
      alignItems: 'center',
    },
  },
  controlButton: {
    minWidth: '90px',
  },
  buttonGroup: {
    gridArea: 'buttonGroup',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    flexWrap: 'wrap',
  },
  description: {
    gridArea: 'description',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  dirtyMessage: {
    gridArea: 'dirtyMessage',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontWeight: 500,
    color: 'var(--secondary-color)',
  },
});

const FormControlPanel = props => {
  const classes = useStyles();
  const { isDirty, handlers = {}, options = {}, onSubmit, additionalButtons = [], children, ...rest } = props;
  const { handleReset, handleCancel } = handlers;
  const { loading, resetDisabled } = options;

  return (
    <>
      <IsDirtyPrompt isDirty={isDirty} />
      <div className={classes.buttonRow}>
        <div className={classes.description}>{children}</div>
        <div className={classes.buttonGroup}>
          {handleReset && (
            <Button
              variant="outlined"
              onClick={handleReset}
              className={clsx(classes.controlButton)}
              disabled={loading || resetDisabled}
              {...rest}
            >
              Reset to defaults
            </Button>
          )}
          {handleCancel && (
            <Button
              variant="outlined"
              disabled={!isDirty || loading}
              onClick={handleCancel}
              className={clsx(classes.controlButton)}
              color="inherit"
              {...rest}
            >
              Cancel
            </Button>
          )}
          <AsyncButton
            loading={loading}
            variant={!isDirty ? 'outlined' : 'contained'}
            color={!isDirty ? 'inherit' : 'primary'}
            disabled={!isDirty}
            onClick={onSubmit}
            classes={{ root: classes.controlButton }}
            {...rest}
          >
            Save
            {loading && <LoadingOverlay open={loading} />}
          </AsyncButton>
          {additionalButtons.map((buttonProps, index) => (
            <Button key={index} {...buttonProps} />
          ))}
        </div>
        <Fade className={classes.dirtyMessage} in={isDirty}>
          <div style={{ marginTop: '12px' }}>* You have unsaved changes</div>
        </Fade>
      </div>
    </>
  );
};

export default FormControlPanel;

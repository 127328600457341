import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { startOfMonth } from 'date-fns';
import { formatDate } from 'utils/v2/dates';
import { useInView } from 'react-intersection-observer';
import { usePpQuery } from './queries';

const useStyles = makeStyles({
  root: {},
});

const AccommodationPricePerPerson = ({ date, supplierCode }) => {
  const classes = useStyles();
  const { ref, inView } = useInView();
  const startDateString = date && formatDate(startOfMonth(date));
  const query = usePpQuery(startDateString, supplierCode, inView && Boolean(startDateString));
  const shouldRender = !query.isError && !query.isLoading && query.isFetched && query?.data?.roomRatePP > 0;
  return (
    <div ref={ref} className={classes.root}>
      {shouldRender ? <div data-test-id="indicative-rate">From ${Math.round(query.data.roomRatePP)} per person</div> : <></>}
    </div>
  );
};

export default AccommodationPricePerPerson;

import { get, reduce } from 'lodash';

import { calculateRateForRooms } from './rates';
import { checkAvailabilityForRooms } from './availability';

import logger from 'itrvl-logger';
const log = logger(__filename);

const CALENDAR_STATE = {
  AVAILABLE: 'available',
  CONFIG_UNAVAILABLE: 'config', // This configuration is unavailable but another may be available
  AVAILABLE_ON_REQUEST: 'request',
  AVAILABLE_ON_REQUEST_CHECK: 'request_check',
  AVAILABLE_VIA_DMC: 'dmcarranged',
  UNAVAILABLE: 'unavailable',
  NO_LONGER_AVAILABLE: 'nolonger_available',
  CLOSED: 'closed', // Accommodation is closed
  LOADING: 'loading', // Availability is still loading - indeterminate, show spinner
  UNKNOWN: 'unknown', // An unknown / error state
  QUOTE_ONLY: 'quote_only', // Quote only mode, doesn't need availability check
};

// supplierRatesForDate: must include all rates that are available for this date, not just the ones for the requested rooms
const calendarStateForAvailabilityAndRates = (
  supplierAvailabilityForDate,
  supplierRatesForDate,
  supplierRoomTypes,
  requestedRooms,
  accommodationInfo,
) => {
  let state = CALENDAR_STATE.UNKNOWN;
  let rate;

  const dmcArrangedOnly = get(accommodationInfo, 'dmcArrangedOnly');
  const consultantInteractionRequired = get(accommodationInfo, 'consultantInteractionRequired');
  const online = get(accommodationInfo, 'online');
  const supplierCode = get(accommodationInfo, 'supplierCode');

  if (dmcArrangedOnly === true) {
    return { state: CALENDAR_STATE.AVAILABLE_VIA_DMC, rate };
  }
  if (consultantInteractionRequired === true) {
    return { state: CALENDAR_STATE.AVAILABLE_ON_REQUEST, rate };
  }

  log.debug('supplierRatesForDate: ', supplierRatesForDate);
  log.debug('rooms: ', requestedRooms);
  // @TODO: refactor other calculateRateForRooms, checkAvailabilityForRooms callers so we don't have to swizzle this data
  const rateForAllRooms = calculateRateForRooms(supplierCode, supplierRatesForDate, requestedRooms, {
    [[supplierCode]]: supplierRoomTypes,
  });
  const availabilityForAllRooms = checkAvailabilityForRooms(supplierCode, supplierAvailabilityForDate, requestedRooms, {
    [[supplierCode]]: supplierRoomTypes,
  });
  const rateConfigUnavailable = get(rateForAllRooms, 'configNotAvailable', false);
  const rateRuleFound = get(rateForAllRooms, 'ruleFound', false);
  const rawRate = get(rateForAllRooms, 'rawRate.amount');
  const currency = get(rateForAllRooms, 'rawRate.currency');
  const availabilityConfigUnavailable = get(availabilityForAllRooms, 'configNotAvailable', false);
  const thisRoomTypeAvailabilityMissing = get(availabilityForAllRooms, 'thisRoomTypeNotFound', false);
  const rateClosed = get(rateForAllRooms, 'isClosed', false);
  const roomTypeNotPresent = get(rateForAllRooms, 'roomTypeNotPresent', false);
  const { allRatesClosed, definedRatesForDate } = reduce(
    supplierRatesForDate,
    (acc, rate) => {
      if (rate) acc.definedRatesForDate++;
      const prov = get(rate, 'prov');
      if (prov && prov !== 'C') {
        acc.allRatesClosed = false;
      }
      return acc;
    },
    { allRatesClosed: true, definedRatesForDate: 0 },
  );

  log.debug('rateForAllRooms: ', rateForAllRooms);
  log.debug('availabilityForAllRooms: ', availabilityForAllRooms);

  log.debug(
    `online ${online}, rateClosed ${rateClosed}, allRatesClosed: ${allRatesClosed}, definedRatesForDate: ${definedRatesForDate}, rateRuleFound ${rateRuleFound}, rateConfigUnavailable ${rateConfigUnavailable}, availabilityConfigUnavailable ${availabilityConfigUnavailable}, roomTypeNotPresent: ${roomTypeNotPresent}, thisRoomTypeAvailabilityMissing: ${thisRoomTypeAvailabilityMissing}`,
  );
  if (online) {
    // Consider rates & availability
    if (rateConfigUnavailable || availabilityConfigUnavailable) {
      state = CALENDAR_STATE.CONFIG_UNAVAILABLE;
    } else {
      state = CALENDAR_STATE.AVAILABLE;
      rate = rawRate;
    }
    // Online falls back to on request if all availability is missing
    if (rateConfigUnavailable === false && supplierAvailabilityForDate === undefined) {
      state = CALENDAR_STATE.AVAILABLE_ON_REQUEST_CHECK;
      rate = rawRate;
    }
    // Online falls back to on request if specific availability is missing
    if (rateConfigUnavailable === false && supplierAvailabilityForDate !== undefined && thisRoomTypeAvailabilityMissing) {
      state = CALENDAR_STATE.AVAILABLE_ON_REQUEST_CHECK;
      rate = rawRate;
    }
    // Online falls back to on request if rates are missing
    if (allRatesClosed === true && definedRatesForDate === 0) {
      state = CALENDAR_STATE.AVAILABLE_ON_REQUEST;
      rate = rawRate;
    }
  } else {
    if (rateConfigUnavailable) {
      state = CALENDAR_STATE.CONFIG_UNAVAILABLE;
    } else {
      state = CALENDAR_STATE.AVAILABLE_ON_REQUEST;
      rate = rawRate;
    }
  }

  if (!rateConfigUnavailable && !rateRuleFound) {
    if (state !== CALENDAR_STATE.AVAILABLE_ON_REQUEST_CHECK) {
      state = CALENDAR_STATE.AVAILABLE_ON_REQUEST;
    }
  }

  // Always config unavailable if a system room type is never at this accommodation
  if (roomTypeNotPresent) {
    state = CALENDAR_STATE.CONFIG_UNAVAILABLE;
  }

  // rateClosed trumps everything as long as there are rates to consider; leave as is if no rates (far future / unknown)
  if (rateClosed) {
    if (definedRatesForDate !== 0) {
      state = allRatesClosed ? CALENDAR_STATE.CLOSED : CALENDAR_STATE.CONFIG_UNAVAILABLE;
    } else {
      // this rate is closed, others may have been available but we don't have enough information
      state = CALENDAR_STATE.CONFIG_UNAVAILABLE;
    }
  }

  return { state, rate, rateForAllRooms, currency, availabilityForAllRooms };
};

const getMinChildAge = (children, childrenAges) => {
  return children > 0 && childrenAges ? Math.min(...childrenAges) : undefined;
};

export { CALENDAR_STATE, calendarStateForAvailabilityAndRates, getMinChildAge };

import React, { useEffect, useContext, useCallback } from 'react';
import { useForm, useWatch, useFormState } from 'react-hook-form';
import { useIsMutating } from '@tanstack/react-query';
import { UserContext } from 'common';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import * as yup from 'yup';

import { usePatchAgentMutation, usePatchAgencyMutation, useAgentSetPasswordMutation, useAgentLoginMutation } from 'api';
import { useUploadMedia, useUploadToS3, useUpdateMediaContent } from 'mutations/mediaContent';
import { handleError, handleSuccess } from 'components/handleSnackBarMessages';
import { getAgentDataWithoutExcludedKeys } from './utils/agentUtils';

import Header from './Header';
import FormControlPanel from './FormControlPanel';
import InputRhf from 'components/v2/FormElements/InputRhf';
import CheckboxRhf from 'components/v2/FormElements/CheckboxRhf';
import ResetPasswordInputRhf from './ResetPasswordInputRhf';
import Section from './Section';
import useStyles from './index.styles';

// TEMPORARY IMPORT
import SingleFileUploader from '../MyAgency/AgencyDetailsTab/SingleFileUploader';

import logger from 'itrvl-logger';
const log = logger(__filename);

const agentSchema = yup
  .object({
    firstName: yup
      .string()
      .required('Please enter a First Name')
      .test('no-leading-trailing-spaces', 'No leading or trailing whitespace', value => value === value.trim()),
    lastName: yup
      .string()
      .required('Please enter a Last Name')
      .test('no-leading-trailing-spaces', 'No leading or trailing whitespace', value => value === value.trim()),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
    isLead: yup.boolean(),
    isContentManager: yup.boolean(),
    newPassword: yup
      .string()
      .transform(value => (value === '' ? undefined : value))
      .notRequired()
      .min(5, 'Password must be at least 5 characters')
      .test('no-leading-trailing-spaces', 'No leading or trailing whitespace', value => (value ? value === value.trim() : true)),
    confirmPassword: yup.string().when('newPassword', {
      is: value => !!value,
      then: schema => schema.required('Please confirm your password').oneOf([yup.ref('newPassword')], 'Passwords must match'),
      otherwise: schema => schema.notRequired(),
    }),
  })
  .required();

const isLead = agent => get(agent, 'agency.admins', []).includes(agent.id);
const isContentManager = agent => get(agent, 'capabilities', []).includes('contentmanager');

const getAgentData = agent => ({
  agencyId: agent?.agencyId,
  firstName: agent?.firstName || '',
  lastName: agent?.lastName || '',
  phone: agent?.phone || '',
  email: agent?.email || '',
  bio: agent?.bio || '',
  headshotId: agent?.headshotId || null,
  headshotUri: agent?.headshotUri || null,
  signatureId: agent?.signatureId || null,
  signatureUri: agent?.signatureUri || null,
  newPassword: '',
  confirmPassword: '',
  isLead: isLead(agent),
  isContentManager: isContentManager(agent),
});

export default function MyProfileMain({ agent, agentId, isEditingOwnProfile }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Users
  const { isSuperAdmin, isLeadAgent, isMasquerading } = useContext(UserContext);
  const isAdmin = isSuperAdmin() || isLeadAgent();
  const isMasquerade = isMasquerading();

  // Hooks
  const patchAgentMutation = usePatchAgentMutation();
  const patchAgencyMutation = usePatchAgencyMutation();
  const updateAgentPassword = useAgentSetPasswordMutation();
  const agentLogin = useAgentLoginMutation();
  const uploadMedia = useUploadMedia();
  const uploadToS3 = useUploadToS3();
  const updateMediaContent = useUpdateMediaContent();

  const isMutating = useIsMutating();
  const loading = isMutating > 0;

  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(agentSchema),
    mode: 'onChange',
    defaultValues: getAgentData(agent),
  });

  const { dirtyFields, isDirty } = useFormState({ control });

  // Watches
  const headshotUriWatch = useWatch({ control, name: 'headshotUri' });
  const signatureUriWatch = useWatch({ control, name: 'signatureUri' });
  const firstName = useWatch({ control, name: 'firstName' });
  const lastName = useWatch({ control, name: 'lastName' });
  const agentFirstAndLastName = `${firstName} ${lastName}`.trim();

  const getChangedData = useCallback((data, dirtyFields) => {
    const changedData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    // Check if firstName or lastName has changed and include fullName in in the patch
    if (dirtyFields.firstName || dirtyFields.lastName) {
      changedData.fullName = `${data.firstName} ${data.lastName}`;
    }
    return changedData;
  }, []);

  const handlePatchAgent = useCallback(
    async data => {
      await patchAgentMutation.mutateAsync({ agentId, agencyId: agent.agencyId, data });
    },
    [agentId, agent.agencyId, patchAgentMutation],
  );

  const handlePatchAgency = useCallback(
    async data => {
      await patchAgencyMutation.mutateAsync({ agencyId: agent.agencyId, data });
    },
    [agent.agencyId, patchAgencyMutation],
  );

  const handleAgentData = useCallback(
    async agentData => {
      const mediaKeys = ['headshotUri', 'signatureUri'];
      const relatedMediaKeys = { headshotUri: 'headshotId', signatureUri: 'signatureId' };
      const excludedKeys = ['newPassword', 'confirmPassword', 'isLead', 'isContentManager'];
      const agentDataWithoutExcludedKeys = getAgentDataWithoutExcludedKeys(agentData, excludedKeys);

      // Create a new object for deletions
      const agentDataWithDeletions = {};

      // Include media keys that are null for deletion
      mediaKeys.forEach(key => {
        if (agentData[key] === null) {
          const relatedMediaId = relatedMediaKeys[key];
          agentDataWithDeletions[relatedMediaId] = null;
          agentDataWithDeletions[key] = null;
        }
      });

      // Combine data for the final patch, removing media keys from the previous step
      const agentDataWithoutMedia = { ...agentDataWithoutExcludedKeys };
      mediaKeys.forEach(key => {
        delete agentDataWithoutMedia[key];
      });

      const finalAgentData = { ...agentDataWithoutMedia, ...agentDataWithDeletions };

      if (Object.keys(finalAgentData).length > 0) {
        await handlePatchAgent(finalAgentData);
      }
    },
    [handlePatchAgent],
  );

  const handleIsLeadChange = useCallback(
    async isLead => {
      const currentAdmins = agent.agency.admins || [];
      let updatedAdmins;

      if (isLead) {
        // Add agent ID to admins array if not already present
        updatedAdmins = currentAdmins.includes(agent.id) ? currentAdmins : [...currentAdmins, agent.id];
      } else {
        updatedAdmins = currentAdmins.filter(adminId => adminId !== agent.id);
      }
      await handlePatchAgency({ admins: updatedAdmins });
    },
    [agent, handlePatchAgency],
  );

  const handleIsContentManagerChange = useCallback(
    async isContentManager => {
      const currentCapabilities = agent.capabilities || [];
      let updatedCapabilities;

      if (isContentManager) {
        // Add 'contentmanager' to capabilities if not already present
        updatedCapabilities = currentCapabilities.includes('contentmanager')
          ? currentCapabilities
          : [...currentCapabilities, 'contentmanager'];
      } else {
        // Remove 'contentmanager' from capabilities
        updatedCapabilities = currentCapabilities.filter(capability => capability !== 'contentmanager');
      }
      await handlePatchAgent({ capabilities: updatedCapabilities });
    },
    [agent, handlePatchAgent],
  );

  const handleCancelImagePreview = useCallback(
    fieldName => {
      const defaultValues = {
        headshotUri: get(agent, 'headshotUri') || null,
        signatureUri: get(agent, 'signatureUri') || null,
      };
      setValue(fieldName, defaultValues[fieldName], { shouldDirty: true });
    },
    [agent, setValue],
  );

  const handleDeleteMedia = useCallback(
    fieldName => {
      setValue(fieldName, null, { shouldDirty: true });
    },
    [setValue],
  );

  const handleAgentRoleChanges = useCallback(
    async ({ isLead, isContentManager }) => {
      if (isLead !== undefined) {
        await handleIsLeadChange(isLead);
      }

      if (isContentManager !== undefined) {
        await handleIsContentManagerChange(isContentManager);
      }
    },
    [handleIsLeadChange, handleIsContentManagerChange],
  );

  const handleMediaUploads = useCallback(
    async agentData => {
      const mediaKeys = ['headshotUri', 'signatureUri'];
      const mediaToUpload = mediaKeys
        .filter(key => agentData[key] && agentData[key] !== null)
        .map(key => {
          const customizationType = key === 'headshotUri' ? 'headshot' : 'signature';
          return {
            file: agentData[key],
            mediaMetadata: { agentId, customizationType },
          };
        });

      if (mediaToUpload.length === 0) {
        // No media to upload, return an empty object
        return {};
      }

      // Proceed with the media upload process if there are files to upload
      const files = mediaToUpload.map(item => item.file);
      const metadata = mediaToUpload.map(item => item.mediaMetadata);

      const uploadMediaResponse = await uploadMedia.mutateAsync({ files, mediaMetadata: metadata });

      if (!uploadMediaResponse || !Array.isArray(uploadMediaResponse)) {
        throw new Error('Invalid response from media upload');
      }

      const filesToUploadToS3 = uploadMediaResponse.map((item, index) => ({
        file: mediaToUpload[index].file,
        data: item,
      }));

      await uploadToS3.mutateAsync(filesToUploadToS3);

      const mediaContentsToUpdate = uploadMediaResponse.map(item => ({
        id: item.id,
        presentationVisibility: true,
        status: 'approved',
        userFilename: item.userFilename,
        mimeType: item.mimeType,
        size: item.size,
        s3PresignedUrl: item.s3PresignedUrl,
        s3Bucket: item.s3Bucket,
        s3Key: item.s3Key,
        agentId: item.agentId,
        customizationType: item.customizationType,
        priority: item.priority,
        createdBy: item.createdBy,
      }));

      await updateMediaContent.mutateAsync(mediaContentsToUpdate);

      try {
        const updatedMediaData = mediaContentsToUpdate.reduce((acc, item) => {
          const idKey = `${item.customizationType}Id`;
          const uriKey = `${item.customizationType}Uri`;
          acc[idKey] = item.id;
          acc[uriKey] = item.s3PresignedUrl.split('?')[0];
          return acc;
        }, {});

        return updatedMediaData;
      } catch (err) {
        handleError(err, 'An error occurred during media uploads.', enqueueSnackbar, log);
        throw err;
      }
    },
    [uploadMedia, uploadToS3, updateMediaContent, agentId, enqueueSnackbar],
  );

  const handlePasswordChange = useCallback(
    async (newPassword, email) => {
      if (newPassword) {
        try {
          await updateAgentPassword.mutateAsync({ newPassword });
          if (isEditingOwnProfile && !isMasquerade) {
            await agentLogin.mutateAsync({ userEmail: email, newPassword });
          }
        } catch (err) {
          log.warn('Password update or login error', err);
          handleError(err, 'An error occurred while updating agent password.', enqueueSnackbar, log);
          throw err;
        }
      }
    },
    [updateAgentPassword, agentLogin, enqueueSnackbar, isEditingOwnProfile, isMasquerade],
  );

  const handleCancel = useCallback(() => {
    const fieldsToReset = {
      newPassword: '',
      confirmPassword: '',
    };

    Object.keys(fieldsToReset).forEach(key => {
      setValue(key, fieldsToReset[key], { shouldDirty: true });
    });

    reset(getAgentData(agent));
  }, [agent, reset, setValue]);

  const onSubmit = useCallback(
    async data => {
      try {
        // Step 1: Get changed data from form
        const changedData = getChangedData(data, dirtyFields);

        // Step 2: Remove excluded keys, media handling
        await handleAgentData(changedData);

        // Step 3: Handle role changes if needed
        await handleAgentRoleChanges(changedData);

        // Step 4: Handle media uploads
        const updatedMediaData = await handleMediaUploads(changedData);
        if (Object.keys(updatedMediaData).length > 0) {
          await handlePatchAgent(updatedMediaData);
        }

        // Step 5: Handle password change separately
        const { newPassword, email } = changedData;
        await handlePasswordChange(newPassword, email || agent.email);

        // Show success message
        handleSuccess('Agent profile updated successfully.', enqueueSnackbar);

        // Reset form fields after successful submission
        reset(data);
      } catch (err) {
        handleError(err, 'An error occurred while updating your profile.', enqueueSnackbar, log);
      }
    },
    [
      dirtyFields,
      handlePatchAgent,
      enqueueSnackbar,
      handleAgentRoleChanges,
      handleMediaUploads,
      handlePasswordChange,
      reset,
      getChangedData,
      handleAgentData,
      agent,
    ],
  );

  useEffect(() => {
    if (agent) {
      setValue('headshotUri', agent?.headshotUri || null);
      setValue('signatureUri', agent?.signatureUri || null);

      reset(getAgentData(agent));
    }
  }, [agent, setValue, reset]);

  return (
    <Typography component="div" className={classes.root} style={{ backgroundColor: '#f7f4f1', flex: 1 }}>
      <div className={classes.contentContainer}>
        <Header title={agentFirstAndLastName} agentId={agentId} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formButtonRow}>
            <FormControlPanel
              title="Agent Details"
              isDirty={isDirty}
              loading={loading}
              handlers={{ handleCancel }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Typography variant="h5">Agent Details</Typography>
            </FormControlPanel>
          </div>
          <div className={classes.sectionsLayout}>
            <Section label="Contact Information" className={classes.contactInfoSection}>
              <div className={classes.firstNameInput}>
                <InputRhf control={control} name="firstName" label="First Name" placeholder=" " id="firstName" autoComplete="given-name" />
              </div>
              <div className={classes.lastNameInput}>
                <InputRhf control={control} name="lastName" label="Last Name" placeholder=" " autoComplete="family-name" />
              </div>
              <div className={classes.phoneInput}>
                <InputRhf control={control} name="phone" label="Phone" placeholder=" " autoComplete="tel" />
              </div>
              <div className={classes.emailInput}>
                <InputRhf control={control} name="email" label="Email" placeholder=" " autoComplete="email" />
              </div>
              <div className={classes.bioInput}>
                <InputRhf control={control} name="bio" label="Bio" placeholder="Write about yourself..." multiline />
              </div>
            </Section>

            {(isEditingOwnProfile || isMasquerade) && (
              <Section label="Reset Password">
                <ResetPasswordInputRhf control={control} />
              </Section>
            )}

            {!isEditingOwnProfile && isAdmin && (
              <Section label="Agent Access">
                <CheckboxRhf control={control} name="isLead" label="Lead Agent Access" style={{ whiteSpace: 'nowrap' }} />
                <CheckboxRhf control={control} name="isContentManager" label="Content Manager Access" style={{ whiteSpace: 'nowrap' }} />
              </Section>
            )}
            <Section label="Agent Media" className={classes.agentMediaSection}>
              <div className={classes.mediaUploader}>
                <SingleFileUploader
                  control={control}
                  name="headshotUri"
                  label="Profile Photo"
                  type="image"
                  url={headshotUriWatch}
                  display="logoContainer"
                  onCancelPreview={() => handleCancelImagePreview('headshotUri')}
                  onDeleteMedia={() => handleDeleteMedia('headshotUri')}
                  isCircular={true}
                />
              </div>
              <div className={classes.mediaUploader}>
                <SingleFileUploader
                  control={control}
                  name="signatureUri"
                  label="Signature"
                  type="image"
                  url={signatureUriWatch}
                  display="logoContainer"
                  onCancelPreview={() => handleCancelImagePreview('signatureUri')}
                  onDeleteMedia={() => handleDeleteMedia('signatureUri')}
                />
              </div>
            </Section>
          </div>
        </form>
      </div>
    </Typography>
  );
}

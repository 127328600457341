import { isAdmin, isContentManager, isSuperAdmin } from '../utils/acls';
import { isAgentRoute, isAdminRoute, isContentManagerRoute, isSuperAdminRoute } from '../utils/routes';

export const blocks = {
  agent: {
    test: () => true,
    filter: isAgentRoute,
  },
  admin: {
    test: isAdmin,
    label: 'Lead Agents',
    filter: isAdminRoute,
  },
  contentManager: {
    test: isContentManager,
    label: 'Content Managers',
    filter: isContentManagerRoute,
  },
  superAdmin: {
    test: isSuperAdmin,
    label: 'Super Admins',
    filter: isSuperAdminRoute,
  },
};

import { isFunction } from 'lodash';

/**
 * This was actually taken from
 * https://github.com/smartprocure/react-freshchat/blob/master/src/react-freshchat.js
 * deciding not to use the component, as it doesn't offer everything that is needed
 * but I liked this class
 */
class Queue {
  constructor() {
    this.data = [];
    this.index = 0;
  }

  queue(value) {
    this.data.push(value);
  }

  dequeue() {
    if (this.index > -1 && this.index < this.data.length) {
      let result = this.data[this.index++];

      if (this.isEmpty) {
        this.reset();
      }

      return result;
    }
  }

  get isEmpty() {
    return this.index >= this.data.length;
  }

  dequeueAll(cb) {
    if (!isFunction(cb)) {
      throw new TypeError(`Please provide a callback`);
    }

    while (!this.isEmpty) {
      let { method, args } = this.dequeue();
      cb(method, args);
    }
  }

  reset() {
    this.data.length = 0;
    this.index = 0;
  }
}

export default Queue;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import withStyles from '@mui/styles/withStyles';
import { TextField } from '@mui/material';

const styles = theme => ({
  textField: {},
  labelRoot: {},
  inputLabelOutlined: {},
  inputLabelShrink: {},
  outlinedRoot: {
    '& $focused $notchedOutline': {
      color: 'black',
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
    },
  },
  notchedOutline: {},
  focused: {},
});

/*
 * TextInput
 * Required Props:
 *  id (string)
 *  value (string)
 *  margin (enum)
 */
class TextInput extends Component {
  static margins = {
    none: 'none',
    dense: 'dense',
    normal: 'normal',
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    margin: PropTypes.oneOf(Object.keys(this.margins)),
  };

  static defaultProps = {
    margin: 'none',
    required: false,
    label: '',
    onChange: () => {},
    onBlur: () => {},
    error: null,
    value: '',
    variant: 'outlined',
  };

  render() {
    const {
      classes,
      onChange,
      onBlur,
      multiline,
      rows,
      autoSize,
      autoFocus,
      value,
      id,
      label,
      error,
      required = false,
      margin,
      className,
      inputLabelOutlined,
      inputLabelShrink,
      shrink,
      type,
      dataTestId,
      variant,
    } = this.props;

    const autoRows = autoSize ? {} : { rows: rows ? rows : 1 };

    return (
      <TextField
        variant={variant}
        margin={margin}
        required={required}
        type={type || 'text'}
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        multiline={multiline}
        {...autoRows}
        autoFocus={autoFocus}
        fullWidth
        inputProps={{ 'data-test-id': dataTestId }}
        InputProps={{
          className: clsx(classes.textField, className),
          classes: {
            root: classes.outlinedRoot,
            focused: classes.focused,
          },
        }}
        InputLabelProps={{
          shrink: shrink,
          classes: {
            shrink: clsx(classes.inputLabelShrink, inputLabelShrink),
            outlined: clsx(classes.inputLabelOutlined, inputLabelOutlined),
          },
        }}
        error={!!(error && error[id])}
        helperText={error && error[id]}
        data-test-id={this.props['data-test-id']}
      />
    );
  }
}

export default withStyles(styles)(TextInput);

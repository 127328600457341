import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // for offline
      networkMode: 'always',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      //   cacheTime: process.env.NODE_ENV === 'development' ? 0 : 30000, // 5min default.
      //   staleTime: process.env.NODE_ENV === 'development' ? 0 : Number.Infinity, // 0min default.
    },
  },
});

import React from 'react';
import { Link } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import withStyles from '@mui/styles/withStyles';

import { ReactComponent as ItrvlLogo } from '../assets/itrvl_logo--white.svg';
import { ReactComponent as BrokenVehicle } from '../assets/brokenVehicle.svg';

const styles = {
  main: {
    color: 'white',
    backgroundColor: 'black',
    width: '100vw',
    height: '100vh',
  },
  header: {
    height: '69px',
  },
  body: {
    backgroundColor: '#639fc3',
    borderRadius: 5,
    width: '80%',
    margin: '25% auto',
    padding: 10,
    '& > div': {
      margin: '10px auto',
      textAlign: 'center',
    },
  },
  p1: {
    fontSize: 48,
  },
  p2: {
    fontSize: 24,
  },
  logo: {
    margin: '15px',
    height: '100px',
  },
  button: {
    marginLeft: 10,
  },
};

const Error = ({ classes, withSentry, eventId }) => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.header}>
          <ItrvlLogo className={classes.logo} alt="itrvl Logo" />
        </div>
        <div className={classes.body}>
          <div>
            <BrokenVehicle alt="Broken Vehicle" />
          </div>
          <div className={classes.p1}>Oops! Something went wrong.</div>
          <div className={classes.p2}>
            You can try refreshing the page, go back, or <Link to="/">go home</Link>.
          </div>
          {withSentry && (
            <div className={classes.p2}>
              Please also{' '}
              <button className={classes.button} onClick={() => Sentry.showReportDialog({ eventId })}>
                Report feedback
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(Error);

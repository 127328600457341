// Syntax in this file is a bit weird to shoe horn into both api and frontend
export * from './allocations';
import money from './dinero';
import reprice, {
  changeItineraryDeposit as doChangeItineraryDeposit,
  changeItinerarySell as doChangeItinerarySell,
  changeCurrency as doChangeCurrency,
  DEFAULT_ITRVL_FEE_CREDIT,
  getRSPAmount,
  getRspInTripCurrency,
  determineCustomPriceAdjustment,
  findProblems as doFindProblems,
} from './reprice';
import { getPaymentDiscount as gpd, getWireDiscount as gwd, getItineraryCurrencies as doGetItineraryCurrencies, toDinero } from './util';
import { rateLimits as rateLimitsImport } from './rateLimits';

export const Dinero = money;
export const repriceItinerary = reprice;
export const changeCurrency = doChangeCurrency;
export const getAsDinero = toDinero;
export const DEFAULT_FEE_CREDIT = DEFAULT_ITRVL_FEE_CREDIT;
export const rateLimits = rateLimitsImport;
export const getPaymentDiscount = gpd;
export const getWireDiscount = gwd;
export const changeItinerarySell = doChangeItinerarySell;
export const changeItineraryDeposit = doChangeItineraryDeposit;
export const findProblems = doFindProblems;
export const getItineraryCurrencies = doGetItineraryCurrencies;

export { getRSPAmount, getRspInTripCurrency, determineCustomPriceAdjustment };

export const fromFormat = (value, currency = 'USD') => {
  const unit = value.replace(/[^\d-.]/g, '');
  try {
    return Dinero({ unit, currency });
  } catch {
    return Dinero({ unit: 0, currency });
  }
};

export const PAYMENT_MODEL = {
  RACK: 'rack',
};

export default {
  Dinero,
  repriceItinerary,
  changeItinerarySell,
  changeItineraryDeposit,
  getItineraryCurrencies,
  getPaymentDiscount,
  getWireDiscount,
  changeCurrency,
  getAsDinero,
  DEFAULT_FEE_CREDIT,
  rateLimits,
  fromFormat,
};

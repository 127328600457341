import React, { Component } from 'react';
import AsyncButton from './Buttons/AsyncButton';
import PasswordField from 'material-ui-password-field';

import withUser from 'common/hocs/withUser';

import { Card, CardContent, CardHeader } from '@mui/material';

export class Activate extends Component {
  state = {
    loading: true,
    error: false,
    submitting: false,
    success: false,
  };

  async componentDidMount() {
    const verificationToken = this.props.match.params.token;
    const { data } = await this.props.onActivate(verificationToken);
    if (!data.valid) {
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  }

  savePassword = async () => {
    this.setState({ submitting: true });
    try {
      const response = await this.props.onSetClientPassword(this.props.match.params.token, this.state.password);
      if (response.status === 200 || response.status === 204) {
        this.setState({ submitting: false, success: true });
      }
    } catch {
      this.setState({ submitting: false, error: true });
    }
  };

  render() {
    const { loading, error, submitting, success } = this.state;
    const details = loading ? (
      'Loading'
    ) : error ? (
      <p>This is an invalid registration token. Please try again.</p>
    ) : (
      <div>
        {success ? (
          <div>
            Your password has been set, you may now <a href={`/${this.props.user.app}/login`}>Log in</a>.
          </div>
        ) : (
          <React.Fragment>
            <div>You must enter a password to activate your account</div>
            <PasswordField onChange={e => this.setState({ password: e.target.value })} />
            <AsyncButton onClick={this.savePassword} loading={submitting} />
          </React.Fragment>
        )}
      </div>
    );

    return (
      <Card>
        <CardHeader title="Activate your account" />
        <CardContent>{details}</CardContent>
      </Card>
    );
  }
}

export default withUser(Activate);

export const UTM_COOKIE = 'GA_WILDERNESS';
export const UTM_GA = '_ga';

export const UTM = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_TERM: 'utm_term',
  CID: 'cid',
};

export const getItrvlCid = _ga => _ga?.replace(/^GA1.\d./, ''); // GA1.n.1234.4321 => 1234.4321

const ChevronLeft = () => (
  <svg viewBox="0 0 7 13" fill="none">
    <path
      d="M5.75781 12.4648L0.753906 7.24219C0.617188 7.07812 0.5625 6.91406 0.5625 6.75C0.5625 6.61328 0.617188 6.44922 0.726562 6.3125L5.73047 1.08984C5.97656 0.816406 6.41406 0.816406 6.66016 1.0625C6.93359 1.30859 6.93359 1.71875 6.6875 1.99219L2.12109 6.75L6.71484 11.5625C6.96094 11.8086 6.96094 12.2461 6.6875 12.4922C6.44141 12.7383 6.00391 12.7383 5.75781 12.4648Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronLeft;

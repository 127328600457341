import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReactDatePicker from 'react-datepicker';
import { getYear, getMonth, subMonths, addMonths } from 'date-fns';
import { range } from 'lodash';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ChevronLeftIcon from './icons/ChevronLeft';
import ChevronRightIcon from './icons/ChevronRight';

import 'react-datepicker/dist/react-datepicker.css';

// @todo: maybe move global css to scss

const useStyles = makeStyles({
  root: {
    '& .month-picker': {
      width: 140,
    },
    '& .year-picker': {
      width: 100,
    },
  },
});

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const selectStyles = {
  fontSize: 14,
  '& .MuiSelect-select': {
    // meh
    paddingRight: '0px !important',
    textAlign: 'left',
  },
};

const DatePickerHeader = props => {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    header,
    onDecreaseMonth,
    onIncreaseMonth,
    rangeStartYear,
    rangeEndYear,
  } = props;

  const years = useMemo(() => {
    const now = getYear(new Date()) - 5;
    return range(rangeStartYear ?? now, rangeEndYear ?? now + 15, 1);
  }, [rangeStartYear, rangeEndYear]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 20,
      }}
    >
      <button
        className="btn"
        onClick={e => {
          decreaseMonth(e);
          onDecreaseMonth && onDecreaseMonth(subMonths(date, 1));
        }}
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeftIcon />
      </button>

      {header ? (
        header(date)
      ) : (
        <>
          <Select
            size="small"
            variant="outlined"
            className="month-picker"
            value={months[getMonth(date)]}
            onChange={event => changeMonth(months.indexOf(event.target.value))}
            sx={selectStyles}
          >
            {months.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>

          <Select
            size="small"
            className="year-picker"
            variant="outlined"
            value={getYear(date)}
            onChange={event => changeYear(event.target.value)}
            sx={selectStyles}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                  overflowY: 'auto',
                },
              },
            }}
          >
            {years.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      <button
        className="btn"
        onClick={e => {
          increaseMonth(e);
          onIncreaseMonth && onIncreaseMonth(addMonths(date, 1));
        }}
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};

const DatePicker = props => {
  const classes = useStyles();
  const { header, ...rest } = props;
  // @todo: memo?
  const Header = props => <DatePickerHeader header={header} {...props} {...rest} />;
  return (
    <div className={classes.root}>
      <ReactDatePicker calendarClassName="itinerary-builder-calendar" renderCustomHeader={Header} inline fixedHeight {...rest} />
    </div>
  );
};

export default DatePicker;

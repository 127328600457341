import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { noop } from 'common';

import { useApi } from 'common/hooks/api';

import InputRhf from '../FormElements/InputRhf';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

yup.addMethod(yup.string, 'sequence', function(funcList) {
  return this.test(async (value, context) => {
    try {
      for (const func of funcList) {
        await func().validate(value);
      }
    } catch ({ message }) {
      return context.createError({ message });
    }
    return true;
  });
});

const NewClientForm = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const Api = useApi();
  const { onSubmit = noop, agencyId } = props;

  const schema = useMemo(
    () =>
      yup
        .object({
          firstName: yup.string().required('First name is required'),
          lastName: yup.string().required('Last name is required'),
          email: yup.string().sequence([
            () => yup.string().email('Email must be valid'),
            () =>
              yup.string().test('checkDuplicateEmail', 'Email already exists', async function(value) {
                if (value) {
                  const exists = await Api.checkClientEmail(value, agencyId);
                  return !exists;
                }
                return true;
              }),
          ]),
        })
        .required(),
    [Api, agencyId],
  );

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const validateForm = handleSubmit(onSubmit);

  React.useImperativeHandle(ref, () => ({
    submit: () => {
      return validateForm();
    },
  }));

  return (
    <div className={classes.root}>
      <InputRhf
        control={control}
        id="title"
        name="title"
        label="Title"
        inputProps={{ maxLength: 4 }}
        data-test-id="new-client-form-title-input"
        autoFocus
      />
      <InputRhf
        control={control}
        id="firstName"
        name="firstName"
        label="First Name"
        required
        data-test-id="new-client-form-firstName-input"
      />
      <InputRhf control={control} id="lastName" name="lastName" label="Last Name" required data-test-id="new-client-form-lastName-input" />
      <InputRhf control={control} id="email" name="email" label="Email" required data-test-id="new-client-form-email-input" />
      <InputRhf control={control} id="phone" name="phone" label="Phone" data-test-id="new-client-form-phone-input" />
      <InputRhf control={control} id="address" name="address" label="Address" data-test-id="new-client-form-address-input" />
    </div>
  );
});

export default NewClientForm;

import { getItineraryDueDate } from './itinerary';
import moment from 'moment';

export const PAYMENT_TYPE = {
  BALANCE: 'balance',
  DEPOSIT: 'deposit',
  SELL: 'sell',
};

export const PAYMENT_STATUS = {
  ERROR: 'Error',
  AUTH: 'Authorized',
  PAID: 'Paid',
  PENDING: 'Pending',
};

export const PAYMENT_METHOD = {
  ACH: 'ach',
  CREDIT: 'credit',
  WIRE: 'wire',
};

export const ACH_DESCRIPTOR_LENGTH = 22;
export const CC_DESCRIPTOR_LENGTH = 15;

export const paymentMethodDisplay = paymentMethod => {
  switch (paymentMethod) {
    case PAYMENT_METHOD.ACH:
      return 'ACH';
    case PAYMENT_METHOD.CREDIT:
      return 'Credit Card';
    case PAYMENT_METHOD.WIRE:
      return 'Wire Transfer';
    default:
      return '';
  }
};

// Get the due date for an itinerary and paymentType
export const getPaymentDueDate = (itinerary, paymentType) =>
  paymentType === PAYMENT_TYPE.DEPOSIT
    ? moment()
        .utc()
        .startOf('day')
    : getItineraryDueDate(itinerary);

export const sanitizeDescriptor = (descriptor = '', maxLength) => {
  const sanitized = descriptor.replace(/['<>]/g, '').slice(0, maxLength);
  return sanitized || undefined;
};

export const paymentDefaultName = (itinerary, payment) => payment.name ?? [itinerary.name, payment.paymentType].filter(Boolean).join(' ');

const Minus = () => (
  <svg viewBox="0 0 13 12">
    <path
      d="M12.2,6.2c0,0.4-0.3,0.7-0.7,0.7H7.2H5.8H1.5c-0.4,0-0.7-0.3-0.7-0.6c0-0.4,0.3-0.7,0.7-0.7h4.4h1.3h4.4
      C11.9,5.6,12.2,5.9,12.2,6.2z"
    />
  </svg>
);

export default Minus;

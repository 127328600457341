const Availability = () => (
  <svg viewBox="0 0 21.661 19.215">
    <path
      d="m20.033 6.606-1.048-.075.528-.879c.085-.14-.08-.294-.328-.309a.831.831 0 0 0-.668.249l-.555.865-2.744-.2c-.082.2-.17.4-.266.6l4.728.373c.578.046.933.4.746.754l-.364.7-5.778-.549c-.13.23-.263.457-.4.683l1.065.111L13.8 10.6l-.977-.122c-.193.285-.377.55-.548.792l.972.133-1.514 2.2-3.9-.647 1.567-1.92a46.625 46.625 0 0 1-.731-1.079l-2.575-.323 1.42-1.472h.056q-.187-.328-.36-.657L.75 13.989c-1.03 1.033-1.011 2.079.131 2.343L13 19.13a3.9 3.9 0 0 0 4.183-1.893l4.387-9.15c.355-.741-.362-1.397-1.537-1.481ZM5.416 10.338l3.095.42-1.725 2.023-3.212-.533Zm-3.6 4.989c-.568-.121-.6-.629-.1-1.147l1.061-1.1 3.26.585-1.85 2.168Zm3.47.741L7.1 13.855l3.974.712-1.647 2.386Zm10.47.887a1.99 1.99 0 0 1-2.047.913l-3.009-.644 1.586-2.439 4.21.755Zm1.288-2.473-4.12-.684 1.458-2.241 3.909.531Zm1.7-3.263-3.829-.48 1.1-1.7 3.662.38Z"
      fill="currentColor"
    />
    <path
      d="M10.892.009a4.273 4.273 0 0 0-4.277 4.3c0 2.379 4.307 7.966 4.307 7.966s4.308-5.608 4.3-7.987a4.321 4.321 0 0 0-4.33-4.279Zm.017 6.834a2.513 2.513 0 1 1 2.507-2.519 2.509 2.509 0 0 1-2.507 2.519Z"
      fill="currentColor"
      style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '.017px' }}
    />
  </svg>
);

export default Availability;

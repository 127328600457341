export const INVENTORY_STATUS = {
  DRAFT: 'Draft',
  PROVISIONAL: 'Provisional',
  WAITLISTED: 'Waitlisted',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
  REQUESTED: 'Requested',
  NOT_APPLICABLE: 'Not_applicable',
  PLACEHOLDER: 'Placeholder',
  PSEUDO: 'Pseudo',
};

import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Numeric from './Numeric';

import PlusIcon from './icons/Plus';
import MinusIcon from './icons/Minus';

import { noop } from 'common';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #EFEFEF',
    padding: '0 15px',
    height: 40,
    gap: 8,
    backgroundColor: '#fff',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  cover: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  input: {
    height: '100%',
    padding: '10px 5px',
    border: 'none',
    outline: 'none',
    flex: 1,
  },
  glyph: {
    width: 14,
    height: 'auto',
    flexShrink: 0,
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    '& > div': {
      width: 14,
      height: 14,
      cursor: 'pointer',
    },
  },
});

const TravelerInput = props => {
  const classes = useStyles();
  const {
    label,
    glyph,
    value,
    maximumValue = Number.MAX_SAFE_INTEGER,
    minimumValue = 0,
    increment = noop,
    decrement = noop,
    onChange = noop,
    input = true,
    disabled = false,
    ariaLabel,
  } = props;

  const clamp = useCallback(value => (value > maximumValue ? maximumValue : value < minimumValue ? minimumValue : value), [
    maximumValue,
    minimumValue,
  ]);

  return (
    <div className={clsx(classes.root, disabled && classes.disabled)} role="group" aria-label={ariaLabel}>
      {disabled && <div className={classes.cover} />}
      {glyph && <div className={classes.glyph}>{glyph}</div>}
      {input ? (
        <Numeric
          className={classes.input}
          placeholder={label}
          value={value}
          onChange={onChange}
          decimalPlaces={0}
          digitGroupSeparator={''}
          // @global and check?
          maximumValue={maximumValue}
          minimumValue={minimumValue}
          aria-label={`modify ${ariaLabel}`}
          data-test-id={`modify-${ariaLabel}`}
        />
      ) : (
        <div className={classes.input}>{label}</div>
      )}

      <div className={classes.controls}>
        <div
          role="button"
          aria-label={'decrement ' + ariaLabel}
          onMouseDown={e => e.preventDefault()}
          onClick={() => decrement(clamp(value - 1))}
        >
          <MinusIcon />
        </div>
        <div
          role="button"
          aria-label={'increment ' + ariaLabel}
          onMouseDown={e => e.preventDefault()}
          onClick={() => increment(clamp(value + 1))}
        >
          <PlusIcon />
        </div>
      </div>
    </div>
  );
};

export default TravelerInput;

export default function getAgencyEndpoints(build) {
  return {
    getAgent: build.query({
      query: id => `/Agents/${id}`,
      providesTags: ['Agent'],
    }),
    patchAgent: build.mutation({
      query: updateObj => ({
        url: `/Agents/${updateObj.id}`,
        method: 'PATCH',
        body: updateObj,
      }),
      invalidatesTags: ['Agent'],
    }),
    getShareVideoLink: build.mutation({
      query: itineraryId => ({
        url: `/Agents/createVideoLink/${itineraryId}`,
        method: 'POST',
      }),
    }),
    logout: build.mutation({
      query: () => ({
        url: '/Agents/logout',
        method: 'POST',
      }),
    }),
  };
}

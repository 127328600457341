import { Controller } from 'react-hook-form';
import { FormHelperText, FormLabel, FormControlLabel, Checkbox, FormGroup, FormControl } from '@mui/material';
import clsx from 'clsx';

import sharedStyles from 'styles/shared';

export default function CheckboxGroupRhf({ error, options, label, helperText, control, labelBold = true }) {
  const s = sharedStyles();

  return (
    <FormControl component="fieldset">
      <FormLabel className={clsx(labelBold && s.bold)} component="legend">
        {label}
      </FormLabel>
      {helperText && <FormHelperText className={s.mtXs}>{helperText}</FormHelperText>}
      <FormGroup row className={clsx(s.mtXs, 'formGroup')}>
        {options.map(option => (
          // <CheckboxRhf /> inlined.
          <Controller
            key={option.value}
            name={option.value}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControlLabel
                  key={option.value + value}
                  control={<Checkbox value={value} checked={value} onChange={onChange} color="secondary" />}
                  label={option.label}
                />
              );
            }}
          />
        ))}
      </FormGroup>

      {error && <FormHelperText>You can display an error</FormHelperText>}
    </FormControl>
  );
}

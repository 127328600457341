import logger from 'itrvl-logger';
import { BrowserRouter as Router } from 'react-router-dom';

const log = logger(__filename);

export class DebugRouter extends Router {
  constructor(props) {
    super(props);
    log.debug('HISTORY: ', JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      log.debug(`URL: ${location.pathname}${location.search}${location.hash}`);
      log.debug(`ACTION: ${action}`, JSON.stringify(this.history, null, 2));
    });
  }
}

export default DebugRouter;

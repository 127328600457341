import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  pZero: { padding: 0 },
  pXxs: { padding: theme.spacing(0.5) },
  pXs: { padding: theme.spacing(1) },
  pSm: { padding: theme.spacing(1.5) },
  pMd: { padding: theme.spacing(2) },
  pLg: { padding: theme.spacing(3) },
  pXl: { padding: theme.spacing(4) },
  pXxl: { padding: theme.spacing(5) },

  ptXxs: { paddingTop: theme.spacing(0.5) },
  ptXs: { paddingTop: theme.spacing(1) },
  ptSm: { paddingTop: theme.spacing(1.5) },
  ptMd: { paddingTop: theme.spacing(2) },
  ptLg: { paddingTop: theme.spacing(3) },
  ptXl: { paddingTop: theme.spacing(4) },
  ptXxl: { paddingTop: theme.spacing(5) },

  prXxs: { paddingRight: theme.spacing(0.5) },
  prXs: { paddingRight: theme.spacing(1) },
  prSm: { paddingRight: theme.spacing(1.5) },
  prMd: { paddingRight: theme.spacing(2) },
  prLg: { paddingRight: theme.spacing(3) },
  prXl: { paddingRight: theme.spacing(4) },
  prXxl: { paddingRight: theme.spacing(5) },

  pbXxs: { paddingBottom: theme.spacing(0.5) },
  pbXs: { paddingBottom: theme.spacing(1) },
  pbSm: { paddingBottom: theme.spacing(1.5) },
  pbMd: { paddingBottom: theme.spacing(2) },
  pbLg: { paddingBottom: theme.spacing(3) },
  pbXl: { paddingBottom: theme.spacing(4) },
  pbXxl: { paddingBottom: theme.spacing(5) },

  plXxs: { paddingLeft: theme.spacing(0.5) },
  plXs: { paddingLeft: theme.spacing(1) },
  plSm: { paddingLeft: theme.spacing(1.5) },
  plMd: { paddingLeft: theme.spacing(2) },
  plLg: { paddingLeft: theme.spacing(3) },
  plXl: { paddingLeft: theme.spacing(4) },
  plXxl: { paddingLeft: theme.spacing(5) },

  phXxs: { padding: theme.spacing(0, 0.5) },
  phXs: { padding: theme.spacing(0, 1) },
  phSm: { padding: theme.spacing(0, 1.5) },
  phMd: { padding: theme.spacing(0, 2) },
  phLg: { padding: theme.spacing(0, 3) },
  phXl: { padding: theme.spacing(0, 4) },
  phXxl: { padding: theme.spacing(0, 5) },

  pvXxs: { padding: theme.spacing(0.5, 0) },
  pvXs: { padding: theme.spacing(1, 0) },
  pvSm: { padding: theme.spacing(1.5, 0) },
  pvMd: { padding: theme.spacing(2, 0) },
  pvLg: { padding: theme.spacing(3, 0) },
  pvXl: { padding: theme.spacing(4, 0) },
  pvXxl: { padding: theme.spacing(5, 0) },

  mrXxs: { marginRight: theme.spacing(0.5) },
  mrXs: { marginRight: theme.spacing(1) },
  mrSm: { marginRight: theme.spacing(1.5) },
  mrMd: { marginRight: theme.spacing(2) },
  mrLg: { marginRight: theme.spacing(3) },
  mrXl: { marginRight: theme.spacing(4) },
  mrXxl: { marginRight: theme.spacing(5) },

  mlXxs: { marginLeft: theme.spacing(0.5) },
  mlXs: { marginLeft: theme.spacing(1) },
  mlSm: { marginLeft: theme.spacing(1.5) },
  mlMd: { marginLeft: theme.spacing(2) },
  mlLg: { marginLeft: theme.spacing(3) },
  mlXl: { marginLeft: theme.spacing(4) },
  mlXxl: { marginLeft: theme.spacing(5) },

  mtXxs: { marginTop: theme.spacing(0.5) },
  mtXs: { marginTop: theme.spacing(1) },
  mtSm: { marginTop: theme.spacing(1.5) },
  mtMd: { marginTop: theme.spacing(2) },
  mtLg: { marginTop: theme.spacing(3) },
  mtXl: { marginTop: theme.spacing(4) },
  mtXxl: { marginTop: theme.spacing(5) },

  mbXxs: { marginBottom: theme.spacing(0.5) },
  mbXs: { marginBottom: theme.spacing(1) },
  mbSm: { marginBottom: theme.spacing(1.5) },
  mbMd: { marginBottom: theme.spacing(2) },
  mbLg: { marginBottom: theme.spacing(3) },
  mbXl: { marginBottom: theme.spacing(4) },
  mbXxl: { marginBottom: theme.spacing(5) },

  gapXxs: { gap: theme.spacing(0.5) },
  gapXs: { gap: theme.spacing(1) },
  gapSm: { gap: theme.spacing(1.5) },
  gapMd: { gap: theme.spacing(2) },
  gapLg: { gap: theme.spacing(3) },
  gapXl: { gap: theme.spacing(4) },
  gapXxl: { gap: theme.spacing(5) },

  flex: { display: 'flex' },
  flex1: { flex: 1 },
  flexRow: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  flexRowSpaced: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  flexCol: { display: 'flex', flexDirection: 'column' },
  flexRowEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  justifyStart: { justifyContent: 'flex-start' },
  justifyCenter: { justifyContent: 'center' },
  justifyEnd: { justifyContent: 'flex-end' },

  itemsStart: { alignItems: 'flex-start' },
  itemsCenter: { alignItems: 'center' },
  itemsEnd: { alignItems: 'flex-end' },
  shrink0: { flexShrink: 0 },
  selfStart: { alignSelf: 'flex-start' },
  selfEnd: { alignSelf: 'flex-end' },
  centered: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  fullWidth: { width: '100%' },
  fullHeight: { height: '100%' },
  bold: { fontWeight: 'bold' },
  fontMedium: { fontWeight: 500 },
  block: { display: 'block' },
  relative: { position: 'relative' },
  absolute: { position: 'absolute' },
  overlay: { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 },
  noOverflow: { overflow: 'hidden' },
  clickable: { cursor: 'pointer' },
  hoverUnderline: { '&:hover': { textDecoration: 'underline' } },
  textCenter: { textAlign: 'center' },
  capitalize: { textTransform: 'capitalize' },
  textRight: { textAlign: 'right' },
  textXs: { fontSize: '0.75rem' },
  textXsImportant: { fontSize: '0.75rem !important' },
  textSm: { fontSize: '0.875rem' },
  uppercase: { textTransform: 'uppercase' },
  grabbable: { cursor: 'move' },
  hr: {
    border: 'none',
    borderTop: `1px solid ${theme.palette?.stoneGrey?.black20}66`,
  },
  hrSpace: {
    border: 'none',
    borderTop: `1px solid ${theme.palette?.stoneGrey?.black20}66`,
    marginTop: 21,
    marginBottom: 42,
  },
  cover: {
    position: 'absolute',
    inset: 0,
  },
  whitespaceNowrap: {
    whiteSpace: 'nowrap',
  },
  null: {}, // Use for dev compile time ease.

  // colors
  backgroundSuccess: { backgroundColor: theme.palette?.success?.main },
  black: { color: theme.palette?.stoneGrey?.black80 },
  smokyGrey: { color: 'rgba(0, 0, 0, 0.54)' },
  bgWhite: { backgroundColor: theme.palette?.stoneGrey?.white80 },
}));

export default useStyles;

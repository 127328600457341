/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useMemo } from 'react';
import { values, isArray, pick } from 'lodash';

const noop = () => ({});
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const connectContext = (context, mapState = noop, mapDispatchers = noop) => WrapperComponent => {
  const ConnectedComponent = forwardRef(function ContextConnectedComponent(props, ref) {
    const targetContext = useContext(context);
    const { ...statePointers } = isArray(mapState) ? pick(targetContext, mapState) : mapState(targetContext);
    const { ...dispatchPointers } = mapDispatchers(targetContext);
    return useMemo(() => <WrapperComponent ref={ref} {...props} {...statePointers} {...dispatchPointers} />, [
      ...values(statePointers),
      ...values(props),
      ...values(dispatchPointers),
    ]);
  });
  ConnectedComponent.displayName = `ConnectContext(${getDisplayName(WrapperComponent)})`;
  return ConnectedComponent;
};

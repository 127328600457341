const { reduce, set, get, cloneDeep, find } = require('lodash');

// Availability Rules:
// Supplier availability only provides: # doubles, # twins, # family rooms
// Doubles cannot always be reconfigured as twins
// Twins can always be reconfigured as doubles
//
// Single room requests can be fulfilled by double room or twin room
// Special exception use cases we don't cover:
//   Single can also potentially be covered by family
//   Twin or double can also potentially be covered by family
// @TODO: verify against window and live itineraries that availability is working for special camps with doubles that
// be reconfigured as twins

const checkAvailabilityForRooms = (supplierCode, availabilities, rooms, _roomTypes) => {
  // @TODO: special case for wilderness properties that may have twin/double/family capable rooms + suites (JAO001)

  // Wilderness special case
  const roomTypes = cloneDeep(get(_roomTypes, supplierCode));
  if (!get(roomTypes, '_Double.default.optionKey')) {
    set(roomTypes, '_Double.default', get(roomTypes, 'Twin.default'));
    set(roomTypes, '_Double.options', get(roomTypes, 'Twin.options'));
  }

  const roomAvails = cloneDeep(availabilities);
  let availabilityForAllRooms = reduce(
    rooms,
    (acc, room) => {
      acc.prevAvailability = availabilities;

      let systemTypeForRoom = 'Twin';
      switch (room.roomType) {
        case 'dr':
          // Doubles are okay with twins
          systemTypeForRoom = '_Double';
          break;
        case 'sr':
          systemTypeForRoom = '_Double';
          break;
        case 'tr':
          // Twins are not okay with doubles
          systemTypeForRoom = 'Twin';
          break;
        case 'fr':
          systemTypeForRoom = 'Family';
          break;
        default:
          systemTypeForRoom = 'Twin';
          break;
      }

      let roomTypeIdForRoom = get(roomTypes, `${systemTypeForRoom}.default.roomTypeId`);

      if (room.roomTypeId) {
        let selectedRoom = get(room.roomTypeId, supplierCode);
        let roomOptions = get(roomTypes, `${systemTypeForRoom}.options`);
        let roomOption = find(roomOptions, { roomTypeId: String(selectedRoom) });

        if (roomOption) {
          roomTypeIdForRoom = roomOption.roomTypeId;
        }
      }
      // console.log(`checkAvailabilityForRoom ${supplierCode} ${JSON.stringify(room.roomTypeId)} ${roomTypeIdForRoom}`);
      // console.log(`roomAvails: `,roomAvails);
      if (!roomTypeIdForRoom) {
        acc.configNotAvailable = true;
        return acc;
      }

      if (get(roomAvails, roomTypeIdForRoom) === undefined) {
        acc.thisRoomTypeNotFound = true;
      }
      if (get(roomAvails, roomTypeIdForRoom, 0) === 0) {
        acc.configNotAvailable = true;
        return acc;
      }

      let currentAvailForRoomTypeId = Number(get(roomAvails, roomTypeIdForRoom, 0));
      currentAvailForRoomTypeId--;
      set(roomAvails, roomTypeIdForRoom, currentAvailForRoomTypeId);

      acc.currentAvailability = roomAvails;
      return acc;
    },
    { configNotAvailable: false, thisRoomTypeNotFound: false, prevAvailability: {}, currentAvailability: {} },
  );

  return availabilityForAllRooms;
};

const checkAvailability = (rooms, supplierAvailability) => {
  const roomSum = reduce(
    rooms,
    (res, room) => {
      res[room.roomType]++;
      if (room.roomType === 'sr') {
        res.doubleOrTwin += 1;
      }
      if (room.roomType === 'dr') {
        res.doubleOrTwin += 1;
      }
      if (room.roomType === 'tr') {
        res.twinOnly += 1;
      }
      if (room.roomType === 'fr') {
        res.family += 1;
      }

      return res;
    },
    { sr: 0, tr: 0, dr: 0, fr: 0, doubleOrTwin: 0, twinOnly: 0, family: 0 },
  );

  let available = true;

  if (!supplierAvailability) {
    available = false;
  } else {
    if (roomSum.doubleOrTwin > supplierAvailability['Twin'].avail + supplierAvailability['Double'].avail) available = false;
    if (roomSum.twinOnly > supplierAvailability['Twin'].avail) available = false;
    if (roomSum.sr + roomSum.dr + roomSum.tr > supplierAvailability['Twin'].avail + supplierAvailability['Double'].avail) available = false;

    if (roomSum.family > supplierAvailability['Family'].avail) available = false;
  }

  return { roomSum, available };
};

// Costing for estimates
//
// Only values used in per person costing for WS properties:
// Returns { single: x, share: y, children: z }
//
// Single is related to single supplement cost (one person in a double)
// Share is a rate when 2 adults or an adult and a child are sharing a room
// Special exception for WS properties when 3 adults are sharing a family room => 2 shares + 1 single
//
// Children may need to be treated differently for costing in the future due to individual camp rules.
// i.e. Some camps may treat children as adults for costing if they are over 16, but itineraries treat anyone under 18
// as a child.

const getCosting = rooms => {
  const costing = reduce(
    rooms,
    (res, room) => {
      switch (room.roomType) {
        case 'sr':
          res.single++;
          res.pax++;
          break;
        case 'dr':
        case 'tr':
          res.share += room.adults;
          res.children += room.children.length;
          res.pax += room.adults + room.children.length;
          break;
        case 'fr':
          res.share += room.adults;
          res.children += room.children.length;
          if (room.adults === 3 && room.children.length === 0) {
            // Special rule for Wilderness
            res.share--;
            res.single++;
          }
          res.pax += room.adults + room.children.length;
          break;
        case 'gr':
          res.pax++;
          res.single++;
          break;
        default:
          // unknown
          break;
      }
      return res;
    },
    { single: 0, share: 0, children: 0, pax: 0 },
  );

  return costing;
};

const calculateItineraryPeople = rooms => {
  const itineraryPeople = reduce(
    rooms,
    (res, room) => {
      if (room.adults) {
        res.adults += room.adults;
        res.pax += room.adults;
      }
      if (room.children.length) {
        res.children += room.children.length;
        res.pax += room.children.length;
        res.childrenAges.push(...room.children);
      }
      return res;
    },
    { adults: 0, children: 0, pax: 0, childrenAges: [] },
  );
  return itineraryPeople;
};

const AVAILABILITY_STALE_AFTER_NUM_HOURS = 48;
const AVAILABILITY_VIA_3P_STALE_AFTER_NUM_MS = 1000 * 60 * 60 * 4; // 4 hours

module.exports = {
  checkAvailability,
  checkAvailabilityForRooms,
  getCosting,
  calculateItineraryPeople,
  AVAILABILITY_STALE_AFTER_NUM_HOURS,
  AVAILABILITY_VIA_3P_STALE_AFTER_NUM_MS,
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Tooltip from 'components/v2/Tooltip';
import { Button } from '@mui/material';

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { useBuilderStore } from './store';

const useStyles = makeStyles({
  root: {
    height: 37,
    width: 37,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    minWidth: 'auto',
    '& svg': {
      width: 22,
    },
  },
});

const FillRoomButton = () => {
  const classes = useStyles();
  const reset = useBuilderStore(state => state.actions.reset);
  return (
    <Tooltip title="Reset Itinerary" placement="bottom">
      <Button variant="outlined" className={classes.root} onClick={() => reset(true)}>
        <SettingsBackupRestoreIcon />
      </Button>
    </Tooltip>
  );
};

export default FillRoomButton;

import root from 'common/utils/window-or-global';

const pressedKeys = {};

const oldKeyUp = root.onkeyup;
const oldKeyDown = root.onkeydown;
root.onkeyup = e => {
  pressedKeys[e.keyCode] = false;
  if (oldKeyUp) {
    oldKeyUp(e);
  }
};
root.onkeydown = e => {
  pressedKeys[e.keyCode] = true;
  if (oldKeyDown) {
    oldKeyDown(e);
  }
};

const isKeyDown = key => {
  const ret = pressedKeys.hasOwnProperty(key) && pressedKeys[key];
  return ret;
};

export default isKeyDown;

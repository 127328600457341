import React from 'react';
import DateSelector from 'components/v2/DateSelector';

import { useBuilderStore } from './store';

const ItineraryBuilderDateSelector = () => {
  const open = useBuilderStore(state => state.ui.date.open);
  const date = useBuilderStore(state => state.data.date);
  const setDate = useBuilderStore(state => state.actions.date.setDate);
  const closeDatePicker = useBuilderStore(state => state.actions.date.closeDatePicker);
  const toggleDatePicker = useBuilderStore(state => state.actions.date.toggleDatePicker);
  return <DateSelector open={open} date={date} setDate={setDate} closeDatePicker={closeDatePicker} toggleDatePicker={toggleDatePicker} />;
};

export default ItineraryBuilderDateSelector;

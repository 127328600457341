import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Chip } from '@mui/material';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(90deg, #555555, #777777)',
    color: '#fff',
    height: 25,
    marginLeft: 'auto',
  },
});

const LegacyChip = () => {
  const classes = useStyles();
  return <Chip className={classes.root} label="legacy" />;
};

export default LegacyChip;

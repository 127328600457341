import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { size } from 'lodash';
import Slider from 'react-slick';

import Slide from '@mui/material/Slide';
import GetQuoteButton from './GetQuoteButton';
import SliderSegment from './SliderSegment';
import AddClientButton from './AddClientButton';

// import PresentationIcon from './icons/Presentation';
// import ElephantIcon from './icons/Elephant';
import ChevronDownIcon from './icons/ChevronDown';
import ChevronLeftIcon from './icons/ChevronLeft';
import ChevronRightIcon from './icons/ChevronRight';

import { useBuilderStore } from './store';

import 'slick-carousel/slick/slick.css';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: theme.shadows[3],
      zIndex: 4,
      '& header': {
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #F1EFEA',
        '& section': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
      '& .slick-list': {
        overflow: 'unset',
      },
      '& .slick-arrow': {
        position: 'absolute',
        height: '100%',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: '#fff',
        '&.slick-disabled': {
          opacity: 0,
        },
      },
      '& .slick-prev': {
        left: -20,
      },
      '& .slick-next': {
        right: -20,
      },
    },
    down: {
      // the material-ui <Slide /> component enforces this directly on the
      // element, maybe use a wrapper?
      transform: 'translateY(97px) !important',
    },
    expand: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
      transition: theme.transitions.create(['scale']),
      transformOrigin: 'center',
    },
    flip: {
      transform: 'scaleY(-1)',
    },
    left: {
      paddingLeft: 27,
    },
    new: {
      display: 'flex',
      gap: 8,
      paddingRight: 20,
      position: 'relative',
      borderRight: '1px solid #F1EFEA',
      cursor: 'pointer',
      transition: theme.transitions.create(['color']),
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      '& svg': {
        width: 14,
        height: 'auto',
      },
    },
    switch: {
      padding: '0 17px',
    },
    btn: {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      textTransform: 'uppercase',
      height: '100%',
      padding: '0 17px',
      fontWeight: 400,
      lineHeight: 1,
      fontSize: 12,
      transition: theme.transitions.create['color'],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      '& svg': {
        width: 13,
        height: 13,
      },
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      display: 'flex',
      borderTop: `2px solid ${theme.palette.secondary.main}`,
    },

    // cards
    media: {
      borderRadius: 2,
      width: 80,
      height: 64,
    },

    segments: {
      height: 96,
      padding: '10px 20px 10px 90px',
    },
  }),
  {
    name: __filename,
  },
);

const useArrowStyles = makeStyles(theme => ({
  arrow: {
    width: 20,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& path': {
      transition: theme.transitions.create(['fill']),
    },
    '&:hover': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
    // @todo: hover states
    '& svg': {
      width: 10,
    },
  },
}));

function Arrow(props) {
  const { direction, onClick, style, className /*, ...rest*/ } = props;
  const classes = useArrowStyles();
  return (
    <div style={style} className={className} onClick={onClick}>
      <div className={classes.arrow}>{direction === 'prev' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</div>
    </div>
  );
}

const ItineraryDrawer = () => {
  const classes = useStyles();
  const [down, setDown] = useState(false);
  const segmentIds = useBuilderStore(state => state.data.segmentIds);
  const hasSegments = size(segmentIds) > 0;
  return (
    <Slide in={hasSegments} direction="up">
      <div className={clsx(classes.root, down && classes.down)} data-test-id="itinerary-drawer">
        <header>
          <section className={classes.left}>
            <div className={classes.new} onClick={() => setDown(down => !down)} data-test-id={down ? 'down' : 'up'}>
              <div className={clsx(classes.expand, down && classes.flip)}>
                <ChevronDownIcon />
              </div>
              New Itinerary
            </div>
            <AddClientButton />
          </section>
          <section>
            <GetQuoteButton />
          </section>
          {/* @todo: this will be implemented later, so let's keep it around for when we switch back! */}
          {/* <section>
            <div className={classes.btn}>Switch View</div>
            <div className={clsx(classes.btn, classes.active)}>
              <ElephantIcon />
              <span>
                Itinerary
                <br /> Builder
              </span>
            </div>
            <div className={classes.btn}>
              <PresentationIcon />
              <span>
                Presentation
                <br /> Mode
              </span>
            </div>
          </section> */}
        </header>
        <section className={classes.segments} /*ref={parent}*/>
          <Slider
            {...{
              dots: false,
              infinite: false,
              centerMode: false,
              slidesToScroll: 1,
              variableWidth: true,
              swipeToSlide: true,
              nextArrow: <Arrow />,
              prevArrow: <Arrow direction="prev" />,
            }}
          >
            {segmentIds.map(id => (
              <div key={id}>
                <SliderSegment key={id} id={id} />
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </Slide>
  );
};

export default React.memo(ItineraryDrawer);

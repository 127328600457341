import NiceModal from '@ebay/nice-modal-react';
import BaseModal from 'components/v2/Modals/BaseModal';
import { useBuilderStore } from './store';
import SelectRoomsContent from './SelectRoomsContent';
import { useModal } from 'hooks/modal';

export default NiceModal.create(function SelectRoomModal({ id }) {
  const segment = useBuilderStore(state => state.data.segmentsById?.[id]);
  const { hideAllModals } = useModal();
  return (
    <BaseModal
      title={`Select Rooms at ${segment.campName}`}
      subtext="Select rooms and assign travelers to add this accommodation to your itinerary"
      confirmText="Done"
      denyText="Back"
      maxWidth="xl"
      fullWidth
      onConfirm={() => hideAllModals()}
    >
      <SelectRoomsContent id={id} />
    </BaseModal>
  );
});

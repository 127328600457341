import React from 'react';

import FeatureFlags from '../FeatureFlags';

import { FeatureContext } from 'common/context/FeatureContext';

const FeatureProvider = ({ children }) => {
  return <FeatureContext.Provider value={{ ...FeatureFlags }}>{children}</FeatureContext.Provider>;
};

export default FeatureProvider;

const ChevronRight = () => (
  <svg viewBox="0 0 7 13" fill="none">
    <path
      d="M1.21484 1.0625L6.21875 6.28516C6.32812 6.42188 6.41016 6.58594 6.41016 6.75C6.41016 6.91406 6.32812 7.07812 6.21875 7.1875L1.21484 12.4102C0.96875 12.6836 0.53125 12.6836 0.285156 12.4375C0.0117188 12.1914 0.0117188 11.7812 0.257812 11.5078L4.85156 6.72266L0.257812 1.96484C0.0117188 1.71875 0.0117188 1.28125 0.285156 1.03516C0.53125 0.789062 0.96875 0.789062 1.21484 1.0625Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRight;

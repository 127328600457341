import React from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import BaseButton from '@mui/material/Button';

const styles = theme => ({
  root: {},
  label: {},
  disabled: {},
  bigButton: {
    textTransform: 'uppercase',
    padding: theme.spacing(1.5),
    width: '100%',
  },
});

export const Button = withStyles(styles)(function Button(props) {
  const { classes, className, big, children, ...rest } = props;
  const { bigButton, ...others } = classes;
  return (
    <BaseButton className={clsx(className && className, big && bigButton)} classes={others} {...rest}>
      {children}
    </BaseButton>
  );
});

export default Button;

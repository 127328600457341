import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'components/v2/Button';
import ArrowLeft from 'components/v2/Icons/ArrowLeft';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import sharedStyles from 'styles/shared';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: '100%',
  },
  arrow: {
    '& svg': {
      width: 14,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  actionButton: {
    marginLeft: 'auto',
    textTransform: 'uppercase',
    minWidth: 160,
  },
}));

export default function Header({ title, agentId }) {
  const history = useHistory();
  const classes = useStyles();
  const s = sharedStyles();
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={clsx(classes.arrow, 'hoverable')} onClick={() => history.goBack()}>
          <ArrowLeft />
        </div>
        <Typography variant="h3" className={s.uppercase}>
          {title}
        </Typography>
      </div>

      <Button
        component={Link}
        to={`/agents/${agentId}/clients`}
        color="primary"
        variant="contained"
        disableElevation
        className={classes.actionButton}
      >
        View All Clients
      </Button>
    </div>
  );
}

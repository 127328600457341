import React, { useState, useEffect, useCallback, useContext } from 'react';
import { get } from 'lodash';

export const CurrencyContext = React.createContext();
CurrencyContext.displayName = 'CurrencyContext';

export const useCurrencies = () => {
  const context = useContext(CurrencyContext);
  return context;
};

export const CurrencyContextProvider = ({ currencies, children }) => {
  const [currencyNames, setCurrencyNames] = useState();
  const getCurrencyName = useCallback(
    currencyCode => {
      return get(currencyNames, currencyCode);
    },
    [currencyNames],
  );
  useEffect(() => {
    if (!currencyNames) {
      setCurrencyNames(currencies);
    }
  }, [currencies, currencyNames]);
  return (
    <CurrencyContext.Provider
      value={{
        currencyNames,
        getCurrencyName,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

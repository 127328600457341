import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { noop } from 'lodash';

import { Button } from 'common/components';
import { CircularProgress, Typography } from '@mui/material';

const styles = {
  // disabled: {},
  root: {
    position: 'relative',
    '&:not(.Mui-disabled) [class^="MuiButton-label"]': {
      borderRadius: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  overlay: {
    position: 'absolute',
    backgroundColor: 'inherit',
    inset: 0,
    borderRadius: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const AsyncButton = ({ loading = false, children = 'Save', onClick, progressProps = {}, classes, ...rest }) => {
  return (
    <Button onClick={loading ? noop : onClick} classes={{ root: classes.root, disabled: classes.disabled }} {...rest}>
      {loading && (
        <div className={classes.overlay}>
          <CircularProgress {...progressProps} size={17} />
        </div>
      )}
      <Typography>{children}</Typography>
    </Button>
  );
};

export default withStyles(styles)(AsyncButton);

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import ItineraryBuilderTravelersSelector from './ItineraryBuilderTravelersSelector';
import ItineraryBuilderDateSelector from './ItineraryBuilderDateSelector';
import SettingsButton from './SettingsButton';
import RegionSelector from './RegionSelector';
import ResetItineraryButton from './ResetItineraryButton';

const useStyles = makeStyles({
  root: {
    padding: '23px 0',
    marginBottom: 23,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 18,
    border: '1px solid #E9E1D7',
  },
});

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ItineraryBuilderTravelersSelector />
      <ItineraryBuilderDateSelector />
      <RegionSelector />
      <ResetItineraryButton />
      <SettingsButton />
    </div>
  );
};

export default React.memo(Header);

import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getDate, isBefore, parse, format, startOfWeek } from 'date-fns';
import { Dinero } from 'itrvl-pricing';
import { head, isEmpty, reduce } from 'lodash';

import Tooltip from 'components/v2/Tooltip';
import RoomAvailability from './RoomAvailability';

import { queryClient } from './ItineraryBuilderEntry';
import { useWeekByWeekAvailabilityQuery, useCalendarBySupplierWithRooms, weekByWeekQueryKey, useRoomsForSupplierQuery } from './queries';
import { useBuilderStore } from './store';
import { isAvailable } from './utils';
import { getMinChildAge } from 'itrvl-types';
import { useQuoteOnly } from './hooks';

import logger from 'itrvl-logger';
import { CALENDAR_STATE } from 'itrvl-types';
const log = logger(__filename);
log.trace(__filename);

export const useDayStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    day: {
      fontSize: '0.875rem',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      padding: 5,
      border: '1px solid #545E69',
      cursor: 'pointer',
      transition: theme.transitions.create(['background-color', 'border']),
      '&.is-own-date': {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&.available': {
        border: '1px solid #545E69',
        backgroundColor: '#fff',
      },
    },
    notAvailable: {
      // This is specifically 'not available' from bySupplier.
      border: `1px solid ${theme.palette.error.main}`,
      color: 'orange',
      backgroundColor: theme.palette.primary.main,
    },
    unavailable: {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      backgroundColor: '#F3DADA',
      cursor: 'not-allowed',
    },
    configUnavailable: {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      backgroundColor: '#CAC9C8',
      cursor: 'not-allowed',
    },
    locked: {
      backgroundColor: '#4DAA62',
      color: '#fff',
    },
    closed: {
      border: 'none',
      color: '#CAC9C8',
      backgroundColor: 'transparent',
    },
    otherDate: {
      color: 'rgba(0, 0, 0, 0.2)',
      border: '1px solid #DCDFE1',
      backgroundColor: '#DCDFE1',
    },
    loading: {
      backgroundColor: '#F2F2F2',
      border: '1px solid #F2F2F2',
      color: '#000',
    },
  }),
  {
    name: 'IB2Days',
  },
);
const Day = React.memo(({ supplierCode, dateString, data, overrides }) => {
  const classes = useDayStyles();
  const date = parse(dateString, 'yyyy-M-d', new Date());
  const now = new Date();

  const adults = useBuilderStore(state => state.data.adults);
  const children = useBuilderStore(state => state.data.children);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);
  const currentDateSegmentId = useBuilderStore(state => state.ui.dateMap[dateString]);
  const segment = useBuilderStore(state => state.data.segmentsById[currentDateSegmentId]);

  const query = useWeekByWeekAvailabilityQuery(supplierCode, startOfWeek(date), adults, childrenAges, undefined, !isBefore(date, now));
  const selectDate = useBuilderStore(state => state.actions.segments.selectDate);
  const isQuoteOnly = useQuoteOnly();

  const querySpecificAvailability = useCalendarBySupplierWithRooms(segment, dateString, isQuoteOnly);

  const roomsQuery = useRoomsForSupplierQuery(supplierCode);

  const ownDate = segment && segment.supplierCode === supplierCode;
  const otherDate = segment && segment.supplierCode !== supplierCode;

  let dayState = query?.data?.[dateString]?.s;
  let specificDayState = querySpecificAvailability?.data ? querySpecificAvailability?.data?.[dateString]?.s : null;
  if (specificDayState !== null) {
    if (specificDayState === CALENDAR_STATE.CONFIG_UNAVAILABLE) {
      specificDayState = CALENDAR_STATE.NO_LONGER_AVAILABLE;
    }
    dayState = specificDayState;
  }

  const all = query?.data?.[dateString]?.a?.['All'];
  const available = all > 0 || all === -2 || all === -1;

  const today = new Date();
  const isPreviousDate = isBefore(date, today);

  const minChildAge = getMinChildAge(children, childrenAges);
  const minChildAgeNotMet = minChildAge !== undefined && data.minChildAge !== 0 && minChildAge < data.minChildAge ? true : false;

  // Quote only override
  if (isQuoteOnly) {
    switch (dayState) {
      case CALENDAR_STATE.UNAVAILABLE:
      case CALENDAR_STATE.AVAILABLE_ON_REQUEST:
      case CALENDAR_STATE.CONFIG_UNAVAILABLE:
        dayState = CALENDAR_STATE.AVAILABLE_ON_REQUEST;
        break;
      default:
        break;
    }
  }

  // Calendar cell color
  let stateDayClass = classes.loading;
  switch (dayState) {
    case CALENDAR_STATE.UNAVAILABLE:
      stateDayClass = classes.unavailable;
      break;
    case CALENDAR_STATE.CLOSED:
      stateDayClass = classes.closed;
      break;
    case CALENDAR_STATE.NO_LONGER_AVAILABLE:
      stateDayClass = classes.notAvailable;
      break;
    case CALENDAR_STATE.CONFIG_UNAVAILABLE:
      stateDayClass = classes.configUnavailable;
      break;
    case CALENDAR_STATE.AVAILABLE_ON_REQUEST_CHECK:
    case CALENDAR_STATE.AVAILABLE_ON_REQUEST:
    case CALENDAR_STATE.AVAILABLE:
      stateDayClass = classes.available;
      break;
    // @ TODO : Child Age not met
    default:
      break;
  }

  const dayClass = clsx(
    classes.day,
    // @todo: unsure what this is? we dont seem to pass `id` to <AvailabilityCalendar /> anymore
    // id && date in dateMap && dateMap[date] && dateMap[date].id === id && 'ib2-ac-current-selection',
    // only pick one class based on precedence so we're not having to do weighted css overrides
    head(
      [
        // order of precedence
        data?.[dateString]?.dayClass && (classes[data[dateString].dayClass] ?? data[dateString].dayClass), // Must be first!
        isPreviousDate && classes.unavailable,
        ownDate && stateDayClass !== classes.available && classes.notAvailable,
        ownDate && stateDayClass === classes.available && 'is-own-date',
        otherDate && classes.otherDate,
        minChildAgeNotMet && classes.closed,
        stateDayClass,
        available && 'available',
      ].filter(Boolean),
    ),
  );

  let rooms = query?.isLoading
    ? []
    : reduce(
        ['Family', '_Double', 'Twin'],
        (acc, key) => {
          const target = query?.data?.[dateString];
          if (!target) return acc;
          const availability = target?.a?.[key];
          if (isAvailable(availability)) {
            const cost = target?.r?.[key];
            const price = cost ? Dinero({ amount: Math.round(cost * 100), currency: 'USD' }).toFormat('$0,0') : 0;
            acc.push({
              key,
              label: key,
              price,
              available: availability,
            });
          }
          return acc;
        },
        [],
      );

  // Popover and title
  let title = 'Availability is loading';
  let clickable = false;
  switch (dayState) {
    case CALENDAR_STATE.UNAVAILABLE:
      title = 'There are no rooms available for this date';
      break;
    case CALENDAR_STATE.AVAILABLE:
    case CALENDAR_STATE.AVAILABLE_ON_REQUEST:
      clickable = true;
      const showMayBeAvailable = dayState === CALENDAR_STATE.AVAILABLE_ON_REQUEST || data.restOfWorld === true ? true : false;
      title = (
        <RoomAvailability
          rooms={rooms}
          minChildAge={data.minChildAge}
          showCount={true}
          showPrice={overrides.showPrice ?? true}
          showMayBeAvailable={showMayBeAvailable}
        />
      );
      break;
    case CALENDAR_STATE.AVAILABLE_ON_REQUEST_CHECK:
      clickable = true;
      title = (
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ fontSize: '1em', margin: 0 }}>May be available</h1>
          <div>Pricing available upon quote</div>
        </div>
      );
      break;
    case CALENDAR_STATE.CLOSED:
      title = 'This accommodation is closed on this date';
      break;
    case CALENDAR_STATE.CONFIG_UNAVAILABLE:
      title = (
        <RoomAvailability
          rooms={rooms}
          minChildAge={data.minChildAge}
          showCount={true}
          showPrice={overrides.showPrice ?? true}
          showMayBeAvailable={false}
          configUnavailable={true}
        />
      );
      break;
    case CALENDAR_STATE.NO_LONGER_AVAILABLE:
      title = (
        <RoomAvailability
          rooms={rooms}
          minChildAge={data.minChildAge}
          showCount={true}
          showPrice={overrides.showPrice ?? true}
          showMayBeAvailable={false}
          nolongerAvailable={true}
        />
      );
      break;
    default:
      break;
  }

  if (data?.[dateString]?.title) {
    // Note: this override comes from BedNightsModal, not the API response, and is important for correct tooltip rendering!
    title = data[dateString].title;
  } else if (query?.isFetching || querySpecificAvailability.isFetching) {
    title = 'Availability is loading';
  } else if (isPreviousDate) {
    title = 'This date is unavailable';
  } else if (otherDate) {
    title = `${segment?.campName} is requested on this date`;
  } else if (ownDate && stateDayClass === classes.available) {
    title = 'This accommodation is requested on this date';
  }

  // @todo: useCallback
  const handleClick = () => {
    if (overrides.handleClick) {
      overrides.handleClick({ dateString, dayClass });
      return;
    }
    if (isPreviousDate) return;
    if (!isQuoteOnly && query?.isFetching) return;
    if (minChildAgeNotMet) return;
    if (ownDate) {
      // Always allow to turn off.
      selectDate(data, date);
      return;
    }
    if (otherDate) return;
    if (clickable) {
      selectDate(
        {
          ...data,
          // @todo: ghetto hack to tag this item with consultantInteractionRequired __after__ fetch conditions
          // this can go away when we hook up prefetching
          ...(roomsQuery.isFetched && roomsQuery.data && isEmpty(roomsQuery.data) && { consultantInteractionRequired: true }),
        },
        date,
      );
      return;
    }
  };

  useEffect(() => {
    return () => {
      const queryKey = weekByWeekQueryKey(supplierCode, dateString, adults, childrenAges);
      queryClient.cancelQueries({ queryKey, exact: true });
      // @todo: maybe re-enable this?, this reduces memory usage
      // queryClient.removeQueries({ queryKey, exact: true });
    };
  }, [supplierCode, dateString, adults, childrenAges]);

  return (
    <div className={classes.root}>
      <Tooltip title={title} maxWidth="auto">
        <div className={dayClass} onClick={handleClick} role="button" aria-label={format(date, 'yyyy-MM-dd')}>
          {getDate(date)}
        </div>
      </Tooltip>
    </div>
  );
});

export default Day;

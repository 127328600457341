import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import SettingsIcon from './icons/Settings';

const useStyles = makeStyles({
  root: {
    width: 34,
    height: 34,
  },
});

const SettingsButton = () => {
  const classes = useStyles();
  // @todo: disabling this for now
  if (true) {
    return null;
  }
  return (
    <div className={classes.root}>
      <SettingsIcon />
    </div>
  );
};

export default SettingsButton;

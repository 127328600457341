import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useApi } from 'common/hooks/api';

export const useGetDmcByCountryCurrencyQuery = ({ countryCode, currency }) => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery({
    queryKey: ['dmc', { countryCode, currency }],
    queryFn: async () => {
      return await Api.getDmcByCountryCurrency({ countryCode, currency });
    },
    onSuccess: () => {},
    onError: err => enqueueSnackbar(err?.response?.data?.error?.message, { variant: 'error' }),
  });
  return query;
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import InfoBox from './InfoBox';
import { useBuilderStore } from './store';

const useStyles = makeStyles(
  {
    wrap: {
      padding: '0 32px 23px 32px',
    },
    text: {},
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  {
    name: 'InfoSection',
  },
);

const InfoSection = () => {
  const classes = useStyles();
  const openDatePicker = useBuilderStore(state => state.actions.date.openDatePicker);
  const date = useBuilderStore(state => state.data.date);
  return (
    <div className={classes.root}>
      {date === undefined && (
        <div className={classes.wrap}>
          <InfoBox
            text={
              <span className={classes.text}>
                To View Accommodation Availability,{' '}
                <span
                  className={classes.link}
                  onClick={e => {
                    e.stopPropagation();
                    openDatePicker();
                  }}
                >
                  Select a Date
                </span>
              </span>
            }
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(InfoSection);

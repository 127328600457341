import { useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import { rateLimits } from 'itrvl-pricing';

export const useCustomizations = () => {
  const themeContext = useContext(ThemeContext);
  return themeContext?.customizations || {};
};

export const useRates = () => {
  const customizations = useCustomizations();
  return {
    land: customizations?.pricingSettings?.landRate ?? rateLimits.land.default,
    air: customizations?.pricingSettings?.airRate ?? rateLimits.air.default,
    deposit: customizations?.pricingSettings?.depositRate ?? rateLimits.deposit.default,
    currency: customizations?.pricingSettings?.currencyRate ?? rateLimits.currency.default,
    dmc: customizations?.pricingSettings?.dmcRate ?? rateLimits.dmc.default,
    rack: customizations?.pricingSettings?.rackRate ?? rateLimits.rack.default,
  };
};

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import memoize from 'memoize-one';
import { filter, groupBy, sortBy } from 'lodash';

const styles = theme => ({
  root: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  lastNameWrapper: {
    marginBottom: '10px',
    color: '#fff',
  },
  lastNameChar: {
    marginBottom: '5px',
    display: 'block',
    color: '#000',
  },
  client: {
    color: '#000',
    border: '1px solid #000',
    padding: '10px 15px',
    '&:not(.solo):first-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
  },
  solo: {},
});

export class ClientList extends Component {
  categorizeClients = memoize(clients =>
    groupBy(
      sortBy(filter(clients, ['archived', false]), client => `${client.lastName} ${client.firstName}`),
      client => client.lastName?.slice(0, 1).toUpperCase(),
    ),
  );

  selectClient = id => () => this.props.onClientSelect?.(id);

  render() {
    const { classes, clients = [] } = this.props;
    const categorizedClients = this.categorizeClients(clients);
    return (
      <div className={classes.root} data-test-id="agent-client-list">
        {Object.keys(categorizedClients)
          .sort()
          .map(lastNameChar => (
            <div className={classes.lastNameWrapper} key={lastNameChar}>
              <span className={classes.lastNameChar}>{lastNameChar}</span>
              <div>
                {categorizedClients[lastNameChar].map((client, index) => (
                  <div
                    key={index}
                    // @todo: nightwatch:removal - remove list_client when
                    className={clsx(classes.client, 'client-list_client', categorizedClients[lastNameChar].length === 1 && 'solo')}
                    onClick={this.selectClient(client)}
                    data-test-id={`client-list-client`}
                  >
                    {client.name}
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(ClientList);

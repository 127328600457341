import { SEGMENT_TYPE } from 'itrvl-types';

export const editTransitDetermineFromTo = (segment, fromTo) => {
  switch (segment?.type) {
    case 'stay':
      return { [`code${fromTo}`]: segment?.supplierCode, [`type${fromTo}`]: 'supplier' };
    case 'point':
      return { [`code${fromTo}`]: segment?.travelHubCode, [`type${fromTo}`]: 'hub' };
    case 'road':
    case 'flight':
      return { [`code${fromTo}`]: segment?.locationCode, [`type${fromTo}`]: 'location' };
    default:
      return { [`code${fromTo}`]: '', [`type${fromTo}`]: '' };
  }
};

export const editTransitIndexSplit = ({ segments, index, onlyAccommodation = false }) => {
  let indexPrev, indexNext;
  let types = [SEGMENT_TYPE.FLIGHT, SEGMENT_TYPE.POINT, SEGMENT_TYPE.ROAD, SEGMENT_TYPE.STAY];
  if (onlyAccommodation) types = ['stay'];
  for (let i = index - 1; i >= 0; i--) {
    if (types.includes(segments[i]?.type)) {
      indexPrev = i;
      break;
    }
  }
  for (let i = index + 1; i < segments.length; i++) {
    if (types.includes(segments[i]?.type)) {
      indexNext = i;
      break;
    }
  }
  if (!indexPrev) indexPrev = 0;
  if (!indexNext) indexNext = segments.length - 1;

  return { indexPrev, indexNext };
};

import React from 'react';

const ServiceBell = () => (
  <svg viewBox="0 0 420 420.6">
    <path
      d="M408.3,327H11.7C5.2,327,0,332.2,0,338.6c0,6.4,5.2,11.7,11.7,11.7h396.7c6.4,0,11.7-5.2,11.7-11.7
		C420,332.2,414.8,327,408.3,327z"
    />
    <path
      d="M55.7,303.6h308.5c6.4,0,11.7-5.2,11.7-11.7c0-92.4-68.2-168-154.3-174.4l0-23.9h32.4
		c6.4,0,11.7-5.2,11.7-11.7c0-6.4-5.3-11.7-11.7-11.7H166c-6.4,0-11.7,5.2-11.7,11.7c0,6.4,5.2,11.7,11.6,11.7h32.4v23.9
		c-86.1,6.4-154.2,82-154.2,174.4C44.1,298.4,49.3,303.6,55.7,303.6L55.7,303.6z M210,140.3c74.9,0,136.6,61.8,142.2,140H67.8
		C73.4,202.1,135.1,140.3,210,140.3L210,140.3z"
    />
  </svg>
);

export default ServiceBell;

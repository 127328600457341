import React from 'react';
import ThemeProvider from 'components/v2/ThemeProvider';
import ItrvlAppBar from '../ItrvlAppBar';

const WrappedPageInner = ({ page: Page, useFixedAppbar, ...props }) => {
  return (
    <>
      <ItrvlAppBar useFixedAppbar={useFixedAppbar} />
      <Page {...props} />
    </>
  );
};

export default function WrappedPage(props) {
  //TODO - when v1 pages are refactored remove the v2 theme provider
  const MaybeTheme = props.useV1Theme ? EmptyComponent : ThemeProvider;
  return (
    <MaybeTheme>
      <WrappedPageInner {...props} />
    </MaybeTheme>
  );
}

const EmptyComponent = ({ children }) => <>{children}</>;

import logger from 'itrvl-logger';

import { invert, isUndefined, has, get, set } from 'lodash';

const log = logger(__filename);

// Flags
export const ITINERARY_FLAG = {
  // lcr - Last Currency Rate - Used to detect changes to currency rates so we can adjust rates when required
  LAST_CURRENCY_RATE: 'lcr',
  // inconsistent - Costs don't match wilderness - Used to indicate that our calculated costs don't match what wilderness told us.
  INCONSISTENT: 'inconsistent',
  // xfsm - Extra Foreign Sell Markup - Indicates the itinerary is subject to a 1% markup when selling in a foreign currency
  EXTRA_FOREIGN_SELL_MARKUP: 'xfsm',
  // requote - This itinerary can reprice because it is being requoted due to a change
  REQUOTE: 'requote',
  // probnotif - Problem notification email has been sent - Cleared when problems go away
  PROBLEM_NOTIFY_SENT: 'probnotif',
  // suppress_prob_negative_margin - Negative margin problem suppressed at agent request, allow sell
  SUPPRESS_PROBLEM_NEGATIVE_MARGIN: 'suppress_problem_negative_margin',
  // genpay - Payments should be generated
  GENERATE_PAYMENTS: 'genpay',
  // locked_incompatible: API does not support changes to this itinerary and it is locked
  LOCKED_INCOMPATIBLE: 'locked_incompatible',
};

const getFlags = itinerary => get(itinerary, 'flags');

const checkFlag = flag => {
  if (isUndefined(invert(ITINERARY_FLAG)[flag])) {
    const err = new Error(`Unknown itinerary flag: ${flag}`);
    log.error(err);
    throw err;
  }
};

export const hasItineraryFlag = (itinerary, flag) => {
  checkFlag(flag);

  return has(getFlags(itinerary), flag);
};

export const getItineraryFlag = (itinerary, flag) => {
  checkFlag(flag);

  return get(getFlags(itinerary), flag, false);
};

export const deleteItineraryFlag = (itinerary, flag) => {
  checkFlag(flag);

  if (hasItineraryFlag(itinerary, flag)) {
    delete getFlags(itinerary)[flag];
  }
};

export const setItineraryFlag = (itinerary, flag, value = true) => {
  checkFlag(flag);
  set(itinerary, `flags.${flag}`, value);
};

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.stoneGrey.white80,
  },
}));

export default function LoadingOverlay({ open = true, dataTestId = 'loading-overlay' }) {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} unmountOnExit={true}>
        <CircularProgress color="inherit" data-test-id={dataTestId} />
      </Backdrop>
    </div>
  );
}

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const VALIDATION_EXPRESSIONS = {
  validate: target => {
    const field = target.id;
    const value = target.value;
    const error = {};
    if (target.type === 'checkbox') {
      if (target.required) {
        error[field] = !target.value ? 'Required field' : '';
      }
    } else if (target.required && value.trim() === '') {
      error[field] = 'Required field';
    } else {
      switch (field) {
        case 'email':
          if (value && value.trim() !== '') {
            const isValid = VALIDATION_EXPRESSIONS.email.regex.test(value);
            if (!isValid) {
              error[field] = VALIDATION_EXPRESSIONS.email.failureMessage;
            } else {
              error[field] = '';
            }
          } else {
            error[field] = '';
          }
          break;
        case 'firstName':
          if (value && value.trim() !== '') {
            const isValid = VALIDATION_EXPRESSIONS.firstName.regex.test(value);
            if (!isValid) {
              error[field] = VALIDATION_EXPRESSIONS.firstName.failureMessage;
            } else {
              error[field] = '';
            }
          } else {
            error[field] = '';
          }
          break;
        case 'lastName':
          if (value && value.trim() !== '') {
            const isValid = VALIDATION_EXPRESSIONS.lastName.regex.test(value);
            if (!isValid) {
              error[field] = VALIDATION_EXPRESSIONS.lastName.failureMessage;
            } else {
              error[field] = '';
            }
          } else {
            error[field] = '';
          }
          break;
        case 'title':
          if (value && value.length > 5) {
            error[field] = 'Title must be 5 characters or less';
          } else {
            error[field] = '';
          }
          break;
        default:
          error[field] = '';
          break;
      }
    }
    return error;
  },
  email: {
    regex: /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/,
    failureMessage: 'Please enter a valid email',
  },
  firstName: {
    regex: /^[\d 'A-Za-z-]*$/,
    failureMessage: 'No special characters',
  },
  lastName: {
    regex: /^[\d 'A-Za-z-]*$/,
    failureMessage: 'No special characters',
  },
};

import { createContext, useContext } from 'react';

export const ThemeContext = createContext({
  theme: null,
  customizations: {},
  setTheme: () => {},
  resetTheme: () => {},
  setCustomization: () => {},
});

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  return context;
};

ThemeContext.displayName = 'ThemeContext';

const Search = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3633 14.0117L10.4805 10.1562C11.3555 9.14453 11.8477 7.85938 11.8477 6.4375C11.8477 3.32031 9.27734 0.75 6.16016 0.75C3.01562 0.75 0.5 3.32031 0.5 6.4375C0.5 9.58203 3.04297 12.125 6.16016 12.125C7.55469 12.125 8.83984 11.6328 9.85156 10.7578L13.707 14.6406C13.8164 14.7227 13.9258 14.75 14.0625 14.75C14.1719 14.75 14.2812 14.7227 14.3633 14.6406C14.5273 14.4766 14.5273 14.1758 14.3633 14.0117ZM6.1875 11.25C3.50781 11.25 1.375 9.08984 1.375 6.4375C1.375 3.78516 3.50781 1.625 6.1875 1.625C8.83984 1.625 11 3.78516 11 6.4375C11 9.11719 8.83984 11.25 6.1875 11.25Z"
      fill="currentColor"
    />
  </svg>
);

export default Search;

import './overrides.css';
import React, { useCallback, useContext, useState } from 'react';
import { ReactComponent as ItrvlLogo } from 'common/assets/itrvl_logo--all-white.svg';
import ClientIcon from 'common/components/Icons/v2/Client';
import Subject from '@mui/icons-material/Subject';

import { lighten } from '@mui/material/styles';

import { Link, useHistory } from 'react-router-dom';

import SiteMenu from './SiteMenu';

import { AppBar, Button, Toolbar } from '@mui/material';

import isKeyDown from 'common/utils/keys';
import ClientSelectorModal from './Builder/ClientSelectorModal';
import GlobalSearch from './GlobalSearch';
import makeStyles from '@mui/styles/makeStyles';
import UserContext from 'common/context/UserContext';

import logger from 'itrvl-logger';

const log = logger(__filename);
log.trace(__filename);

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 10,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
  },
  toolBar: {
    flex: 1,
  },
  logo: {
    height: 69,
    width: 148,
    padding: 9,
    flexShrink: 0,
  },
  name: {
    marginLeft: '20px',
    color: 'white',
  },
  growHalf: {
    flexGrow: 0.5,
  },
  menuButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexGrow: 1,
    alignItems: 'center',
    padding: 20,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuItem: {
    padding: '6px 13px',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
  clientButton: {
    // color: '#fff',
    padding: '6px 13px',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: '#415D602',
    marginRight: 20,
  },
  iconButton: {
    float: 'right',
    color: '#333',
  },
  iconLabel: {
    fontSize: '9px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 1 / 2,
    textTransform: 'uppercase',
  },
  iconDrawer: {
    fontSize: '1.5rem',
  },
  navButtons: {
    flexGrow: 0.5,
    float: 'left',
  },
  clientDetailsButton: {
    '&:hover': { color: 'white' },
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.main,
    padding: '10px',
    height: '85px',
    width: '95px',
    textAlign: 'center',
  },
  menuBtn: {
    color: '#fff',
    height: 69,
    width: 69,
  },

  clientInfo: {
    marginRight: theme.spacing(2),
    fontFamily: 'Atyp Display',
    color: '#fff',
    '& button': {
      fontFamily: 'Atyp Display',
      textTransform: 'capitalize',
      color: 'white',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.light, 0.1),
      },
    },
  },
}));

const ItrvlAppBar = ({ useFixedAppbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext);

  const [showSiteMenu, setShowSiteMenu] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [next, setNext] = useState();

  const handleLogout = () => {
    setShowClientModal(false);
    setShowSiteMenu(false);
    userContext.logout();
  };

  const toggleClientModal = useCallback(
    ({ next }) => {
      setShowClientModal(curr => !curr);
      setNext(next);
    },
    [setShowClientModal, setNext],
  );

  const selectClient = async client => {
    if (next) {
      next();
    }

    userContext.updateClient(client);
    history.push(`/clients/${client.id}`);
    setShowClientModal(false);
  };

  // Easter egg. Hold down s and click to generate error
  const wrappedLogo = (
    <Link
      to={() =>
        isKeyDown(83) // s
          ? '/sentryError'
          : isKeyDown(67) // c
          ? '/config'
          : '/'
      }
    >
      <ItrvlLogo alt="itrvl" />
    </Link>
  );
  const menu = (
    <>
      <Button
        className={classes.menuBtn}
        onClick={() => setShowSiteMenu({ showSiteMenu: true })}
        aria-label="Navigation Menu"
        id="agent_app-bar_menu-button"
        data-test-id="navigation-menu-button"
      >
        <Subject />
      </Button>
      <SiteMenu open={showSiteMenu} logout={handleLogout} onClose={() => setShowSiteMenu(false)} />
    </>
  );

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position={useFixedAppbar ? 'fixed' : 'static'} color="primary" data-test-id="app-bar-container">
        <Toolbar className={classes.toolBar} disableGutters={true}>
          {menu}
          <div className={classes.logo}>{wrappedLogo}</div>
          <div className={classes.menuButtons}>
            <GlobalSearch />
            <div className={classes.clientInfo} data-test-id="client-info">
              <Button onClick={toggleClientModal} color="secondary" data-test-id="client-info-selector" sx={{ paddingX: '35px' }}>
                {userContext.client ? `Client: ${userContext.client.name}` : 'Select Client'}
              </Button>
            </div>

            {showClientModal && (
              <ClientSelectorModal
                onSelectClient={selectClient}
                clients={userContext.clients}
                onNewClient={userContext.addClient}
                onClose={toggleClientModal}
              />
            )}
          </div>
          {userContext?.client?.id && (
            <>
              <Link
                to={`/clients/${userContext.client.id}`}
                id="app-bar_client-details"
                data-test-id="navigation-client-details"
                className={classes.clientDetailsButton}
              >
                <span className={classes.iconDrawer}>
                  <ClientIcon />
                </span>

                <div className={classes.iconLabel}>Client Details</div>
              </Link>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ItrvlAppBar;

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToggleButton, ToggleButtonGroup, Popper, Paper, TextField, ClickAwayListener } from '@mui/material';

import { QUOTE_ONLY_MODE } from './constants';
import { useBuilderStore } from './store';
import { NewChip } from 'components';
import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import Tooltip from '../v2/Tooltip';

const useStyles = makeStyles({
  root: {},
  fillIcon: {
    '& path': {
      strokeWidth: 4,
      stroke: '#000',
    },
  },
  staticDatePicker: {
    '&>.muitrvl-MuiDialogActions-root': {
      display: 'none', // slots/slotProps is not working..?
    },
  },
});

const options = [
  {
    label: 'Shift Start Date Left',
  },
  {
    label: 'Change Start Date',
  },
  {
    label: 'Shift Start Date Right',
  },
];

const ChangeStartDateSelector = () => {
  const classes = useStyles();
  const quoteMode = useBuilderStore(state => state.data.quoteMode);

  const date = useBuilderStore(state => Object.keys(state.ui.dateMap).sort()[0]);
  const setChangeStartDate = useBuilderStore(state => state.actions.changeStartDate.set);
  const openChangeStartDate = useBuilderStore(state => state.ui.openChangeStartDate);
  const setOpenChangeStartDate = useBuilderStore(state => state.actions.changeStartDate.setOpen);

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Tooltip
      title={
        <>
          <NewChip /> {options[1].label}
        </>
      }
      placement="top"
      showOnCreate={true}
      onShow={tip => {
        setTimeout(() => tip.hide(), 5000);
      }}
      delay={[1500, null]}
      trigger="manual"
    >
      <ToggleButtonGroup className={classes.root} aria-label="Change Start Date">
        <Tooltip title={options[0].label} placement="bottom">
          <ToggleButton
            value={quoteMode === QUOTE_ONLY_MODE}
            aria-label={options[0].label}
            label="availability selector"
            className={classes.fillIcon}
            onClick={() => setChangeStartDate({ days: -1 })}
          >
            <ItrvlIcon className={classes.fillIcon} name="chevronLeft"></ItrvlIcon>
          </ToggleButton>
        </Tooltip>
        <Tooltip title={options[1].label} placement="bottom">
          <ToggleButton
            value={quoteMode === QUOTE_ONLY_MODE}
            aria-label={options[1].label}
            label="availability selector"
            className={classes.fillIcon}
            ref={setAnchorEl}
            selected={openChangeStartDate}
            onClick={() => {
              setOpenChangeStartDate(!openChangeStartDate);
            }}
          >
            <ItrvlIcon className={classes.fillIcon} name="dates"></ItrvlIcon>
          </ToggleButton>
        </Tooltip>
        <ClickAwayListener onClickAway={() => setOpenChangeStartDate(false)} mouseEvent="onMouseUp">
          <Popper open={openChangeStartDate} anchorEl={anchorEl} style={{ zIndex: 1 }}>
            <Paper>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  className={classes.staticDatePicker}
                  onChange={e => {
                    setOpenChangeStartDate(false);
                    setChangeStartDate(e);
                  }}
                  showToolbar={false}
                  disableHighlightToday={true}
                  disablePast={true}
                  value={date}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Paper>
          </Popper>
        </ClickAwayListener>
        <Tooltip title={options[2].label} placement="bottom">
          <ToggleButton
            value={quoteMode === QUOTE_ONLY_MODE}
            aria-label={options[2].label}
            label="availability selector"
            className={classes.fillIcon}
            onClick={() => setChangeStartDate({ days: 1 })}
          >
            <ItrvlIcon className={classes.fillIcon} name="chevronRight"></ItrvlIcon>
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Tooltip>
  );
};

export default ChangeStartDateSelector;

import axios from 'axios';
import { forEach, get, head, keys } from 'lodash';
import * as Sentry from '@sentry/browser';
import Cookies from 'universal-cookie';
import { getCookieOptions } from 'common/utils/cookie';
import { zapDealId } from 'itrvl-types';

import logger from 'itrvl-logger';
const log = logger(__filename);

function BindToClass(functionsObject, thisClass, subProp) {
  for (let [functionKey, functionValue] of Object.entries(functionsObject)) {
    if (subProp) {
      thisClass[subProp][functionKey] = functionValue.bind(thisClass);
    } else {
      thisClass[functionKey] = functionValue.bind(thisClass);
    }
  }
}

const handleResponse = response => response?.data;

function parseAxiosError(err) {
  // log.debug(`err: err.response? ${err.response}`);
  // log.debug(`err: err.request? ${err.request}`);
  // log.debug(`err.request json: `, err.toJSON());

  let method, model, code, url, errorString, shortErrorString, responseErrorString;
  method = get(err, 'config.method');
  code = get(err, 'code');
  url = get(err, 'config.url');
  errorString = get(err, 'message');
  let parsedUrl = new URL(url, 'http://localhost');
  model = parsedUrl.pathname.split('/')?.[2];
  shortErrorString = `${errorString} on ${method.toUpperCase()} /${model}`;
  // log.debug(`shortErrorString: ${shortErrorString}`);

  if (err.response) {
    code = get(err.response, 'status');
    responseErrorString = get(err.response, 'data.error.message');
  } else {
    let parsedUrl = new URL(url, 'http://localhost');
    model = parsedUrl.pathname.split('/')?.[2];
    shortErrorString = `${errorString} on ${method.toUpperCase()} /${model}`;
    // log.debug(`shortErrorString: ${shortErrorString}`);
  }

  log.debug(`method ${method} model ${model} code ${code} url ${url} message ${errorString}`);

  return { method, model, code, url, shortErrorString, errorString, responseErrorString };
}

class CommonApi {
  constructor(server) {
    this.cookies = new Cookies();
    this.axios = axios.create({
      baseURL: server || '/',
      timeout: 60000,
    });
    this.axios.interceptors.request.use(
      config => {
        log.debug('request before, config: ', config);
        return config;
      },
      err => {
        log.debug('request err, err: ', err);
        return Promise.reject(err);
      },
    );
    this.axios.interceptors.response.use(
      response => {
        // @todo: pick up re-auth and update cookie

        // log.debug('response before, response: ', response);
        return response;
      },
      err => {
        const { method, model, code, url, shortErrorString, errorString, responseErrorString } = parseAxiosError(err);
        Sentry.withScope(scope => {
          if (code && url) {
            scope.setFingerprint([method, model, code]);
          }
          if (err.response) {
            if (responseErrorString) {
              scope.setContext('Response', responseErrorString);
            }
            if (err.response.status === 401) {
              if (!window.location.pathname.startsWith('/client/')) {
                if (err.config.url !== '/api/Agents/loginAsUser') {
                  this.cookies.remove('TOKEN', getCookieOptions());
                  this._updateInstanceToken();
                  if (err.config.url !== '/api/Agents/login') {
                    window.location.href = '/login';
                  }
                }
              }
              if (window.location.pathname.startsWith('/client/')) {
                this.cookies.remove('CLIENT_TOKEN', getCookieOptions());
                this._updateInstanceToken();
                if (err.config.url !== '/api/Clients/login') {
                  window.location.href = '/client/login';
                }
              }
            }

            if (err.response.status >= 400 && err.response.status !== 401) {
              const url = get(err, 'config.url');
              switch (url) {
                case '/api/Agents/login':
                case '/api/Clients/login':
                case '/api/Info/whoAmI':
                  // Don't report login failures
                  // Don't report whoAmI failures (expired token)
                  return;
                default:
                  break;
              }
              Sentry.captureException(new Error(`${shortErrorString}`), () => scope);
            }
          } else {
            // Never made it to the server or timed out
            if (err.code === 'ECONNABORTED') {
              let level = 'error';
              Sentry.captureException(new Error(`${errorString}: ${url}`), { level }, () => scope);
            } else {
              Sentry.captureException(new Error(`${errorString}: ${url}`), () => scope);
            }
          }
        });
        return Promise.reject(err);
      },
    );
  }
  post(...args) {
    return this.axios.post(...args);
  }
  postWithCredentials(...args) {
    return this.axios.post(...args, { withCredentials: true });
  }
  put(...args) {
    return this.axios.put(...args);
  }
  get(...args) {
    return this.axios.get(...args);
  }
  patch(...args) {
    return this.axios.patch(...args);
  }
  head(...args) {
    return this.axios.head(...args);
  }
  delete(...args) {
    return this.axios.delete(...args);
  }
  _updateInstanceToken(token) {
    if (token) {
      this.axios.defaults.headers.common['Authorization'] = token;
    } else {
      delete this.axios.defaults.headers.common['Authorization'];
    }
  }
  _masquerade(token, masqFromToken) {
    log.debug(`Api masq: token ${token}, originalToken ${masqFromToken}`);
    if (token) {
      this.axios.defaults.headers.common['X-Access-Masq'] = token;
      this.axios.defaults.headers.common['X-Access-MasqFrom'] = masqFromToken;
    } else {
      delete this.axios.defaults.headers.common['X-Access-Masq'];
      delete this.axios.defaults.headers.common['X-Access-MasqFrom'];
    }
  }
  loadConfig() {
    return this.get(`/_api/config?type=agent`);
  }
  logAudit({ action, agentId, clientId, itineraryId, details }) {
    return this.post('/api/AuditTrail/logAudit', {
      action,
      agentId,
      clientId,
      itineraryId,
      details,
    });
  }

  logMessage(message) {
    return this.post('/api/Info/logMessage', { message });
  }
  downloadItineraryLocation(id) {
    return this.get(`/api/Itineraries/${id}/download`);
  }
  downloadItinerary(id) {
    return this.axios({
      method: 'GET',
      url: `/api/Itineraries/${id}/download`,
    });
  }
  getRates(optionKeys, dateFrom, dateTo) {
    return this.post('/api/Rates/getByOptionKey', {
      optionKeys,
      startDate: dateFrom,
      endDate: dateTo,
    });
  }
  getRatesBySupplier(supplierCodes, dateFrom, dateTo) {
    return this.post('/api/Rates/getBySupplier', {
      supplierCodes,
      startDate: dateFrom,
      endDate: dateTo,
    });
  }
  async getInclusionsBySupplier(supplierCodes, dateFrom, dateTo) {
    return handleResponse(
      await this.post('/api/Rates/inclusions', {
        supplierCodes,
        startDate: dateFrom,
        endDate: dateTo,
      }),
    );
  }
  getActivityInfo(supplierCode, locale) {
    return this.get(`/api/Activities/${supplierCode}/activity-info${locale ? `?locale=${locale}` : ''}`);
  }
  getCampInfo(supplierCode, locale) {
    return this.get(`/api/Camps/${supplierCode}/camp-info${locale ? `?locale=${locale}` : ''}`);
  }
  getAccommodationInfo(supplierCode, locale) {
    return this.get(`/api/Camps/${supplierCode}/accommodation-info${locale ? `?locale=${locale}` : ''}`);
  }
  // await fetchInExclusions(stayStep.startDate, stayStep.endDate, stayStep.supplierCode)
  async getInExclusions(formattedStartDate, formattedEndDate, supplierCode, optionKeys) {
    let inclusions = '',
      rates;

    try {
      const { data } = await this.getRatesBySupplier([supplierCode], formattedStartDate, formattedEndDate, 'Twin', 4);
      rates = data;
    } catch (err) {
      log.error('[CommonAPI], `this.getRatesBySupplier` failed, ', err);
    }

    forEach(optionKeys, (optionKey, index) => {
      // Take the any date we have a rate for
      let ratesForOptionKey = get(rates, optionKey);
      if (ratesForOptionKey) {
        let rateForOptionKey = ratesForOptionKey[head(keys(ratesForOptionKey))];
        if (index > 0) inclusions += '\n\n';
        inclusions += `${rateForOptionKey.room}: ${rateForOptionKey.inclusions}`;
      }
    });
    return inclusions;
  }
  itineraryHold(itineraryId, _itinerary, message) {
    log.debug(`WindowAPI GetHold for id: ${itineraryId}`);
    return this.post('/api/WindowAPIs/GetHold', {
      itineraryId,
      mock: false,
      message,
    });
  }
  itinerarySave(itinerary) {
    return itinerary.id ? this.patch(`/api/Itineraries/${itinerary.id}`, itinerary) : this.post('/api/Itineraries', itinerary);
  }
  getSavedItineraryAvailability(itineraryId, startDate, endDate, rooms, quoteOnly = false) {
    return this.post('/api/SavedItineraries/getAvailability', {
      id: itineraryId,
      startDate,
      endDate,
      rooms,
      quoteOnly,
    });
  }
  getItinerary(itineraryId, filter) {
    return this.get(`/api/Itineraries/${itineraryId}` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  async zap(zap, userContext, itinerary, url, payload) {
    log.debug('zap', zap, userContext, itinerary, url);
    const pay = {
      ...zapDealId(userContext?.client),
      'Agency Name': get(userContext, 'user.agency.name'),
      'Agency Code': get(userContext, 'user.agency.agencyCode'),
      'Agent Name': get(userContext, 'user.agent.fullName', `${get(userContext, 'user.firstName')} ${get(userContext, 'user.lastName')}`),
      'Agent Email': get(userContext, 'user.agent.email', get(userContext, 'user.email')),
    };
    const load = {
      'Link to itinerary in itrvl': url,
      'WW Booking ref': get(itinerary, 'quoteId'),
      WWKey: get(itinerary, 'wwKey'),
      'Client name': get(userContext, 'client.name', get(userContext, 'user.name')),
    };
    payload = payload || { ...pay, ...load };
    log.debug('zap:', payload);
    const method = 'POST';
    const headers = { 'Content-Type': 'application/json' };
    const body = JSON.stringify({ zap, payload });
    fetch(`/_api/zap`, { method, headers, body });
  }
}

export { CommonApi as default, BindToClass };

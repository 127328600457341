import React from 'react';

import TravelersSelector from 'components/v2/TravelersSelector';
import { useBuilderStore } from './store';

const ItineraryBuilderTravelersSelector = () => {
  const adults = useBuilderStore(state => state.data.adults);
  const children = useBuilderStore(state => state.data.children);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);
  const setTravelersByType = useBuilderStore(state => state.actions.travelers.setTravelersByType);
  const setChildrenAges = useBuilderStore(state => state.actions.travelers.setChildrenAges);
  return (
    <TravelersSelector
      adults={adults}
      children={children}
      childrenAges={childrenAges}
      setTravelersByType={setTravelersByType}
      onChildrenAgeUpdate={setChildrenAges}
    />
  );
};

export default ItineraryBuilderTravelersSelector;

import React, { Component } from 'react';
import AsyncButton from './Buttons/AsyncButton';
import PasswordField from 'material-ui-password-field';

import withUser from 'common/hocs/withUser';

export class Verify extends Component {
  state = {
    loading: true,
    error: false,
    submitting: false,
    success: false,
  };

  async componentDidMount() {
    const verificationToken = this.props.match.params.token;
    const verificationUid = this.props.match.params.uid;
    try {
      const response = await this.props.onVerify(verificationToken, verificationUid);
      if (this.props.onSuccess && (response.status === 200 || response.status === 204)) {
        this.props.onSuccess(response.data);
      }
    } catch {
      this.setState({ error: true, loading: false });
    }
  }

  // TODO: This does not seem to be used from client anymore
  // onSubmit used to wire to setClientPassword
  savePassword = async () => {
    this.setState({ submitting: true });
    const success = await this.props.onSubmit(this.props.match.params.token, this.state.password);
    this.setState({ submitting: false, success });
  };

  render() {
    const { loading, error, submitting, success } = this.state;
    return loading ? (
      'Loading'
    ) : error ? (
      <p>This is an invalid registration token. Please try again.</p>
    ) : (
      <div>
        {success ? (
          <div>
            Your password has been set, you may now <a href={`/${this.props.user.app}/login`}>Log in</a>.
          </div>
        ) : (
          <React.Fragment>
            <div>You must enter a password to activate your account</div>
            <PasswordField
              hintText="At least 8 characters"
              floatingLabelText="Enter your password"
              errorText="Your password is too short"
              onChange={e => this.setState({ password: e.target.value })}
            />
            <AsyncButton onClick={this.savePassword} loading={submitting} />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withUser(Verify);

import React, { Component } from 'react';

import { escapeRegExp } from 'lodash';
import withStyles from '@mui/styles/withStyles';

import SearchBar from '../SearchBar';
import ClientList from './ClientList';
import AddClientModal from '../AddClientModal';
import AddButton from '../AddButton';

import logger from 'itrvl-logger';
const log = logger(__filename);

const styles = {
  root: {},
  searchWrapper: {
    marginBottom: 10,
    paddingBottom: 20,
    borderBottom: '1px solid rgb(232, 232,232)',
  },
  searchPaper: {
    border: '1px solid #000',
  },
  searchInput: {
    color: '#000',
  },
  noClients: {
    color: '#000',
    padding: 20,
    textAlign: 'center',
  },
};

const doFilter = (search, clients) => {
  if (!search || search === '') {
    return clients;
  }
  const regExp = new RegExp(escapeRegExp(search), 'i');
  return clients.filter(client => regExp.test(client.name));
};

// @todo: we may need to refactor the async logic here, i dunno if its prefetched, live filtered etc
export class ClientSelector extends Component {
  state = {
    showAddClientModal: false,
    loading: false,
    hasLoaded: false,
    search: '',
    clients: this.props.clients ?? [],
    filteredClients: this.props.clients ?? [],
  };

  /* eslint-disable-next-line camelcase */
  UNSAFE_UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clients !== nextProps.clients) {
      const clients = nextProps.clients;
      const filteredClients = doFilter(this.state.search, clients);
      this.setState({ ...this.state, clients, filteredClients });
    }
  }

  handleNewClient = () => {
    this.setState({ showAddClientModal: true });
  };

  handleSearch = search => {
    const filteredClients = doFilter(search, this.state.clients);
    this.setState({ filteredClients, search });
  };
  cancelSearch = () => {
    this.handleSearch('');
  };
  selectClient = id => {
    this.props.onSelectClient?.(id);
  };
  cancelAdd = () => {
    this.setState({ showAddClientModal: false });
  };
  addedClient = async newClient => {
    this.setState({ showAddClientModal: false });
    this.props.onNewClient?.(newClient);
    await this.props.onSelectClient?.(newClient);
  };

  render() {
    const { classes } = this.props;
    const { filteredClients } = this.state;
    return (
      <div className={classes.root}>
        <AddClientModal onClose={this.cancelAdd} onSave={this.addedClient} open={this.state.showAddClientModal} />
        <AddButton icon="addClient" label="New Client" onClick={this.handleNewClient} data-test-id="add-client-button" />
        <div className={classes.searchWrapper}>
          <SearchBar
            data-test-id="client-search"
            className={classes.searchPaper}
            classOverrides={{
              input: classes.searchInput,
            }}
            onChange={this.handleSearch}
            value={this.state.search}
            onCancelSearch={this.cancelSearch}
            onRequestSearch={() => log.debug('onRequestSearch')}
          />
        </div>
        <div className={classes.clients}>
          {filteredClients.length ? (
            <ClientList onClientSelect={this.selectClient} clients={filteredClients} />
          ) : (
            <div className={classes.noClients} data-test-id="client-selector-no-clients">
              No clients found.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ClientSelector);

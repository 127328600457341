import NiceModal, { useModal as useModalHook } from '@ebay/nice-modal-react';
import { keys } from 'lodash';
import { useCallback, useContext } from 'react';

export const useModal = ComponentOrId => {
  const modal = useModalHook(ComponentOrId);
  const modals = useContext(NiceModal.NiceModalContext);

  const update = useCallback(
    props => {
      modal.show({ ...modal.args, ...props });
    },
    [modal],
  );

  const hideAllModals = useCallback(() => {
    keys(modals).forEach(modalId => NiceModal.hide(modalId));
  }, [modals]);

  return {
    update,
    hideAllModals,
    ...modal,
  };
};

import React from 'react';
import { Controller } from 'react-hook-form';
import Input from 'components/v2/FormElements/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/* 
Notes:
- Component is mostly a copy of https://mui.com/material-ui/react-text-field/#system-InputAdornments.js
- About handleMouseDownPassword and handleMouseUpPassword:
 This function prevents the default behavior when the mouse button is pressed down on the password visibility toggle icon (IconButton). 
 In many browsers, pressing down on a button within a form input can inadvertently cause the input to lose focus. Preventing the default 
 behavior avoids this focus loss, ensuring that the cursor remains in the password field when toggling visibility. 
 */

const handleMouseDownPassword = event => {
  event.preventDefault();
};

const handleMouseUpPassword = event => {
  event.preventDefault();
};

const ResetPasswordInputRhf = ({ control, newPasswordName = 'newPassword', confirmPasswordName = 'confirmPassword' }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <>
      {/* New Password Input */}
      <Controller
        name={newPasswordName}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            label="New Password"
            name="new password"
            type={showPassword ? 'text' : 'password'}
            error={error?.message}
            fullWidth
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      />

      {/* Confirm Password Input */}
      <Controller
        name={confirmPasswordName}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Input
              {...field}
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              error={error?.message}
              fullWidth
              autoComplete="new-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        )}
      />
    </>
  );
};

export default ResetPasswordInputRhf;

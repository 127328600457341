import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import { Button, Typography } from '@mui/material';

import LightTextField from './LightTextField';

const styles = {
  colored: {
    color: '#fff',
  },
  form: {
    marginBottom: 20,
  },
};

class Registered extends Component {
  state = {
    email: '',
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onConfirm = async () => {
    const { onSubmit, onSuccess } = this.props;
    const { email } = this.state;
    if (onSubmit) {
      const response = await onSubmit(email);
      if (onSuccess && (response.status === 200 || response.status === 204)) {
        onSuccess();
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.form}>
          <Typography className={classes.colored}>
            You have successfully registered. Please check your e-mail and click on the validation link or enter your e-mail address to
            resend confirmation e-mail.
          </Typography>
          <LightTextField id="email" name="email" onChange={this.handleInputChange} label="E-mail Address" />
        </div>
        <Button variant="contained" color="secondary" onClick={this.onConfirm}>
          Resend activation e-mail
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(Registered);

import React, { useContext, useCallback } from 'react';
import { get } from 'lodash';
import withApi from 'common/hocs/withApi';

import { UserContext } from 'common/context/UserContext';

export const PASSWORD_SET = 'passwordSet';
export const TERMS_ACCEPTED = 'termsAccepted';
export const NEXT_STEPS_SEEN = 'nextStepsSeen';
export const OBJECTIVES_SUPPRESS = 'objectivesSuppress';
export const WELCOME_MESSAGE_SEEN = 'welcomeMessageSeen';
export const BUILD_ITINERARY = 'buildItinerary';

const OBJECTIVES = [
  {
    id: 'agencyCustomize',
    label: 'Customize your agency',
    isLead: true,
    location: '/my-agency',
    action: 'visit',
  },
  {
    id: 'teammatesInvite',
    label: 'Invite your teammates',
    isLead: true,
    location: '/agents',
    action: 'visit',
  },
  {
    id: 'clientsView',
    label: 'View your client data',
    location: '/clients',
    action: 'visit',
  },
  // @todo: this doesn't currently exist so we are disabling it for now
  // {
  //   id: 'view_tutorials',
  //   label: 'View itrvl tutorials',
  // },
  {
    id: 'buildItinerary',
    label: 'Build an itinerary',
    location: '/custom',
  },
  // {
  //   id: 'itinerary_saved_view',
  //   label: 'Quote a recommended itinerary',
  //   location: '/saved',
  // },
];

export const ObjectiveContext = React.createContext();

export const ObjectiveProvider = withApi(({ Api, children }) => {
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const has = useCallback(
    objective =>
      userContext.isMasquerading() || get(user, `objectives.${objective}`, get(user.agency, `objectives.${objective}`, false)) === true,
    [user, userContext],
  );
  const updateObjective = useCallback(
    async objective => {
      if (objective === TERMS_ACCEPTED) {
        await Api.updateAgency(user.agency.id, 'objectives', {
          ...get(user.agency, 'objectives', {}),
          [objective]: true,
        });
      } else {
        await Api.updateAgent(user.userId, 'objectives', {
          ...get(user, 'objectives', {}),
          [objective]: true,
        });
      }
      // @todo: we should be able to roll this user directly in instead of another re-fetch
      await userContext.refreshUser();
    },
    [Api, user, userContext],
  );
  const isLeadAgent = get(user, 'agency.admins', []).includes(user.userId);
  const validObjectives = OBJECTIVES.filter(objective => {
    return objective.isLead ? isLeadAgent : true;
  });
  const { remaining, objectives } = validObjectives.reduce(
    (acc, objective) => {
      let obj = { ...objective };
      if (has(objective.id)) {
        obj.completed = true;
        acc.remaining = acc.remaining - 1;
      }
      acc.objectives.push(obj);
      return acc;
    },
    { remaining: validObjectives.length, objectives: [] },
  );
  const suppressObjectives = () => updateObjective(OBJECTIVES_SUPPRESS);
  return (
    <ObjectiveContext.Provider value={{ updateObjective, objectives, remaining, has, suppressObjectives, isLeadAgent }}>
      {children}
    </ObjectiveContext.Provider>
  );
});

export const withObjectives = WrappedComponent => {
  return function ComponentWithObjectives(props) {
    return <ObjectiveContext.Consumer>{objectives => <WrappedComponent {...props} objectives={objectives} />}</ObjectiveContext.Consumer>;
  };
};

export const documentPresets = [
  { label: 'Detailed Quote Document', value: 'detailedQuoteDocument' },
  { label: 'Itinerary Document', value: 'itineraryDocument' },
  { label: 'Confirmation Document', value: 'confirmationDocument' },
  { label: 'Travel Document + Traveler Details', value: 'travelDocumentAndTravelerDetails' },
  { label: 'Travel Document', value: 'travelDocument' },
  { label: 'Supplier Document', value: 'supplierDocument' },
  { label: 'Custom', value: 'custom' },
];

export const documentPresetValues = {
  detailedQuoteDocument: {
    coverPage: true,
    welcomeNote: true,
    itineraryOverview: true,
    itineraryAtAGlance: true,
    travelerDetails: false,
    team: true,
    emergencyContact: false,
    importantNotes: true,
    flightDetails: true,
    flightSummary: true,
    detailedItinerary: true,
    accommodations: true,
    countryOverview: true,
    locationOverview: true,
  },
  itineraryDocument: {
    coverPage: true,
    welcomeNote: true,
    itineraryOverview: true,
    itineraryAtAGlance: true,
    travelerDetails: false,
    team: true,
    emergencyContact: false,
    importantNotes: true,
    flightDetails: true,
    flightSummary: true,
    detailedItinerary: true,
    accommodations: false,
    countryOverview: false,
    locationOverview: false,
  },
  confirmationDocument: {
    coverPage: true,
    welcomeNote: true,
    itineraryOverview: true,
    itineraryAtAGlance: true,
    travelerDetails: true,
    team: true,
    emergencyContact: false,
    importantNotes: true,
    flightDetails: true,
    flightSummary: true,
    detailedItinerary: true,
    accommodations: false,
    countryOverview: false,
    locationOverview: false,
  },
  travelDocumentAndTravelerDetails: {
    coverPage: true,
    welcomeNote: true,
    itineraryOverview: true,
    itineraryAtAGlance: true,
    travelerDetails: true,
    team: true,
    emergencyContact: true,
    importantNotes: true,
    flightDetails: true,
    flightSummary: true,
    detailedItinerary: true,
    accommodations: false,
    countryOverview: false,
    locationOverview: false,
  },
  travelDocument: {
    coverPage: true,
    welcomeNote: true,
    itineraryOverview: true,
    itineraryAtAGlance: true,
    travelerDetails: false,
    team: true,
    emergencyContact: true,
    importantNotes: true,
    flightDetails: true,
    flightSummary: true,
    detailedItinerary: true,
    accommodations: false,
    countryOverview: false,
    locationOverview: false,
  },
  supplierDocument: {
    coverPage: true,
    welcomeNote: false,
    itineraryOverview: true,
    itineraryAtAGlance: true,
    travelerDetails: true,
    team: false,
    emergencyContact: true,
    importantNotes: false,
    flightDetails: true,
    flightSummary: true,
    detailedItinerary: true,
    accommodations: false,
    countryOverview: false,
    locationOverview: false,
  },
};

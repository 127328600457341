import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';

import { cdnUrl } from 'common/helpers/content';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const useImgix = true;

// Thin wrapper around Imgix so we have a single
// place to change image handling should we
// decide to switch back to cloud front or another
// image provider with different API
export default class Image extends Component {
  render() {
    const { src, sizes, imgixParams, alt, loading, ...rest } = this.props;
    if (useImgix && src && typeof src == 'string' && src.includes('imgix.net')) {
      const htmlAttributes = {
        alt,
        loading,
      };
      // log.info('htmlAttributes for this image: ', htmlAttributes);
      return <Imgix src={src} sizes={sizes} imgixParams={imgixParams} htmlAttributes={htmlAttributes} {...rest} />;
    }
    if (src !== '' && typeof src == 'string') {
      let srcCdn = src;
      try {
        if (/^https.*cdn-static.*itrvl.*com/.test(src)) {
          // Leave as is, imgix only mirrors cdn-media
        } else if (/^https.*itrvl.*com/.test(src)) {
          srcCdn = cdnUrl(src);
        }
      } catch {
        // Things like '/static/media/...'.
      }
      return <img src={srcCdn} loading={loading} alt={alt} {...rest} />;
    }
    log.warn('image with invalid source:', src);
    return <img loading={loading} alt={alt} {...rest} />;
  }
}

Image.defaultProps = {
  sizes: '100vw',
  imgixParams: {
    fit: 'crop',
    crop: 'faces',
  },
  src: '',
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
};

import { format, isSameMonth, isSameYear, parse, parseISO } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

export const formatDateRange = (startDateString, endDateString, opts = {}) => {
  const { appendSameYear = false, appendSameMonth = true } = opts;
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  const startMonth = format(startDate, 'MMMM');
  const endMonth = format(endDate, 'MMMM');

  const startYear = format(startDate, 'yyyy');
  const endYear = format(endDate, 'yyyy');

  const includeMonth = !isSameMonth(startDate, endDate);
  const includeYear = !isSameYear(startDate, endDate);

  let result = `${startMonth} ${format(startDate, 'd')}`;

  if (includeYear) {
    result += `, ${startYear}`;
  }

  result += ` -`;

  if (includeMonth || appendSameMonth) {
    result += ` ${endMonth}`;
  }

  result += ` ${format(endDate, 'd')}`;

  if (includeYear || appendSameYear) {
    result += `, ${endYear}`;
  }

  return result;
};

export const parseDate = dateString => {
  return parse(dateString, 'yyyy-MM-dd', new Date());
};

export const parseTimeUTC = timeString => format(parseISO(timeString.slice(0, -1)), 'h:mmaaa');

export const formatTime = (time = '00:00') => format(new Date(`2022-02-22T${time}:00`), 'h:mmaaa');
export const formatTime24 = (time = '00:00') => format(new Date(`2022-02-22T${time}:00`), 'HH:mm');

export const formatUtcIsoStringToDateString = dateString => formatInTimeZone(parseISO(dateString), 'UTC', 'yyyy-MM-dd');
export const convertIsoDateStringToDateObject = dateString => utcToZonedTime(parseISO(dateString), 'UTC');

export default function getItineraryEndpoints(build) {
  return {
    getItinerary: build.query({
      query: id => {
        const filter = {
          include: ['video', 'client'],
        };
        return `/Itineraries/${id}?filter=${JSON.stringify(filter)}`;
      },
      providesTags: ['Itinerary'],
    }),
    patchItinerary: build.mutation({
      query: updateObj => ({
        url: `/Itineraries/${updateObj.id}`,
        method: 'PATCH',
        body: updateObj,
      }),
      invalidatesTags: ['Itinerary'],
    }),
    putItinerary: build.mutation({
      query: updateObj => ({
        url: `/Itineraries/${updateObj.id}`,
        method: 'PUT',
        body: updateObj,
      }),
      invalidatesTags: ['Itinerary'],
    }),
    getPoster: build.query({
      query: clientId => `/Itineraries/${clientId}/getPosterForItinerariesByClientId`,
    }),
    getFeaturedImagesForItinerary: build.query({
      query: itineraryId => `/Itineraries/getFeaturedImagesForItinerary/${itineraryId}`,
    }),
    assembleVideo: build.mutation({
      query: itineraryId => ({
        url: `/Itineraries/${itineraryId}/assembleVideo`,
        method: 'POST',
      }),
      invalidatesTags: ['Itinerary'],
    }),
  };
}

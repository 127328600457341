const Plus = () => (
  <svg viewBox="0 0 13 12" fill="none">
    <path
      d="M12.1875 5.75C12.1875 5.99609 11.9688 6.1875 11.75 6.1875H6.9375V11C6.9375 11.2461 6.71875 11.4648 6.5 11.4648C6.25391 11.4648 6.0625 11.2461 6.0625 11V6.1875H1.25C1.00391 6.1875 0.8125 5.99609 0.8125 5.77734C0.8125 5.53125 1.00391 5.3125 1.25 5.3125H6.0625V0.5C6.0625 0.28125 6.25391 0.0898438 6.5 0.0898438C6.71875 0.0898438 6.9375 0.28125 6.9375 0.5V5.3125H11.75C11.9688 5.3125 12.1875 5.53125 12.1875 5.75Z"
      fill="currentColor"
    />
  </svg>
);

export default Plus;

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { get } from 'lodash';

import { IconButton } from '@mui/material';
import DebouncedInput from './DebouncedInput';

import SearchIcon from '@mui/icons-material/Search';
import ItrvlIcon from 'common/components/Icons/ItrvlIcon';

const styles = {
  root: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: '#f2f2f2',
  },
  iconButton: {
    opacity: 0.54,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0,
    },
  },
  iconButtonDisabled: {
    opacity: 0.38,
  },
  searchIconButton: {
    marginRight: -48,
  },
  icon: {
    opacity: 0.54,
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  input: {
    width: '100%',
  },
  searchContainer: {
    margin: 'auto 16px',
    width: 'calc(100% - 48px - 32px)', // 48px button + 32px margin
  },
  outlined: {
    border: '1px solid #ECECEC',
  },
};

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/guidelines/patterns/search.html)
 */
class SearchBar extends Component {
  state = {
    focus: false,
    value: '',
    active: false,
  };

  handleFocus = e => {
    this.setState({ focus: true });
    if (this.props.onFocus) {
      e.persist();
      this.props.onFocus(e);
    }
  };

  handleBlur = e => {
    this.setState({ focus: false });
    if (this.state.value.trim().length === 0) {
      this.setState({ value: '' });
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleInput = e => {
    const value = get(e, 'target.value', '');
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
  };

  handleCancel = () => {
    this.setState({ active: false, value: '' });
    if (this.props.onCancelSearch) {
      this.props.onCancelSearch();
    }
  };

  handleKeyUp = e => {
    if (e.charCode === 13 || e.key === 'Enter') {
      this.handleRequestSearch();
    } else if (this.props.cancelOnEscape && (e.charCode === 27 || e.key === 'Escape')) {
      this.handleCancel();
    }
    if (this.props.onKeyUp) {
      e.persist();
      this.props.onKeyUp(e);
    }
  };

  handleRequestSearch = () => {
    if (this.props.onRequestSearch) {
      this.props.onRequestSearch(this.state.value);
    }
  };

  render() {
    const { value } = this.state;
    const {
      className,
      classes,
      closeIcon,
      disabled,
      searchIcon,
      style,
      classOverrides,
      variant,
      align,
      idPrefix,
      // eslint-disable-next-line no-unused-vars
      onCancelSearch,
      // eslint-disable-next-line no-unused-vars
      onRequestSearch,
      // eslint-disable-next-line no-unused-vars
      cancelOnEscape,
      ...inputProps
    } = this.props;

    return (
      <div className={clsx(classes.root, className, variant && classes[variant])} style={style}>
        {align === 'left' && (
          <>
            <IconButton
              id={`${idPrefix || 'searchbar'}-search-button`}
              onClick={this.handleRequestSearch}
              classes={{
                root: clsx(classes.iconButton, classes.searchIconButton, {
                  [classes.iconButtonHidden]: value !== '',
                }),
                disabled: classes.iconButtonDisabled,
              }}
              disabled={disabled}
              size="large"
            >
              {React.cloneElement(searchIcon, {
                classes: { root: classes.icon },
              })}
            </IconButton>
            <IconButton
              id={`${idPrefix || 'searchbar'}-search-clear`}
              onClick={this.handleCancel}
              classes={{
                root: clsx(classes.iconButton, {
                  [classes.iconButtonHidden]: value === '',
                }),
                disabled: classes.iconButtonDisabled,
              }}
              disabled={disabled}
              size="large"
            >
              {React.cloneElement(closeIcon, {
                classes: { root: classes.icon },
              })}
            </IconButton>
          </>
        )}
        <div className={classes.searchContainer}>
          <DebouncedInput
            disableUnderline
            {...inputProps}
            id={`${idPrefix || 'searchbar'}-search-input`}
            onBlur={this.handleBlur}
            value={value}
            onChange={this.handleInput}
            onKeyUp={this.handleKeyUp}
            onFocus={this.handleFocus}
            fullWidth
            className={clsx(classes.input, classOverrides && classOverrides.input)}
            disabled={disabled}
            data-test-id={'global-search-input'}
          />
        </div>
        {align === 'right' && (
          <>
            <IconButton
              id={`${idPrefix || 'searchbar'}-search-button`}
              onClick={this.handleRequestSearch}
              classes={{
                root: clsx(classes.iconButton, classes.searchIconButton, {
                  [classes.iconButtonHidden]: value !== '',
                }),
                disabled: classes.iconButtonDisabled,
              }}
              disabled={disabled}
              aria-label="search"
              size="large"
            >
              {React.cloneElement(searchIcon, {
                classes: { root: classes.icon },
              })}
            </IconButton>
            <IconButton
              id={`${idPrefix || 'searchbar'}-search-clear`}
              onClick={this.handleCancel}
              classes={{
                root: clsx(classes.iconButton, {
                  [classes.iconButtonHidden]: value === '',
                }),
                disabled: classes.iconButtonDisabled,
              }}
              disabled={disabled}
              aria-label="clear"
              size="large"
            >
              {React.cloneElement(closeIcon, {
                classes: { root: classes.icon },
              })}
            </IconButton>
          </>
        )}
      </div>
    );
  }
}

SearchBar.defaultProps = {
  className: '',
  closeIcon: <ItrvlIcon name="exit" style={{ color: '#434343' }} />,
  disabled: false,
  placeholder: 'Search',
  searchIcon: <SearchIcon style={{ color: '#434343' }} />,
  style: null,
  value: '',
  variant: false,
  align: 'right',
};

export default withStyles(styles)(SearchBar);

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import clsx from 'clsx';
import produce from 'immer';
import { size, reduce, toLower } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import { useBuilderStore } from './store';

// @todo: move me
const Plus = () => (
  <svg viewBox="0 0 13 12" fill="none">
    <path
      d="M12.1875 6.25C12.1875 6.63281 11.8867 6.90625 11.5312 6.90625H7.15625V11.2812C7.15625 11.6641 6.85547 11.9648 6.5 11.9648C6.11719 11.9648 5.84375 11.6641 5.84375 11.2812V6.90625H1.46875C1.08594 6.90625 0.8125 6.63281 0.8125 6.27734C0.8125 5.89453 1.08594 5.59375 1.46875 5.59375H5.84375V1.21875C5.84375 0.863281 6.11719 0.589844 6.5 0.589844C6.85547 0.589844 7.15625 0.863281 7.15625 1.21875V5.59375H11.5312C11.8867 5.59375 12.1875 5.89453 12.1875 6.25Z"
      fill="#221F20"
    />
  </svg>
);

// @todo: move me
const Minus = () => (
  <svg viewBox="0 0 13 12">
    <path
      d="M12.2,6.2c0,0.4-0.3,0.7-0.7,0.7H7.2H5.8H1.5c-0.4,0-0.7-0.3-0.7-0.6c0-0.4,0.3-0.7,0.7-0.7h4.4h1.3h4.4
	C11.9,5.6,12.2,5.9,12.2,6.2z"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  root: {
    width: 272,
    backgroundColor: '#fff',
    // boxShadow: theme.shadows[1],
  },
  cellPad: {
    height: 24,
  },
  icon: {
    width: 14,
    height: 'auto',
    flexShrink: 0,
  },
  group: {
    fontSize: 12,
    display: 'flex',
    gap: 15,
    margin: '0 24px',
    alignItems: 'center',
    padding: '12px 16px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color', 'color']),
    '&:hover': {
      // 60% "lighter" than selected color
      backgroundColor: '#EBF7FF',
    },
  },
  selected: {
    backgroundColor: '#525E6A',
    color: '#fff',
    '&:hover': {
      // 10% "lighter"
      backgroundColor: '#6C7884',
    },
  },
  countWrap: {
    width: 28,
    height: 28,
    marginLeft: 'auto',
    flexShrink: 0,
  },
  count: {
    width: '100%',
    height: '100%',
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FF6E00',
    color: '#000',
    borderRadius: '50%',
    // @todo: convert to rem
    fontSize: 14,
  },
  child: {
    paddingLeft: 44,
  },
  addAll: {
    paddingLeft: 15,
    '& svg': {
      fontSize: 16,
    },
  },
}));

export default function ListComponent() {
  const countries = useBuilderStore(state => state.ui.countries.list);
  const countryAllSelectMap = useBuilderStore(state => state.ui.countryAllSelectMap);

  const [g, setGroups] = useState([]);
  useEffect(() => {
    setGroups(countries);
  }, [countries, setGroups]);

  const filteredRegions = useBuilderStore(state => state.ui.regions.list);
  const groups = filteredRegions ? filteredRegions : g;

  const selectedMap = useBuilderStore(state => state.ui.countries.selectedMap);

  const siblingCountMap = useMemo(() => {
    return reduce(
      selectedMap,
      (acc, val) => {
        if (!(val.parentValue in acc)) {
          acc[val.parentValue] = 0;
        }
        acc[val.parentValue] = acc[val.parentValue] + 1;
        return acc;
      },
      {},
    );
  }, [selectedMap]);

  const setSelected = useBuilderStore(state => state.actions.countries.setSelected);
  const addAllRegions = useBuilderStore(state => state.actions.countries.addAllRegions);
  const removeAllCountryRegions = useBuilderStore(state => state.actions.countries.removeAllCountryRegions);

  const toggleGroup = useCallback(
    async index => {
      setGroups(groups => {
        return produce(groups, draft => {
          const target = draft[index];
          if (target.open) {
            target.open = false;
            draft.splice(index + 1, size(target.children));
          } else {
            target.open = true;
            if (size(target.children) > 0) {
              draft.splice(...[index + 1, 0, ...target.children]);
            }
          }
          return draft;
        });
      });
    },
    [setGroups],
  );

  const childClick = (_index, item) => {
    setSelected(item.value, item);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Virtuoso
        style={{ height: 400 }}
        data={groups}
        components={{
          Header: () => <div className={classes.cellPad} />,
          Footer: () => <div className={classes.cellPad} />,
        }}
        itemContent={index => {
          const group = groups[index];
          const isParent = size(group.children) > 0;
          const isAddAll = group.value === 'all-children';
          const condition = isAddAll && group.parentValue in countryAllSelectMap;
          const label = condition ? group.alternateLabel : group.label;
          return (
            <div
              role="button"
              aria-label={isParent ? toLower(`country ${group.label}`) : toLower(`region ${group.label}`)}
              className={clsx(
                classes.group,
                !isParent && classes.child,
                isAddAll && classes.addAll,
                group.value in selectedMap && classes.selected,
              )}
              onClick={() => {
                if (isParent) {
                  toggleGroup(index);
                } else {
                  if (isAddAll) {
                    condition ? removeAllCountryRegions(group.parentValue, group.values) : addAllRegions(group.parentValue, group.values);
                  } else {
                    childClick(index, group);
                  }
                }
              }}
            >
              {isAddAll && <div className={classes.icon}>{condition ? <IndeterminateCheckBoxIcon /> : <AddToPhotosIcon />}</div>}
              {isParent && <div className={classes.icon}>{group.open ? <Minus /> : <Plus />}</div>}
              {label}
              {isParent && (
                <div className={classes.countWrap}>
                  {group.value in siblingCountMap && siblingCountMap[group.value] > 0 && (
                    <div className={classes.count}>{siblingCountMap[group.value]}</div>
                  )}
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
}

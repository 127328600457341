import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import { useController } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import { mediaContentToVideoUrl, mediaContentToPosterUrl } from 'common/helpers/content';
import { InputLabel, CircularProgress, Button } from '@mui/material';
import { ucFirst } from 'utils/string';
import clsx from 'clsx';
import Image from 'common/components/Image';
import SingleFileUploadButton from './SingleFileUploadButton';
import VideoCall from '@mui/icons-material/VideoCall';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& video': {
      width: '100%',
    },
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    transform: 'translateY(1.5px) scale(0.75)',
    transformOrigin: 'center',
  },
  logoContainer: {
    width: '360px',
    height: '160px',
    padding: 20,
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.23);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:after': {
      content: '" "',
      display: 'block',
      paddingTop: '100%',
    },
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '& > video': {
      width: '80%',
      height: 'auto',
    },
  },
  featuredContainer: {
    width: '360px',
    height: '200px',
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.23);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:after': {
      content: '" "',
      display: 'block',
      paddingTop: '100%',
    },
    '& > img, & > video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '6px',
      backgroundColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  circularImage: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: '160px',
    height: '160px',
    padding: 6,
    '& > img': {
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },
  light: {
    backgroundColor: '#fff',
  },
  dark: {
    backgroundColor: '#000',
  },
  icon: {
    fontSize: '3rem',
  },
  iconLight: {
    color: '#000',
  },
  iconDark: {
    color: '#fff',
  },
  deleteButton: {},
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
  },
});

/* 
this uploader should be refactored to take in classes for styling
default is a rectangle image, but can be circular if isCircular is true
but parent has no control over the styling of the image > width, height, object-fit, etc
*/

const SingleFileUploader = forwardRef((props, ref) => {
  const {
    type = 'image',
    variant = 'light',
    url,
    label,
    name,
    control,
    onCancelPreview,
    onDeleteMedia,
    media,
    display,
    isCircular = false,
  } = props;

  const classes = useStyles();
  const { field } = useController({ name, control });
  const [loading, setLoading] = useState(false);
  const [temporaryFilePreview, setTemporaryFilePreview] = useState(null);
  const videoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    references: {
      video: videoRef.current,
    },
  }));

  useEffect(() => {
    if (field.value instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => setTemporaryFilePreview(reader.result);
      reader.readAsDataURL(field.value);
    } else {
      setTemporaryFilePreview(null);
    }
  }, [field.value]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 2;
    }
  }, [temporaryFilePreview, url, media]);

  const handleFilePreview = useCallback(
    files => {
      setLoading(true);
      field.onChange(files[0]);
      setLoading(false);
    },
    [field],
  );

  const handleDeleteClick = () => {
    temporaryFilePreview ? onCancelPreview() : onDeleteMedia();
  };

  const getAddFileIcon = () => {
    const Icon = type === 'video' ? VideoCall : AddPhotoAlternate;
    return <Icon className={clsx(classes.icon, classes[`icon${ucFirst(variant)}`])} />;
  };

  const getPreview = () => {
    if (media) {
      const videoUrl = mediaContentToVideoUrl(media);
      const imgUrl = mediaContentToPosterUrl(media);
      return type === 'video' ? (
        <video ref={videoRef} src={videoUrl} poster={imgUrl} controls crossOrigin="anonymous" data-test-id="video" />
      ) : typeof url === 'string' ? (
        <Image src={url} alt={label} data-test-id="image" />
      ) : null;
    }
    if (url && typeof url === 'string') {
      return type === 'video' ? <video ref={videoRef} src={url} controls crossOrigin="anonymous" /> : <Image src={url} alt={label} />;
    }
    return null;
  };

  const temporaryPreview =
    temporaryFilePreview && typeof temporaryFilePreview === 'string' ? (
      type === 'video' ? (
        <video ref={videoRef} src={temporaryFilePreview} controls crossOrigin="anonymous" alt={`Preview of ${label}`} />
      ) : (
        <Image src={temporaryFilePreview} alt={`Preview of ${label}`} />
      )
    ) : (
      getPreview()
    );

  const defaultAddFileIcon = getAddFileIcon();

  return (
    <div className={classes.root}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <div
        className={clsx(classes[display], classes[variant], classes[type], {
          [classes.circularImage]: isCircular,
        })}
        data-test-id={`${label}-wrapper`}
      >
        {loading && <CircularProgress classes={{ svg: classes[`icon${ucFirst(variant)}`] }} />}
        {!loading && temporaryPreview ? temporaryPreview : !loading && <div className={classes.iconContainer}>{defaultAddFileIcon}</div>}
      </div>

      <div className={classes.buttonContainer}>
        {temporaryFilePreview || url ? (
          <Button
            className={clsx(classes.deleteButton, 'edit')}
            variant="link"
            onClick={handleDeleteClick}
            data-test-id="delete-button"
            aria-label={`remove ${label}`}
          >
            {type === 'video' ? 'Remove Video' : 'Remove Photo'}
          </Button>
        ) : (
          !loading && <SingleFileUploadButton onFileDrop={handleFilePreview} type={type} label={label} />
        )}
      </div>
    </div>
  );
});

export default SingleFileUploader;

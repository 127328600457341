import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDebounce } from 'react-use';

import Input from './Input';
import SearchIcon from './icons/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useBuilderStore } from './store';

const useStyles = makeStyles(theme => ({
  root: {},
  clear: {
    cursor: 'pointer',
    transition: theme.transitions.create(['color']),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const Search = () => {
  const classes = useStyles();

  const setSearchTerm = useBuilderStore(state => state.actions.accommodations.setSearchTerm);
  const transientTerm = useBuilderStore(state => state.ui.accommodations.transientTerm);
  const setTransientTerm = useBuilderStore(state => state.actions.accommodations.setTransientTerm);
  const clearSearch = useBuilderStore(state => state.actions.accommodations.clearSearch);

  useDebounce(
    async () => {
      if (transientTerm !== undefined) {
        setSearchTerm(transientTerm);
      }
    },
    300,
    [transientTerm, setSearchTerm],
  );

  const handleChange = async event => {
    setTransientTerm(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Input
        value={transientTerm}
        placeholder="Search"
        label="search"
        adornment={
          transientTerm ? (
            <div
              className={classes.clear}
              onClick={() => {
                clearSearch();
                window.requestAnimationFrame(() => {
                  // @todo: this is gross, but we need to set a ref and pass it down otherwise
                  document.querySelector('[class^=routes-root]').scrollTo(0, 0);
                });
              }}
            >
              <CloseIcon role="button" aria-hidden="false" titleAccess="clear search" />
            </div>
          ) : (
            <SearchIcon />
          )
        }
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;

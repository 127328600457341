import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';

import { Button } from '@mui/material';
import InputSelector from '../ItineraryBuilder/InputSelector';
import Calendar from '../ItineraryBuilder/icons/Calendar';
import DatePicker from '../ItineraryBuilder/DatePicker';

const useStyles = makeStyles(theme => ({
  root: {
    '& input': {
      height: '100%',
      outline: 'none',
      padding: '10px 5px',
    },
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    alignSelf: 'flex-end',
    marginRight: 10,
    marginBottom: 10,
  },
}));

const DateSelector = ({ open = false, date, setDate, closeDatePicker, toggleDatePicker }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <InputSelector
        text={date ? format(date, 'LLLL d, y') : 'Select Date'}
        open={open}
        onClick={toggleDatePicker}
        onClickAway={closeDatePicker}
        adornment={<Calendar />}
        filled={date !== undefined}
        label="date picker"
      >
        <div className={classes.paper}>
          <DatePicker selected={date} onChange={setDate} />
          <Button className={classes.btn} onClick={toggleDatePicker}>
            Done
          </Button>
        </div>
      </InputSelector>
    </div>
  );
};

export default DateSelector;

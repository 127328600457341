import NiceModal from '@ebay/nice-modal-react';
import BaseModal from 'components/v2/Modals/BaseModal';
import { useBuilderStore } from './store';
import StayOverview from './StayOverview';
import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import SelectRoomModal from './SelectRoomModal';

const useStyles = makeStyles(theme => ({
  option: {
    border: '1px solid transparent',
    transition: theme.transitions.create('border'),
    cursor: 'pointer',
    '&.selected': {
      border: '1px solid #000',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));

export default NiceModal.create(function SelectStayModal({ supplierCode }) {
  const classes = useStyles();
  const stays = useBuilderStore(state => state.staysBySupplierCode(supplierCode));
  const [selectedId, setSelectedId] = useState(stays.length > 0 && stays[0].id);
  const campName = stays?.[0]?.campName;

  return (
    <BaseModal
      title={`Select a stay at ${campName}`}
      subtext="Choose the dates you'd like to select rooms for"
      maxWidth="lg"
      fullWidth
      onConfirm={() => {
        NiceModal.show(SelectRoomModal, { id: selectedId });
      }}
    >
      <h4>Select a stay</h4>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        {stays.map(stay => {
          const selected = selectedId === stay.id;
          return (
            <div
              key={stay.id}
              className={clsx(classes.option, selected && 'selected')}
              onClick={() => setSelectedId(stay.id)}
              role="button"
              aria-label={`Select ${stay.startDateString}`}
              data-test-id={`select-${stay.startDateString}`}
            >
              <StayOverview segment={stay} hideControls={true} />
            </div>
          );
        })}
      </div>
    </BaseModal>
  );
});

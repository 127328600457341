import React, { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { CircularProgress } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    paddingTop: 40,
    paddingBottom: 80,
  },
});

const Loader = forwardRef((props, ref) => {
  const { text = 'Loading' } = props;
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.root}>
      <CircularProgress color="primary" />
      {text}
    </div>
  );
});

export default Loader;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Button from './Button';
import { CircularProgress } from '@mui/material';
import { noop } from 'common';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    position: 'relative',
    '& span[class^=MuiButton-label]': {
      backgroundColor: 'inherit',
    },
  },
  progress: {
    color: '#fff',
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AsyncButton = props => {
  const classes = useStyles(props);
  const {
    loading,
    children,
    overlay = true,
    progressProps = { size: 16, className: classes.progress },
    onClick,
    loadingBg,
    ...rest
  } = props;

  return (
    <Button onClick={loading ? noop : onClick} {...rest} className={classes.root}>
      {overlay && (
        <>
          {loading && (
            <div className={classes.overlay} style={loadingBg ? { backgroundColor: loadingBg } : {}}>
              <CircularProgress {...progressProps} />
            </div>
          )}
          {children}
        </>
      )}
      {!overlay && <>{loading ? <CircularProgress {...progressProps} /> : children}</>}
    </Button>
  );
};

export default AsyncButton;

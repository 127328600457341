import React from 'react';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';

import BaseModal from 'common/components/Modals/BaseModal';

import ClientSelector from './ClientSelector';

const styles = {
  root: {
    padding: 0,
    width: '67%',
    margin: '0 auto',
    minWidth: 400,
  },
};

const ClientSelectorModal = ({ onSelectClient, clients, onNewClient, onClose, classes }) => (
  <BaseModal
    data-test-id="client-selector-modal"
    fullScreen={false}
    open={true}
    title="Select a Client"
    onClose={onClose}
    className={clsx(classes.root, 'custom_builder-client_selector_modal')}
  >
    <ClientSelector onSelectClient={onSelectClient} clients={clients} onNewClient={onNewClient} />
  </BaseModal>
);

export default withStyles(styles)(ClientSelectorModal);

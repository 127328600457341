import { size } from 'lodash';
import { adultsChildrenText } from './utils';
import { useBuilderStore } from './store';
import { Button, Paper } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import ChildIcon from 'components/v2/Icons/Child';
import UserIcon from 'components/v2/Icons/User';
import BedIcon from './icons/Bed';

import { useSegmentRemainingTravelers } from './hooks';
import SegmentDates from './SegmentDates';
import NiceModal from '@ebay/nice-modal-react';
import AddTravelersToRoomModal from './AddTravelersToRoomModal';
import ConfirmDenyModal from 'components/v2/Modals/ConfirmDenyModal';

const useStyles = makeStyles({
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  humanCount: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  icon: {
    width: 13,
    lineHeight: 0,
    color: '#000',
  },
  child: {
    width: 14,
  },

  //
  info: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 20,
    '& > div:nth-child(1)': {
      justifySelf: 'start',
    },
    '& > div:nth-child(2)': {
      justifySelf: 'center',
    },
    '& > div:nth-child(3)': {
      justifySelf: 'end',
    },
  },
  edit: {
    width: 20,
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
});

export default function StayOverview({ segment, hideControls = false }) {
  const { rooms, id } = segment;
  const classes = useStyles();
  const roomCount = size(rooms);
  const hasRooms = roomCount > 0;
  const removeRoom = useBuilderStore(state => state.actions.accommodations.removeRoom);
  const { adults, children } = useSegmentRemainingTravelers(id);
  const tripChildren = useBuilderStore(state => state.data.children);
  return (
    <Paper elevation={0} style={{ padding: 20, gap: 20, display: 'flex', flexDirection: 'column' }}>
      <div className={classes.info} data-test-id="stay-overview">
        <SegmentDates segment={segment} />
        {/*  */}
        <div className={classes.item}>
          <div className={classes.humanCount} data-test-id="unassigned-adults-count">
            {adults}
            <div className={clsx(classes.icon)}>
              <UserIcon />
            </div>
          </div>
          {tripChildren > 0 && (
            <div className={classes.humanCount} data-test-id="unassigned-child-count">
              {children}
              <div className={clsx(classes.icon, classes.child)}>
                <ChildIcon />
              </div>
            </div>
          )}
          Unassigned Travelers
        </div>
        {/*  */}
        <div className={classes.item}>
          <div className={classes.icon}>
            <BedIcon />
          </div>
          <div data-test-id="rooms-selected">
            {roomCount} {roomCount === 1 ? 'Room' : 'Rooms'} Selected
          </div>
        </div>
      </div>
      {hasRooms && (
        <>
          <div style={{ width: '100%', height: 1, backgroundColor: '#F7F4F0' }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {rooms.map(props => {
              const { adults, children, room, id } = props;
              return (
                <div
                  key={id}
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                  data-test-id={`selected-room-${room.name}`}
                >
                  <div>
                    <span style={{ fontWeight: 700 }} data-test-id="assigned-room-name">
                      {room.uniqueName}: {room.name}
                    </span>
                    , <span data-test-id="travelers-per-room-count">{adultsChildrenText(adults, children)}</span>
                  </div>
                  {!hideControls && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                      <Button
                        aria-label={`Edit ${room.uniqueName}: ${room.name}`}
                        variant="link"
                        onClick={() =>
                          NiceModal.show(AddTravelersToRoomModal, {
                            segmentId: segment.id,
                            room,
                            edit: props,
                            deleteRoom: () => removeRoom(segment.id, id),
                          })
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        aria-label={`Delete ${room.uniqueName}: ${room.name}`}
                        variant="link"
                        onClick={() =>
                          NiceModal.show(ConfirmDenyModal, {
                            title: 'Delete Room',
                            children: (
                              <div>
                                Are you sure you wish to delete the room:{' '}
                                <strong>
                                  {room.uniqueName}: {room.name}
                                </strong>
                                ?
                              </div>
                            ),
                            confirmedProps: {
                              color: 'error',
                              text: 'Delete',
                            },
                            onConfirm: modal => {
                              removeRoom(segment.id, id);
                              modal.hide();
                            },
                          })
                        }
                        color="error"
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </Paper>
  );
}

import React from 'react';
import withStyles from '@mui/styles/withStyles';

import { Typography } from '@mui/material';

const styles = {
  colored: {
    color: '#fff',
  },
};

const Forgotten = ({ classes }) => (
  <Typography className={classes.colored} style={{ textAlign: 'center' }}>
    If an account exists with the email you entered, you will receive a link to reset your password.
    <br />
    <br />
    Please click on the link in your e-mail to reset your password.
  </Typography>
);

export default withStyles(styles)(Forgotten);

import { useMutation } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import Client from 'models/Client';

export const useUpdateClient = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ clientId, client }) => {
      await Api.patchClient(clientId, client);

      const response = await Api.getClient(clientId);

      return new Client(response?.data);
    },
  });
  return mutation;
};

export const useCreateClient = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async userData => {
      const { firstName, lastName, email, phone, address, title } = userData;
      const response = await Api.createClientNext({
        firstName,
        lastName,
        email,
        title,
        contact: {
          phone,
          address,
        },
      });
      return response;
    },
  });
  return mutation;
};

export const usePatchClient = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ clientId, payload }) => {
      const response = await Api.patchClient(clientId, payload);
      return response;
    },
  });
  return mutation;
};

export const useClientReassignAgent = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ clientId, agentId }) => {
      const response = await Api.reassignAgent(clientId, agentId);
      return response;
    },
  });
  return mutation;
};

import logger from 'itrvl-logger';
import { SEGMENT_TYPE, ITINERARY_STATE } from 'itrvl-types';
import { boolean } from 'boolean';
import {
  pick,
  isNumber,
  isFinite,
  has,
  get,
  set,
  size,
  map,
  reduce,
  merge,
  cloneDeep,
  omit,
  values,
  isUndefined,
  forEach,
  toNumber,
} from 'lodash';
import Dinero from './dinero';
import { PAYMENT_MODEL } from './index';

import {
  getItineraryFlag,
  setItineraryFlag,
  hasItineraryFlag,
  deleteItineraryFlag,
  ITINERARY_FLAG,
  shouldOnlyHaveBalance,
} from 'itrvl-types';

import { getItineraryCurrencies } from './util';
import { rateLimits } from './rateLimits';

// Keys in margins the agent can manipulate
const marginKeys = ['land', 'air', 'dmc', 'custom'];
// Keys in rates agent can manipulate
const rateKeys = ['land', 'air', 'dmc', 'deposit', 'currency'];

export const DEFAULT_CURRENCY_RATE = rateLimits.currency.default;
export const DEFAULT_ITRVL_FEE_FOREIGN_SELL = rateLimits.foreignSell.default;
export const DEFAULT_ITRVL_FEE_CREDIT = rateLimits.credit.default;
const DEFAULT_ITRVL_FEE_ACH = rateLimits.ach.default;

const log = logger(__filename);

const invert = (rates, currency) => {
  return reduce(
    rates,
    (ret, val, key) => {
      set(ret, `${key}.${currency}`, 1 / val);
      set(ret, `${key}.${key}`, 1);
      return ret;
    },
    {},
  );
};

const buildExchangeRates = (tripCurrency, theMap) => {
  return merge({ [tripCurrency]: theMap }, { [tripCurrency]: { [tripCurrency]: 1 } }, invert(theMap, tripCurrency));
};

const buildRateMap = (itinerary, tripCurrency, key) => {
  const theMap = cloneDeep(get(itinerary, key, {}));
  return buildExchangeRates(tripCurrency, theMap);
};

const getSpotRates = (currencies, tripCurrency, spotRates) =>
  reduce(
    currencies,
    (ret, currency) => {
      const rate = get(spotRates, currency, -1);
      if (rate < 0) {
        throw new Error(`Missing spot rate for required currency: ${currency} ${JSON.stringify(spotRates)}`);
      }
      if (currency !== tripCurrency) {
        ret[currency] = rate;
      }
      return ret;
    },
    {},
  );

export const calculateRate = (rate, markup) => rate * (1 - markup / 100);
export const applyMarkup = (rates, exchangeRates, inverse) => {
  // inverse case is -markup
  return reduce(
    exchangeRates,
    (acc, exchangeRate, currency) => {
      // apply specific rate or fallback
      const markup = (rates?.currencies?.[currency] ?? rates.currency) * (inverse ? -1 : 1);
      acc[currency] = calculateRate(exchangeRate, markup);
      return acc;
    },
    {},
  );
};

/**
 *
 * @param {Object} dinero Dinero object containing the value to apply the rate too
 * @param {Number} rate rate to apply to dinero object
 * @returns
 */
const applyRateAmt = (dinero, rate) => {
  const ratePercent = rate / 100;
  return dinero.multiply(ratePercent).add(dinero);
};

export const applyRate = (unit, currency, rate) => {
  const ratePercent = rate / 100;
  const amount = Dinero({ unit, currency });
  return amount
    .divide(1 - ratePercent)
    .multiply(ratePercent)
    .add(amount);
};

const calculateCosts = ({ tripCurrency, exchange, pricing }) => {
  const costs = {};

  // We always update supplier costs
  const supplierCosts = Dinero.fromAccumulated(pricing.total.cost);
  set(costs, 'supplier', supplierCosts);
  // Ensure that we have a tripCost value available for summation in the UI
  const supplierCostInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.spot, pricing.total.cost);
  set(costs, 'supplierTotal', supplierCostInTripCurrency.getAmount());
  // Setup upfront costs
  const upfrontInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing.upfront.cost);
  set(costs, 'upfrontTotal', upfrontInTripCurrency.getAmount());

  // Extras are over and above this total becase?
  const totalCostInTripCurrency = supplierCostInTripCurrency.add(upfrontInTripCurrency);

  // Set the other costs
  set(costs, 'upfront', Dinero.fromAccumulated(pricing.upfront.cost));
  set(costs, 'total', totalCostInTripCurrency.getAmount());

  return costs;
};

const getDefaultFeeRates = ({ client, flags, tripCurrency }) => {
  // Are we adding extra escrow for foreign sell?
  const foreignSellFee =
    getItineraryFlag({ flags }, ITINERARY_FLAG.EXTRA_FOREIGN_SELL_MARKUP) && tripCurrency !== 'USD'
      ? Number(get(process.env, client ? 'REACT_APP_ITRVL_FEE_FOREIGN_SELL' : 'ITRVL_FEE_FOREIGN_SELL', DEFAULT_ITRVL_FEE_FOREIGN_SELL))
      : 0;
  // Okay these are the defaults for these
  return {
    credit: Number(get(process.env, client ? 'REACT_APP_ITRVL_FEE_CREDIT' : 'ITRVL_FEE_CREDIT', DEFAULT_ITRVL_FEE_CREDIT)) + foreignSellFee,
    ach: Number(get(process.env, client ? 'REACT_APP_ITRVL_FEE_ACH' : 'ITRVL_FEE_ACH', DEFAULT_ITRVL_FEE_ACH)) + foreignSellFee,
  };
};

const calculateRates = ({ tripCurrency, client, flags, published, rates, onlyBalance, paymentModel, currencies = [] }) => {
  // Because we added dmc rate we need to treat the default special
  if (get(rates, 'dmc', -1) < rateLimits.dmc.min) {
    // If this itinerary is already published and it didn't have a DMC rate then
    // we need to set it to the air rate because that's what used to be applied
    if (published) {
      set(rates, 'dmc', get(rates, 'air', rateLimits.dmc.default));
    } else {
      set(rates, 'dmc', rateLimits.dmc.default);
    }
  }

  // Setup default rates if we don't have any yet
  // and double check we aren't under the minimums
  forEach([...rateKeys, paymentModel === PAYMENT_MODEL.RACK && 'rack'].filter(Boolean), key => {
    if (get(rates, key, -1) == -1) {
      set(rates, key, rateLimits[key].default);
    }
    if (get(rates, key, -1) < rateLimits[key].min) {
      set(rates, key, rateLimits[key].min);
    }
  });

  // Now do the fee rates
  forEach(['ach', 'credit'], key => {
    // If we aren't published or we have no value then we can set it to the default
    if (!published || !get(rates, key)) {
      // Optimizing for the case where we don't need the defaults since that is the most common
      set(rates, key, getDefaultFeeRates({ tripCurrency, client, flags })[key]);
    }
  });

  return rates;
};

const getMarginTotals = object => values(object).reduce((acc, value) => acc + value, 0);

const calculateMargins = ({ tripCurrency, exchange, pricing, customPriceAdjustment, customPriceAdjustmentFee, paymentModel }) => {
  const margins = reduce(
    [...marginKeys, paymentModel === PAYMENT_MODEL.RACK && 'rack'].filter(Boolean),
    (ret, key) => {
      const sell = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing[key].sell);
      const cost = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.spot, pricing[key].cost);
      ret[key] = sell.subtract(cost).getAmount();
      log.debug('calculateMargins for ', key, sell.getAmount(), '-', cost.getAmount(), '=', ret[key]);
      return ret;
    },
    {},
  );
  let total = getMarginTotals(margins);
  if (customPriceAdjustment) {
    total += customPriceAdjustment.getAmount() - customPriceAdjustmentFee.getAmount();
  }

  margins.total = total;

  return margins;
};

/**
 * Convert value to a number or fall back to a defaultValue if the converted value isNaN
 * @param {Mixed} value value to be cast to a number
 * @param {Number} defaultValue number to fall back if coercion fails
 * @returns Number
 */
const getNumber = (value, defaultValue) => {
  const number = toNumber(value);
  return isNaN(number) ? defaultValue : value;
};

/**
 * Determine the recommended selling price for a given segment
 * @param {Object} segment
 * @returns Dinero Object
 */
export const getRSPAmount = segment =>
  Dinero({ amount: getNumber(segment?.costAmount, 0), currency: segment?.currency || 'USD' }).multiply(getNumber(segment?.racFactor, 1));

export const getRspInTripCurrency = (segment, itinerary) => {
  const rspAmount = get(segment, 'rspAmount', 0);
  const currency = get(itinerary, 'finance.currency', 'USD');
  const segmentCurrency = get(segment, 'currency', 'USD');
  return Dinero.exchangeSync(Dinero({ amount: rspAmount, currency: segmentCurrency }), currency, {
    [currency]: itinerary.exchange,
  });
};

// determine the customPriceAdjustment that should be added given an itinerary
// and the desired price target
export const determineCustomPriceAdjustment = (itinerary = {}, desiredPrice) => {
  if (!isNumber(desiredPrice)) {
    return 0;
  }

  desiredPrice = parseInt(desiredPrice, 10);

  const { finance = {} } = itinerary;
  const { sellingPrice, customPriceAdjustment } = finance;

  // we already have a customPriceAdjustment, now we need to figure out what the
  // difference is in order to preserve the delta
  if (customPriceAdjustment) {
    const originalSell = sellingPrice - customPriceAdjustment;
    return desiredPrice - originalSell;
  }

  return (
    desiredPrice - get(itinerary, 'margins.total', 0) - get(itinerary, 'costs.total', 0) - get(itinerary, 'finance.sell_fee_credit', 0)
  );
};

const getSupplierDeposits = ({ deposits, pricing }) => {
  const supplierDeposits = get(deposits, 'supplier', {});

  // Set costs as the supplier deposit if supplier didn't provide it
  if (size(supplierDeposits) === 0) {
    return pricing.total.cost;
  }

  return Dinero.toAccumulated(supplierDeposits);
};

const applyCustomPriceAdjustment = (sellInTripCurrency, customPriceAdjustment, customPriceAdjustmentFee) => {
  if (!customPriceAdjustment || !customPriceAdjustmentFee) {
    return sellInTripCurrency;
  }
  return Dinero({
    amount: sellInTripCurrency.getAmount() + customPriceAdjustment.getAmount() - customPriceAdjustmentFee.getAmount(),
    currency: sellInTripCurrency.getCurrency(),
  });
};

const applyCustomPriceAdjustmentFee = (sellFeeCredit, customPriceAdjustmentFee) =>
  customPriceAdjustmentFee
    ? Dinero({ amount: sellFeeCredit.getAmount() + customPriceAdjustmentFee.getAmount(), currency: sellFeeCredit.getCurrency() })
    : sellFeeCredit;

const calculateDeposits = ({
  deposits,
  rates,
  exchange,
  tripCurrency,
  pricing,
  onlyBalance,
  customPriceAdjustment,
  customPriceAdjustmentFee,
}) => {
  if (onlyBalance) {
    return {
      supplier: 0,
      supplierTotal: 0,
      agent: 0,
      agentTotal: 0,
      upfrontTotal: 0,
      total: 0,
    };
  }

  const supplier = getSupplierDeposits({ deposits, pricing });

  let sellInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing.total.sell);

  sellInTripCurrency = applyCustomPriceAdjustment(sellInTripCurrency, customPriceAdjustment, customPriceAdjustmentFee);

  // Costs are always calculated using the spotRates
  const totalCostInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.spot, pricing.total.cost, pricing.upfront.cost);
  const profitInTripCurrency = sellInTripCurrency.subtract(totalCostInTripCurrency);
  const supplierDepositTotal = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.spot, supplier);
  const upfrontInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing.upfront.cost);
  const agentDeposit =
    isNumber(rates.deposit) && isFinite(rates.deposit) && rates.deposit > 0
      ? profitInTripCurrency.multiply(rates.deposit / 100)
      : Dinero({ amount: 0, currency: tripCurrency });
  const agentDepositTotal = agentDeposit.add(upfrontInTripCurrency);
  const depositInTripCurrency = agentDepositTotal.add(supplierDepositTotal);

  return {
    supplier: Dinero.fromAccumulated(supplier),
    supplierTotal: supplierDepositTotal.getAmount(),
    agent: agentDeposit.getAmount(),
    agentTotal: agentDepositTotal.getAmount(),
    upfrontTotal: upfrontInTripCurrency.getAmount(),
    total: depositInTripCurrency.getAmount(),
  };
};

const calculateFinance = ({
  paid,
  serviceFee,
  exchange,
  rates,
  deposits,
  pricing,
  tripCurrency,
  depositOverride,
  customPriceAdjustment,
  customPriceAdjustmentFee,
}) => {
  let sellInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing.total.sell);
  let sellFeeCredit = sellInTripCurrency.percentage(rates.credit);

  // apply custom price adjusments if they exist
  sellInTripCurrency = applyCustomPriceAdjustment(sellInTripCurrency, customPriceAdjustment, customPriceAdjustmentFee);
  sellFeeCredit = applyCustomPriceAdjustmentFee(sellFeeCredit, customPriceAdjustmentFee);

  const sellFeeAch = sellInTripCurrency.percentage(rates.ach);

  let depositInTripCurrency = depositOverride || Dinero({ amount: get(deposits, 'total', 0), currency: tripCurrency });

  let depositFeeCredit = depositInTripCurrency.percentage(rates.credit);
  let depositFeeAch = depositInTripCurrency.percentage(rates.ach);

  // Balance is not done as percentages because of penny rounding errors
  let balanceInTripCurrency = sellInTripCurrency.subtract(depositInTripCurrency);
  let balanceFeeCredit = sellFeeCredit.subtract(depositFeeCredit);
  let balanceFeeAch = sellFeeAch.subtract(depositFeeAch);

  // Did they push the deposit percentage too high so that balance fee went negative?
  // In this case we just eliminate the balance all together and do everything in the deposit
  if (balanceFeeCredit.isNegative()) {
    depositInTripCurrency = sellInTripCurrency;
    depositFeeCredit = sellFeeCredit;
    depositFeeAch = sellFeeAch;
    balanceFeeCredit = balanceFeeAch = balanceInTripCurrency = Dinero({ amount: 0, currency: tripCurrency });
  }

  const expectedCreditFee = paid.percentage(rates.credit);
  const discount = expectedCreditFee.subtract(serviceFee);

  return {
    currency: tripCurrency,
    discount: discount.getAmount(),
    deposit: depositInTripCurrency.getAmount(),
    deposit_fee_credit: depositFeeCredit.getAmount(),
    deposit_fee_ach: depositFeeAch.getAmount(),
    sellingPrice: sellInTripCurrency.add(sellFeeCredit).getAmount(),
    sell: sellInTripCurrency.getAmount(),
    sell_fee_credit: sellFeeCredit.getAmount(),
    sell_fee_ach: sellFeeAch.getAmount(),
    balance: balanceInTripCurrency.getAmount(),
    balance_fee_credit: balanceFeeCredit.getAmount(),
    balance_fee_ach: balanceFeeAch.getAmount(),
    paid: paid.getAmount(),
    serviceFee: serviceFee.getAmount(),
    // these are just a pass-through as they will get unset as they are new keys
    ...(customPriceAdjustment && { customPriceAdjustment: customPriceAdjustment.getAmount() }),
    ...(customPriceAdjustmentFee && { customPriceAdjustmentFee: customPriceAdjustmentFee.getAmount() }),
  };
};

const priceSegments = ({ segments, rates, exchange, tripCurrency, sellOverride, paymentModel }) => {
  const toCurrency = (amount, currency) => {
    const ret = Dinero.exchangeSync(amount, currency, exchange.custom);
    return ret;
  };

  const [cost, addCost] = Dinero.withAccumulate();
  const [upfront, addUpfront] = Dinero.withAccumulate();
  const [totalSell, addSell] = Dinero.withAccumulate();

  const [customCost, addCustomCost] = Dinero.withAccumulate();
  const [customSell, addCustomSell] = Dinero.withAccumulate();

  const [landCost, addLandCost] = Dinero.withAccumulate();
  const [landSell, addLandSell] = Dinero.withAccumulate();

  const [airCost, addAirCost] = Dinero.withAccumulate();
  const [airSell, addAirSell] = Dinero.withAccumulate();

  const [dmcCost, addDmcCost] = Dinero.withAccumulate();
  const [dmcSell, addDmcSell] = Dinero.withAccumulate();

  const [rackCost, addRackCost] = Dinero.withAccumulate();
  const [rackSell, addRackSell] = Dinero.withAccumulate();

  const prices = {
    total: {
      cost,
      sell: totalSell,
    },
    upfront: {
      cost: upfront,
    },
    custom: {
      cost: customCost,
      sell: customSell,
    },
    land: {
      cost: landCost,
      sell: landSell,
    },
    air: {
      cost: airCost,
      sell: airSell,
    },
    dmc: {
      cost: dmcCost,
      sell: dmcSell,
    },
    rack: {
      cost: rackCost,
      sell: rackSell,
    },
  };

  if (segments) {
    forEach(segments, segment => {
      const currency = get(segment, 'currency', tripCurrency);

      const amount = Dinero({ unit: segment.cost, currency });

      // Arranged by segments solely impact sell price specified by the agent
      // and the upfrontCost for the booking and don't impact margins
      if (segment.arrangedBy === 'Agent' || segment.arrangedBy === '1') {
        const upfront = Dinero({ unit: segment.sell, currency: tripCurrency });
        addUpfront(upfront);
        addSell(upfront);
        segment.sellAmount = upfront.getAmount();
      } else {
        switch (segment.type) {
          case 'price':
          case 'service':
          case 'activity':
          case 'flight':
          case 'scheduled':
          case 'chartered':
          case 'road':
          case 'stay':
            // there are cases where costAmount is incorrect, let's set this so we can
            // use getRSPAmount properly, below
            segment.costAmount = amount.getAmount();
            const rsp = getRSPAmount(segment);
            let sell;
            if (!segment.sellOverride) {
              if (paymentModel === PAYMENT_MODEL.RACK) {
                sell = applyRateAmt(rsp, rates.rack);
                addRackCost(amount);
                addRackSell(sell);
              } else {
                switch (segment.type) {
                  case 'stay':
                  case SEGMENT_TYPE.SERVICE:
                    sell = applyRate(segment.cost, currency, rates.land);
                    addLandCost(amount);
                    addLandSell(sell);
                    break;
                  // DMC handling
                  case 'price':
                    sell = applyRate(segment.cost, currency, rates.dmc);
                    addDmcCost(amount);
                    addDmcSell(sell);
                    break;
                  default:
                    sell = applyRate(segment.cost, currency, rates.air);
                    addAirCost(amount);
                    addAirSell(sell);
                    break;
                }
              }
            } else {
              // sellOverride is always expressed in tripCurrency
              sell = Dinero({ unit: segment.sell, currency: tripCurrency });
              addCustomCost(amount);
              addCustomSell(sell);
            }
            addCost(amount);

            // set rsp on the segment if valid
            // @todo: i think this should live elsewhere on the UI and whatnot but that can come in a later story
            if (boolean(get(segment, 'chargeable')) === true && boolean(get(segment, 'priced')) === true) {
              segment.rspAmount = rsp.getAmount();
            } else {
              delete segment.rspAmount;
            }

            const sellInTripCurrency = toCurrency(sell, tripCurrency);
            addSell(sellInTripCurrency);
            segment.sell = sellInTripCurrency.toUnit();
            segment.sellAmount = sellInTripCurrency.getAmount();

            break;
          default:
            log.warn('Unknown segment type:', segment.type);
          // fall through
          case 'point':
          case 'entry':
          case 'exit':
            segment.sell = 0;
            segment.sellAmount = 0;
            break;
        }
      }
    });
  }

  // Do we have a sell override, which is really like a custom cost
  if (sellOverride) {
    const originalSell = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, prices.total.sell);

    const targetSell = sellOverride.divide((100 + rates.credit) / 100);
    const overrideAmount = targetSell.subtract(originalSell);

    addCustomSell(overrideAmount);
    addSell(overrideAmount);
  }

  return prices;
};

const buildExchange = ({ itinerary, currencies, rates, tripCurrency, flags, spotRates }) => {
  // Use itinerary spotRates if none are given
  if (!spotRates) {
    log.debug('Using Itinerary Spot Rates');
    spotRates = itinerary.spotRates;
  }

  const markup = rates.currency;

  // If there isn't a notion of last currency rate then add it
  if (!hasItineraryFlag({ flags }, ITINERARY_FLAG.LAST_CURRENCY_RATE)) {
    setItineraryFlag({ flags }, ITINERARY_FLAG.LAST_CURRENCY_RATE, markup);
  }

  // We given exchange rates to update with?
  if (spotRates) {
    // Are spotRates still allowed to float? If so then update them first
    if (!get(itinerary, 'published', false) || !get(itinerary, 'exchange')) {
      // Get new spot rates
      const itinerarySpotRates = getSpotRates(currencies, tripCurrency, spotRates);
      set(itinerary, 'spotRates', itinerarySpotRates);

      // Set exchange if we don't have them
      if (!get(itinerary, 'exchange')) {
        set(itinerary, 'exchange', applyMarkup(rates, itinerarySpotRates));
      }
    } else {
      // ITINERARY IS PUBLISHED!

      // If we have exchange set but not spotRates back into spot using the exchange and the markup
      // on the itinerary. This is safe because we do this on load, and there after we
      // will have spotRates, so this can only be done once.
      if (!get(itinerary, 'spotRates') && get(itinerary, 'exchange')) {
        const reversedSpotRates = applyMarkup(rates, get(itinerary, 'exchange'), true);
        set(itinerary, 'spotRates', reversedSpotRates);
      }

      // Check if any currencies have been added that we don't have an exchange for already
      // This occurs on itinerary edit if something gets added in a new country with a different exchange
      // In this case we snapshot the exchange as it was given.
      map(currencies, currency => {
        let ourRates;
        if (!has(itinerary.spotRates, currency)) {
          // Make sure we have a rate for this new currency
          if (!spotRates[currency]) {
            throw new Error(`Missing spot rate for new itinerary segment priced in: ${currency}`);
          }
          // Lazy calculate but cache marked up rates
          if (!ourRates) {
            ourRates = applyMarkup(rates, spotRates);
          }
          // Set the currency we are missing in the right place
          set(itinerary.exchange, currency, ourRates[currency]);
          set(itinerary.spotRates, currency, spotRates[currency]);
        }
      });
    }
  }

  // Sanity check: Validate we have all the rates we need or die trying
  currencies.map(currency => {
    if (!get(itinerary, `spotRates.${currency}`)) {
      if (!get(spotRates, currency)) {
        throw new Error(`Not given spot rates but we need one for: ${currency}: ${JSON.stringify(spotRates)}`);
      } else {
        set(itinerary, `spotRates.${currency}`, get(spotRates, currency));
      }
    }
  });

  // Recalculate exchange rates from our spotRates unless this one is published
  if (!get(itinerary, 'published', false)) {
    const ourRates = applyMarkup(rates, get(itinerary, 'spotRates'));
    set(itinerary, 'exchange', ourRates);
    setItineraryFlag({ flags }, ITINERARY_FLAG.LAST_CURRENCY_RATE, markup);
  } else if (getItineraryFlag({ flags }, ITINERARY_FLAG.LAST_CURRENCY_RATE) !== markup) {
    // Did they change the markup?
    const itineraryRates = getSpotRates(currencies, tripCurrency, spotRates);
    set(itinerary, 'spotRates', itineraryRates);
    set(itinerary, 'exchange', applyMarkup(rates, itineraryRates));
    setItineraryFlag({ flags }, ITINERARY_FLAG.LAST_CURRENCY_RATE, markup);
  }

  // Calculate forward and backward maps in an easy to use format
  const exchange = {
    custom: buildRateMap(itinerary, tripCurrency, 'exchange'),
    spot: buildRateMap(itinerary, tripCurrency, 'spotRates'),
  };

  return exchange;
};

export const findProblems = ({ margins, state, paymentModel }) => {
  const problems = {};

  // Raise a problem if the total margin is less than zero
  // Rack margin can come across as negative during the quoting phase, so let's suppress this
  if (
    (paymentModel !== PAYMENT_MODEL.RACK && margins.total < 0) ||
    (paymentModel === PAYMENT_MODEL.RACK && state !== ITINERARY_STATE.QUOTING && margins.total < 0)
  ) {
    set(problems, 'margins.total', 'The total margin is below zero.');
  }

  log.debug('Problems:', problems);

  return problems;
};

const saveChanges = (itinerary, fields) => {
  forEach(fields, (v, k) => {
    if (size(v) > 0) {
      log.debug('Set:', k);
      set(itinerary, k, v);
    } else {
      log.debug('Delete:', k);
      delete itinerary[k];
    }
  });
};

const recalculateDepositRate = ({
  rates,
  deposits,
  pricing,
  exchange,
  tripCurrency,
  depositOverride,
  customPriceAdjustment,
  customPriceAdjustmentFee,
}) => {
  // Back into the deposit rate
  const depositWithoutFee = depositOverride.divide((100 + rates.credit) / 100);
  const supplier = getSupplierDeposits({ deposits, pricing });

  let sellInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing.total.sell);
  sellInTripCurrency = applyCustomPriceAdjustment(sellInTripCurrency, customPriceAdjustment, customPriceAdjustmentFee);

  // Costs are always calculated using the spotRates
  const totalCostInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.spot, pricing.total.cost, pricing.upfront.cost);
  const profitInTripCurrency = sellInTripCurrency.subtract(totalCostInTripCurrency);
  const supplierDepositTotal = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.spot, supplier);
  const upfrontInTripCurrency = Dinero.reduceToSingleCurrencySync(tripCurrency, exchange.custom, pricing.upfront.cost);
  const agentDepositTotal = depositWithoutFee.subtract(supplierDepositTotal);
  const agentDeposit = agentDepositTotal.subtract(upfrontInTripCurrency);

  const depositRate = (agentDeposit.getAmount() / profitInTripCurrency.getAmount()) * 100;

  // Update the deposit rate
  log.debug('New Deposit Rate:', depositRate, !isFinite(depositRate), rates.deposit);
  return !isFinite(depositRate) ? (depositOverride.isZero() ? 0 : rates.deposit) : depositRate;
};

// @todo: clean up the arity here and just use options{} for most stuff
// @todo: depositOverride is only ever called in Itinerary.updatePayments: repriceItinerary\([^,]+,\s*[^,]+,\s*[^,]+,\s*[^,]+,\s*[^,)]+(,.*?)*.?
const repriceItinerary = (itinerary, spotRates, client = false, sellOverride, depositOverride, options = {}) => {
  const { paymentModel } = options;

  // @TODO: functions should not mutate inputs
  log.debug(
    'reprice:',
    pick(itinerary, ['name', 'id', 'finance', 'flags']),
    sellOverride ? sellOverride.toFormat() : sellOverride,
    depositOverride ? depositOverride.toFormat() : depositOverride,
  );

  // Only do balance if they have overridden the deposit to zero, or if the dates indicate we should only have a balance payment.
  const onlyBalance = shouldOnlyHaveBalance(itinerary, depositOverride);

  log.debug('Only Balance:', onlyBalance);

  const tripCurrency = get(itinerary, 'finance.currency', 'USD');

  // If sell is zero that will unlock the price, so let's avoid that by pretending we had a price
  if (!isUndefined(sellOverride) && get(itinerary, 'finance.sell', 0) === 0) {
    itinerary.finance.sell = 1;
  }

  // Are they moving above the current sell with this change? Then bump sell up first and then set deposit
  if (!isUndefined(depositOverride) && isUndefined(sellOverride) && depositOverride.getAmount() > get(itinerary, 'finance.sell', 0)) {
    sellOverride = depositOverride;
  }

  // Don't let the deposit move if they are just overriding the sell by overriding to the current deposit
  if (!isUndefined(sellOverride) && isUndefined(depositOverride)) {
    depositOverride = Dinero({
      amount: get(itinerary, 'finance.deposit', 0) + get(itinerary, 'finance.deposit_fee_credit', 0),
      currency: tripCurrency,
    });
  }

  // We need a copy here because we modify this as we go
  const flags = cloneDeep(get(itinerary, 'flags', {}));

  const currencies = getItineraryCurrencies(itinerary);
  const published = get(itinerary, 'published', false);

  let rates = get(itinerary, 'rates', {});
  rates = calculateRates({
    tripCurrency,
    client,
    published,
    flags,
    rates,
    onlyBalance,
    paymentModel,
    currencies,
  });

  // Figure out what exchange rates we are using
  const exchange = buildExchange({ itinerary, rates, currencies, tripCurrency, flags, spotRates });
  // Calculate costs and sell based on current rates
  const segments = get(itinerary, 'itinerary.segments');
  const pricing = priceSegments({ segments, flags, rates, exchange, tripCurrency, sellOverride, paymentModel });

  let finance = get(itinerary, 'finance');
  const originalFinance = cloneDeep(finance);

  let customPriceAdjustment;
  let customPriceAdjustmentFee;
  if (
    get(originalFinance, 'customPriceAdjustment') &&
    isNumber(originalFinance.customPriceAdjustment) &&
    originalFinance.customPriceAdjustment > 0
  ) {
    customPriceAdjustment = Dinero({ amount: originalFinance.customPriceAdjustment, currency: tripCurrency });
    customPriceAdjustmentFee = customPriceAdjustment.percentage(rates.credit);
  }

  let deposits = get(itinerary, 'deposits');

  const costs = calculateCosts({
    tripCurrency,
    exchange,
    pricing,
  });

  if (depositOverride) {
    rates.deposit = recalculateDepositRate({
      rates,
      deposits,
      pricing,
      exchange,
      tripCurrency,
      depositOverride,
      onlyBalance,
      customPriceAdjustment,
      customPriceAdjustmentFee,
    });
  }

  deposits = calculateDeposits({
    rates,
    deposits,
    tripCurrency,
    exchange,
    pricing,
    onlyBalance,
    customPriceAdjustment,
    customPriceAdjustmentFee,
  });

  const margins = calculateMargins({
    tripCurrency,
    exchange,
    pricing,
    customPriceAdjustment,
    customPriceAdjustmentFee,
    paymentModel,
  });

  // We need to carry these into the new finance block
  const paid = Dinero({ amount: get(itinerary, 'finance.paid', 0), currency: tripCurrency });
  const serviceFee = Dinero({ amount: get(itinerary, 'finance.serviceFee', 0), currency: tripCurrency });

  finance = calculateFinance({
    paid,
    serviceFee,
    tripCurrency,
    rates,
    deposits,
    exchange,
    pricing,
    depositOverride,
    customPriceAdjustment,
    customPriceAdjustmentFee,
  });

  // We dropped this so just in case it is still around remove it
  delete itinerary.marginCosts;

  const problems = findProblems({ margins, state: itinerary?.itinerary?.state, paymentModel });

  // Save all the updated information
  const changes = {
    rates,
    costs,
    flags,
    margins,
    deposits,
    problems,
    exchange: omit(exchange.custom[tripCurrency], tripCurrency),
    spotRates: omit(exchange.spot[tripCurrency], tripCurrency),
  };

  // If there are no more problems then make sure we clear the flag so that if new problems arrise
  // we send the notification
  if (size(problems) === 0) {
    log.debug('Delete problem send flag');
    deleteItineraryFlag(changes, ITINERARY_FLAG.PROBLEM_NOTIFY_SENT);
  }

  // Check for a rounding issue because floats are imprecise
  if (depositOverride) {
    log.debug(
      'CHECKING DEPOSIT ROUNDING:',
      'sellingPrice:',
      finance.sellingPrice,
      'deposit wanted:',
      depositOverride.getAmount(),
      'deposit:',
      finance.deposit,
      finance.deposit_fee_credit,
      '=',
      finance.deposit + finance.deposit_fee_credit,
      'balance',
      finance.balance,
      finance.balance_fee_credit,
      '=',
      finance.balance + finance.balance_fee_credit,
      'error:',
      depositOverride.getAmount() - (finance.deposit + finance.deposit_fee_credit),
    );
    const roundingErr = depositOverride.subtract(Dinero({ amount: finance.deposit_fee_credit + finance.deposit, currency: tripCurrency }));
    if (!roundingErr.isZero()) {
      log.debug('Applying depositOverride rounding error:', roundingErr.getAmount());
      finance.deposit += roundingErr.getAmount();
      finance.balance -= roundingErr.getAmount();
      deposits.total += roundingErr.getAmount();
      deposits.agent += roundingErr.getAmount();
      deposits.agentTotal += roundingErr.getAmount();
    }
  }

  if (sellOverride) {
    const roundingErr = sellOverride.subtract(Dinero({ amount: finance.sellingPrice, currency: tripCurrency }));
    if (!roundingErr.isZero()) {
      log.debug('Applying sellOverride rounding error:', roundingErr.getAmount());
      finance.sell += roundingErr.getAmount();
      finance.sellingPrice += roundingErr.getAmount();
      finance.balance += roundingErr.getAmount();
    }
  }

  // @todo: test these assumptions
  // if (sellOverride || (depositOverride && !options.canOverrideDeposit) /* || !locked*/) {
  changes.finance = finance;

  // If the price isn't locked or we don't have a basis yet then set the baseRates
  if (!has(itinerary, 'baseRates')) {
    changes.baseRates = rates;
  }

  saveChanges(itinerary, changes);

  log.debug('repriced:', itinerary.name, itinerary.finance, itinerary.problems);

  return itinerary;
};

const isDirectExchange = segment => segment.sellOverride || ['Agent', '1'].includes(segment.arrangedBy);

// Assumes called on client
export const changeCurrency = (itinerary, newCurrency, spotRates) => {
  // Changing selling currency allows spotPrices to change so mark it as
  // unpublished and restore publish state after repricing
  const oldCurrency = get(itinerary, 'finance.currency');
  const exchange = buildExchangeRates(newCurrency, cloneDeep(spotRates));

  // Because this is a new change of currency we can increase our escrow rate. See #1649
  setItineraryFlag(itinerary, ITINERARY_FLAG.EXTRA_FOREIGN_SELL_MARKUP);
  // const oldPublished = itinerary.published;
  // itinerary.published = false;
  set(itinerary, 'rates.credit', undefined);
  set(itinerary, 'rates.ach', undefined);

  // We need to reprice any overides in the new currency
  map(get(itinerary, 'itinerary.segments'), segment => {
    if (isDirectExchange(segment)) {
      segment.sell = Dinero.exchangeSync(Dinero({ unit: segment.sell, currency: oldCurrency }), newCurrency, exchange).toUnit();
    }
  });
  set(itinerary, 'finance.currency', newCurrency);
  delete itinerary.exchange;
  delete itinerary.spotRates;
  itinerary = repriceItinerary(itinerary, spotRates, true);
  // itinerary.published = oldPublished;
  return itinerary;
};

export const changeItinerarySell = (itinerary, sellOverride, client = true) => {
  return repriceItinerary(itinerary, undefined, client, sellOverride);
};

export const changeItineraryDeposit = (itinerary, depositOverride, client = true, options) => {
  // const repriceItinerary = (itinerary, spotRates, client = false, sellOverride, depositOverride, options = {}) => {
  return repriceItinerary(itinerary, undefined, client, undefined, depositOverride, options);
};

export default repriceItinerary;

import { StyledEngineProvider } from '@mui/material/styles';
import ThemeProvider from './ThemeProvider';

import Verify from './components/Verify';
import Verified from './components/Verified';
import Activate from './components/Activate';
import ResetPassword from './components/ResetPassword';
import Register from './components/Register';
import Registered from './components/Registered';
import Forgotten from './components/Forgotten';
import LoggedOut from './components/LoggedOut';

import { compose } from './utils/compose';
import { getFormattedDateDiffText } from './utils/dates';

import ConfirmModal from './components/Modals/ConfirmModal';
import ProgressModal from './components/Modals/ProgressModal';

export {
  ThemeProvider,
  StyledEngineProvider,
  compose,
  getFormattedDateDiffText,
  Activate,
  Verify,
  Verified,
  ResetPassword,
  Register,
  Registered,
  ConfirmModal,
  ProgressModal,
  Forgotten,
  LoggedOut,
};

export { default as ItrvlIcon } from './components/Icons/ItrvlIcon';
export { default as ItrvlIcon2 } from './components/Icons/ItrvlIcon2';

export { ApiContext } from './context/ApiContext';
export { ItinerariesContext } from './context/ItinerariesContext';
export { LodgeMappingContext } from './context/LodgeMappingContext';
export { ThemeContext } from './context/ThemeContext';
export { UserContext } from './context/UserContext';

export { heroForItineraryFromLodgeList } from 'common/helpers/content';
export { tripMeta } from 'common/helpers/trip';

export { connectContext } from 'common/utils/context';
export const noop = () => {};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Chip } from '@mui/material';
import ChildXIcon from '../ItineraryBuilder/icons/ChildX';

import { useBuilderStore } from './store';
import { getMinChildAge } from 'itrvl-types';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

const useStyles = makeStyles(
  theme => ({
    root: {
      color: '#fff',
      fontSize: 14,
      width: 330,
      '& header': {
        fontSize: 12,
        marginBottom: 12,
      },
      '& footer': {
        marginTop: 12,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    rooms: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      '& > div': {
        borderBottom: '1px solid #4E4C4D',
        paddingBottom: 12,
      },
      '& > :last-child': {
        border: 0,
        paddingBottom: 0,
      },
    },
    room: {
      display: 'flex',
      alignItems: 'center',
      gap: 13,
    },
    chip: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '& span': {
        lineHeight: 1,
        width: 40,
        textAlign: 'center',
      },
    },
    unavailable: {
      backgroundColor: '#4E4C4D',
    },
    noAvail: {
      padding: '3px 6px',
      backgroundColor: '#4E4C4D',
      display: 'inline-block',
    },
    price: {
      marginLeft: 'auto',
    },
    onRequest: {
      color: 'rgba(255, 255, 255, 0.6)',
      fontSize: 12,
    },
    glyph: {
      width: 25,
      height: 'auto',
      flexShrink: 0,
      marginRight: 10,
    },
  }),
  {
    name: 'RoomAvailability',
  },
);

const RoomAvailability = props => {
  const classes = useStyles();
  const children = useBuilderStore(state => state.data.children);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);
  const paxMinChildAge = getMinChildAge(children, childrenAges);

  const {
    rooms = [],
    showCount = true,
    showPrice = true,
    showMayBeAvailable = false,
    minChildAge,
    configUnavailable,
    nolongerAvailable,
  } = props;
  const areChildrenTooYoungForRooms =
    paxMinChildAge !== undefined && minChildAge !== undefined && paxMinChildAge < minChildAge ? true : false;

  let headerText = showMayBeAvailable ? 'Rooms may be available' : 'Rooms available';
  if (configUnavailable) {
    headerText = 'Insufficient rooms for the selected number of guests';
  }
  if (nolongerAvailable) {
    headerText = 'Original room selection is no longer available';
  }
  return (
    <div data-test-id="ib2-room-availability" className={classes.root}>
      <header>{headerText}</header>
      <div className={classes.rooms}>
        {rooms.map(room => {
          const { available, price } = room;
          let { label } = room;
          if (label === '_Double') label = 'Double';
          const onRequest = available === -2;
          const mayBeAvailable = available === -1;
          const unavailable = available === 0;
          return (
            <div className={classes.room} data-test-id={`system-type-${label}`} key={room.key}>
              {showCount && !onRequest && !mayBeAvailable && (
                <Chip
                  data-test-id={`system-type-${label}-availability`}
                  className={clsx(classes.chip, unavailable && classes.unavailable)}
                  label={available}
                />
              )}
              <div>
                <div>{label}</div>
                {mayBeAvailable && (
                  <div data-test-id={`system-type-${label}-availability`} className={classes.onRequest}>
                    May be available
                  </div>
                )}
                {onRequest && (
                  <div data-test-id={`system-type-${label}-availability`} className={classes.onRequest}>
                    Availability on request
                  </div>
                )}
              </div>
              {/* @todo: what if no price */}
              <div className={classes.price} data-test-id={`system-type-${label}-price`}>
                {showPrice ? (
                  unavailable ? (
                    <div className={classes.noAvail}>No Rooms Available</div>
                  ) : price === 0 ? (
                    <div className={classes.noAvail}>No pricing available</div>
                  ) : (
                    <>From {price}</>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </div>
      {areChildrenTooYoungForRooms ? (
        <>
          <hr />
          <footer>
            <div className={classes.glyph}>
              <ChildXIcon />
            </div>
            <div>{`Children permitted ${minChildAge} years and up`}</div>
          </footer>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RoomAvailability;

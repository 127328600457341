import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Tooltip from 'components/v2/Tooltip';

import ChildXIcon from './icons/ChildX';
import { useBuilderStore } from './store';
import { getMinChildAge } from 'itrvl-types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  glyph: {
    width: 25,
    height: 'auto',
    flexShrink: 0,
  },
});

const ChildRestrictions = props => {
  const { item } = props;

  const classes = useStyles();
  const children = useBuilderStore(state => state.data.children);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);
  const minChildAge = getMinChildAge(children, childrenAges);

  if (item.minChildAge === undefined || item.minChildAge === 0 || minChildAge === undefined) return null;
  if (minChildAge && minChildAge >= item.minChildAge) return null;
  return (
    <Tooltip interactive placement="bottom" title={`Children permitted ${item.minChildAge} years and up`}>
      <div className={classes.glyph} data-test-id="child-age-restriction">
        <ChildXIcon />
      </div>
    </Tooltip>
  );
};

export default ChildRestrictions;

import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import BaseModal from './BaseModal';

export default NiceModal.create(function ConfirmDenyModal(props) {
  const { children, ...rest } = props;
  return (
    <BaseModal labelledById="confirm-modal" {...rest}>
      {children}
    </BaseModal>
  );
});

import React from 'react';

const Bars = () => (
  <svg viewBox="0 0 14 12">
    <path
      d="M0.4375 1.21875C0.4375 0.984375 0.642578 0.75 0.90625 0.75H13.0938C13.3281 0.75 13.5625 0.984375 13.5625 1.21875C13.5625 1.48242 13.3281 1.6875 13.0938 1.6875H0.90625C0.642578 1.6875 0.4375 1.48242 0.4375 1.21875ZM0.4375 5.90625C0.4375 5.67188 0.642578 5.4375 0.90625 5.4375H13.0938C13.3281 5.4375 13.5625 5.67188 13.5625 5.90625C13.5625 6.16992 13.3281 6.375 13.0938 6.375H0.90625C0.642578 6.375 0.4375 6.16992 0.4375 5.90625ZM13.0938 11.0625H0.90625C0.642578 11.0625 0.4375 10.8574 0.4375 10.5938C0.4375 10.3594 0.642578 10.125 0.90625 10.125H13.0938C13.3281 10.125 13.5625 10.3594 13.5625 10.5938C13.5625 10.8574 13.3281 11.0625 13.0938 11.0625Z"
      fill="currentColor"
    />
  </svg>
);

export default Bars;

const Heart = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.96484 2.625L9.38672 2.23828C10.5469 1.07812 12.1992 0.550781 13.7812 0.832031C16.207 1.21875 18 3.32812 18 5.78906V5.96484C18 7.44141 17.3672 8.84766 16.3125 9.83203L9.94922 15.7734C9.70312 16.0195 9.35156 16.125 9 16.125C8.61328 16.125 8.26172 16.0195 8.01562 15.7734L1.65234 9.83203C0.597656 8.84766 0 7.44141 0 5.96484V5.78906C0 3.32812 1.75781 1.21875 4.18359 0.832031C5.76562 0.550781 7.41797 1.07812 8.57812 2.23828L8.96484 2.625ZM8.96484 4.24219L7.76953 3.01172C6.89062 2.13281 5.625 1.71094 4.35938 1.92188C2.49609 2.23828 1.08984 3.85547 1.08984 5.78906V5.96484C1.08984 7.125 1.58203 8.21484 2.42578 8.98828L8.78906 14.9297C8.82422 15 8.89453 15 8.96484 15C9.07031 15 9.14062 15 9.17578 14.9297L15.5391 8.98828C16.3828 8.21484 16.875 7.125 16.875 5.96484V5.78906C16.875 3.85547 15.4688 2.23828 13.6055 1.92188C12.3398 1.71094 11.0742 2.13281 10.1953 3.01172L8.96484 4.24219Z"
      fill="white"
    />
  </svg>
);

export default Heart;

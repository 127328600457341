import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

// Keys
export const agencyAgentsKey = agentId => ['agent', agentId, 'details', 'withAgency'];

export const useGetAgentDetailsQuery = agentId => {
  const Api = useApi();

  return useQuery({
    queryKey: agencyAgentsKey(agentId),
    queryFn: async () => {
      if (!agentId) throw new Error('Agent ID is required');

      // Fetch agent details
      const agentResponse = await Api.getAgentDetails(agentId);
      const { data: agent } = agentResponse;
      if (!agent.agencyId) throw new Error('Agency ID is missing from agent data');

      // Fetch agency details
      const agencyResponse = await Api.getAgency(agent.agencyId);
      const { data: agency } = agencyResponse;

      return {
        agency,
        ...agent,
      };
    },
    enabled: !!agentId, // Only fetch if agentId is provided
    retry: (_failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return true;
    },
  });
};

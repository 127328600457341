import React from 'react';

import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  vh: {
    height: '100vh',
  },
  fill: {
    height: '100%',
    width: '100%',
  },
  black: {
    backgroundColor: 'black',
    color: 'white',
  },
  white: {
    backgroundColor: 'white',
    color: 'black',
  },
  center: {
    width: '50%',
    textAlign: 'center',
  },
};

const Loading = ({ classes, message = 'Loading itrvl...', white, fill }) => {
  return (
    <div
      className={clsx(white ? classes.white : classes.black, fill ? classes.fill : classes.vh, classes.root)}
      data-test-id="loading-screen"
    >
      <div className={classes.center}>
        <h1>{message}</h1>
        <LinearProgress />
      </div>
    </div>
  );
};

export default withStyles(styles)(Loading);

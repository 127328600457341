import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { keyBy } from 'lodash';

export const useCampsInfoMapQuery = ({ instance, enabled }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-info-map', instance?.id],
    queryFn: async () => {
      const accommodations = instance.accommodationsFromSegments;
      const response = await Api.getCamp({
        where: {
          supplierCode: {
            in: (instance?.itinerary?.params?.accommodations || accommodations).map(accommodation => accommodation.supplierCode),
          },
        },
        fields: ['supplierCode', 'campName', 'regionName', 'campInfo', 'country', 'featuredImageId', 'dmcArrangedOnly'],
        include: {
          relation: 'featuredImage',
          scope: {
            fields: ['s3Key'],
          },
        },
      });
      return keyBy(response?.data || [], 'supplierCode');
    },
    staleTime: Number.Infinity,
    enabled,
  });
  return query;
};

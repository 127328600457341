import React from 'react';
import Tooltip from 'components/v2/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';

import ItrvlIcon from 'common/components/Icons/ItrvlIcon';

import { useBuilderStore } from './store';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 10,
  },
  fillIcon: {
    '& path': {
      fill: '#000',
    },
  },
});

const CampFilters = () => {
  const classes = useStyles();
  const onlineMode = useBuilderStore(state => state.ui.onlineMode);
  const setOnlineMode = useBuilderStore(state => state.actions.accommodations.setOnlineMode);
  const restOfWorldMode = useBuilderStore(state => state.ui.restOfWorldMode);
  const setRestOfWorldMode = useBuilderStore(state => state.actions.accommodations.setRestOfWorldMode);

  let debugButtons;
  if (process.env.REACT_APP_DEBUG_IB2) {
    debugButtons = (
      <>
        <Tooltip title={!restOfWorldMode ? `Show Rest of World` : `Hide Rest of World`} placement="bottom">
          <ToggleButton
            key="africa"
            value="africa"
            aria-label="africa"
            selected={restOfWorldMode}
            className={classes.fillIcon}
            onChange={() => setRestOfWorldMode(!restOfWorldMode)}
          >
            <ItrvlIcon className={classes.fillIcon} name="world"></ItrvlIcon>
          </ToggleButton>
        </Tooltip>
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Tooltip title={!onlineMode ? `Show Live Availability Only` : `Show All Accommodations`} placement="bottom">
        <ToggleButton key="online" value="online" aria-label="online" selected={onlineMode} onChange={() => setOnlineMode(!onlineMode)}>
          <ItrvlIcon name="live" className={classes.fillIcon}></ItrvlIcon>
        </ToggleButton>
      </Tooltip>
      {debugButtons}
    </div>
  );
};

export default CampFilters;

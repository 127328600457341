export const ITINERARY_VIDEO_STATUS = {
  // Job is queued
  PENDING: 'PENDING',
  // Job is running
  RUNNING: 'RUNNING',
  // Job is complete
  COMPLETE: 'COMPLETE',
  // Job had an error
  ERROR: 'ERROR',
  // No status yet
  NONE: 'NONE',
};

export const getVideoProgressText = (videoStatus, videoProgress) => {
  let progressText = videoProgress === 100 ? 'Video Generation Complete (100%)' : `Video Generation in progress (${videoProgress}%)`;
  if (videoStatus === ITINERARY_VIDEO_STATUS.ERROR) {
    progressText = 'Error generating video';
  }
  return progressText;
};
export const getVideoProgressColorClass = (videoStatus, videoProgress) => {
  let progressColorClass = videoProgress === 100 ? `Complete` : `Incomplete`;
  if (videoStatus === ITINERARY_VIDEO_STATUS.ERROR) {
    progressColorClass = `Error`;
  }
  return progressColorClass;
};

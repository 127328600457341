import React from 'react';

import Dialog from '@mui/material/Dialog';

import { LinearProgress, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ProgressModal = ({ open, title, text }) => (
  <Dialog aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} disableEnforceFocus>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
    </DialogContent>
    <LinearProgress />
  </Dialog>
);

export default ProgressModal;

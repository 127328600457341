import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { groupBy, orderBy, size, toLower, uniqBy } from 'lodash';

import Badge from '@mui/material/Badge';
import Tooltip from 'components/v2/Tooltip';

import ServiceBellIcon from './icons/ServiceBell';
import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import { useCampFeatures } from './hooks';
import { Fade } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 20,
  },
  countIcon: {
    '& svg': {
      width: 25,
      height: 'auto',
      cursor: 'pointer',
    },
  },

  itemList: {
    maxHeight: 200,
    overflowY: 'auto',
    padding: 10,
  },
});

const CampFeatures = ({ campFeatures = [] }) => {
  const classes = useStyles();
  const { isLoading, features } = useCampFeatures(campFeatures);
  const groupedFeatures = groupBy(orderBy(uniqBy(features, 'featureId'), ['featureName']), o => toLower(o?.featureType));
  const amenities = groupedFeatures.amenity;
  const activities = groupedFeatures.activity;
  if (isLoading) return null;
  return (
    <Fade in={!isLoading}>
      <div className={classes.root}>
        {size(amenities) > 0 && (
          <div className={classes.countIcon} data-test-id="amenities-icon">
            <Tooltip
              interactive
              placement="bottom"
              title={
                <div className={classes.itemList} data-test-id="amenities-list">
                  {amenities.map(({ featureId, featureName }) => (
                    <div key={featureId}>{featureName}</div>
                  ))}
                </div>
              }
            >
              <Badge badgeContent={size(amenities)} color="secondary" data-test-id="amenities-badge-count">
                <ServiceBellIcon />
              </Badge>
            </Tooltip>
          </div>
        )}
        {size(activities) > 0 && (
          <div className={classes.countIcon} data-test-id="activities-icon">
            <Tooltip
              interactive
              placement="bottom"
              title={
                <div className={classes.itemList} data-test-id="activities-list">
                  {activities.map(({ featureId, featureName }) => (
                    <div key={featureId}>{featureName}</div>
                  ))}
                </div>
              }
            >
              <Badge badgeContent={size(activities)} color="secondary">
                <ItrvlIcon name="hike" data-test-id="activities-badge-count" />
              </Badge>
            </Tooltip>
          </div>
        )}
      </div>
    </Fade>
  );
};

export default CampFeatures;

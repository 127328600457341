import React from 'react';

const Support = () => (
  <svg viewBox="0 0 496.2 504">
    <path
      fill="currentColor"
      d="M480.9 349c2 0 4-.4 5.9-1.2 5.7-2.4 9.4-7.9 9.4-14.1V104c0-16.3-13.2-29.5-29.5-29.5H228.2c-16.3 0-29.5 13.2-29.5 29.5v170.9c0 16.3 13.2 29.5 29.5 29.5h201.7l40.2 40.2c2.9 2.9 6.8 4.4 10.8 4.4zm-50.5-61.2H228.2c-7.2 0-13-5.8-13-13V103.9c0-7.2 5.8-13 13-13h238.4c7.2 0 13 5.8 13 13v226.7l-38.4-38.4c-2.8-2.8-6.7-4.3-10.8-4.4z"
    />
    <path
      fill="currentColor"
      d="M33.3 16.5h238.4c7.1 0 13 5.8 13 13v20.1h16.5V29.5C301.2 13.2 288 0 271.7 0H33.3C17 0 3.8 13.2 3.8 29.5v229.7c0 6.2 3.7 11.7 9.4 14.1 1.9.8 3.9 1.2 5.9 1.2 3.8 0 7.4-1.4 10.3-4l45.7-34.7.4-.3 98.4-.1v-16.5H75c-3.8 0-7.5 1.4-10.3 4.1l-44.4 33.7V29.6c0-7.2 5.8-13.1 13-13.1zM45 356.3c0 27.9 22.7 50.6 50.6 50.6s50.6-22.7 50.6-50.6c0-27.9-22.7-50.6-50.6-50.6-27.9.1-50.6 22.7-50.6 50.6zm84.6 0c0 18.8-15.3 34-34 34s-34-15.3-34-34 15.3-34 34-34 34 15.3 34 34zM35.2 442.4c1.7-4.8 6.2-8 11.2-8h100c5.1 0 9.6 3.2 11.3 8l17.4 50.6h15.7c.6-1.7.6-3.5 0-5.3L173.4 437c-3.9-11.5-14.8-19.2-26.9-19.2h-100c-12.1 0-22.8 7.6-26.8 19L.4 491.5c-.2.5-.3 1.1-.4 1.7h17.3l17.9-50.8zM348.3 387c0 27.9 22.7 50.6 50.6 50.6s50.6-22.7 50.6-50.6c0-27.9-22.7-50.6-50.6-50.6-27.9.1-50.6 22.7-50.6 50.6zm84.6 0c0 18.8-15.3 34-34 34-18.8 0-34-15.3-34-34s15.3-34 34-34c18.8 0 34 15.3 34 34zM338.5 473.1c1.7-4.8 6.2-8 11.2-8h100c5.1 0 9.6 3.2 11.3 8l10.6 30.8h17.5l-12.4-36.2c-3.9-11.5-14.8-19.2-26.9-19.2h-100c-12.1 0-22.8 7.6-26.8 19L310.2 504h17.5l10.8-30.9zM295.1 191.9c0 7.9-6.4 14.4-14.4 14.4-7.9 0-14.4-6.4-14.4-14.4s6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4M361.8 191.9c0 7.9-6.4 14.4-14.4 14.4s-14.4-6.4-14.4-14.4 6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4M428.5 191.9c0 7.9-6.4 14.4-14.4 14.4s-14.4-6.4-14.4-14.4 6.4-14.4 14.4-14.4c7.9 0 14.4 6.4 14.4 14.4"
    />
  </svg>
);

export default Support;

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import Button from '@mui/material/Button';
import LightTextField from './LightTextField';

const styles = theme => {
  return {
    root: {},
    form: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: '0 30px',
      },
    },
    input: {
      flex: 1,
    },
  };
};

class Register extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    agencyName: '',
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onRegister = async () => {
    const { onSubmit, onSuccess } = this.props;
    if (onSubmit) {
      const response = await onSubmit(
        this.state.firstName,
        this.state.lastName,
        this.state.email,
        this.state.password,
        this.state.agencyName,
      );
      if (onSuccess && (response.status === 200 || response.status === 204)) {
        onSuccess();
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.form}>
          {this.props.withAgency ? (
            <>
              <LightTextField id="agencyName" name="agencyName" onChange={this.handleInputChange} label="Agency Name" />
              <br />
            </>
          ) : (
            undefined
          )}
          <LightTextField className={classes.input} id="firstName" name="firstName" onChange={this.handleInputChange} label="First Name" />
          <br />
          <LightTextField id="lastName" name="lastName" onChange={this.handleInputChange} label="Last Name" />
          <br />
          <LightTextField id="email" name="email" onChange={this.handleInputChange} label="E-mail Address" />
          <br />
          <LightTextField
            id="password"
            name="password"
            onChange={this.handleInputChange}
            label="Password"
            type="password"
            autoComplete="current-password"
          />
        </div>
        <div>
          <Button variant="contained" color="secondary" onClick={this.onRegister}>
            Register
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Register);

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { noop } from 'common';
import { useControlled } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: 40,
    '& input': {
      fontFamily: theme.typography.fontFamily,
      height: '100%',
      border: 0,
      borderRadius: 0,
      padding: props => (props.adornment ? '13px 40px 13px 15px' : '13px 15px'),
      outline: 0,
      borderBottom: '1px solid transparent',
      transition: theme.transitions.create(['border-bottom']),
      '&.filled': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&:focus': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  adornment: {
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    lineHeight: 0,
  },
}));

const Input = props => {
  const { onChange = noop, adornment, placeholder, value: valueProp } = props;
  const classes = useStyles(props);

  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: false,
    name: 'Input',
    state: 'value',
  });

  return (
    <div className={classes.root}>
      <input
        className={value && 'filled'}
        value={value}
        onChange={e => {
          onChange && onChange(e);
          setValue(e.target.value);
        }}
        placeholder={placeholder}
        aria-label={placeholder}
      />
      {adornment && <div className={classes.adornment}>{adornment}</div>}
    </div>
  );
};

export default Input;

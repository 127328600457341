import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useAsyncFn, useDebounce } from 'react-use';
import { reduce } from 'lodash';

import { Button } from '@mui/material';
import InputSelector from './InputSelector';
import ListComponent from './ListComponent';
import { useBuilderStore } from './store';
import { useApi } from 'common/hooks/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& input': {
      border: 0,
      outline: 0,
    },
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    gap: 10,

    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1,
    color: '#fff',

    background: '#525E6A',
    borderRadius: 26,

    height: 'auto',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    alignSelf: 'flex-end',
    margin: 10,
  },
}));

const RegionSelector = () => {
  const classes = useStyles();
  const Api = useApi();
  const [value, setValue] = useState();
  const setFilteredRegions = useBuilderStore(state => state.actions.regions.setFilteredRegions);

  const handleChange = e => setValue(e?.target?.value);
  const [_state, fetchFn] = useAsyncFn(
    async term => {
      // @todo: add pagination / infinite scroll?
      const response = await Api.getRegions({
        // @todo: adding fields makes the include not be included...
        // fields: ["name", "countryRegionCode"],
        include: {
          relation: 'country',
          fields: ['countryCode', 'name'],
        },
        where: {
          name: {
            like: term,
            options: 'i',
          },
          disabled: { neq: true },
        },
      });

      let data = reduce(
        response?.data,
        (acc, region) => {
          const key = region?.country?.countryCode;
          if (!(key in acc)) {
            acc[key] = {
              label: region?.country?.name,
              value: region?.country?.countryCode,
              children: [],
            };
          }
          acc[key].children.push({
            label: region?.name,
            value: region?.countryRegionCode,
            parentValue: key,
          });
          return acc;
        },
        {},
      );
      data = reduce(
        data,
        (acc, country) => {
          acc.push(country);
          (country?.children || []).forEach(child => {
            acc.push(child);
          });
          return acc;
        },
        [],
      );
      setFilteredRegions(data);
    },
    [value, setFilteredRegions, Api],
  );
  useDebounce(
    () => {
      if (value) {
        fetchFn(value);
      } else {
        setFilteredRegions(null);
      }
    },
    300,
    [value, fetchFn],
  );
  return (
    <div className={classes.root}>
      <InputSelector
        text={<input placeholder="Select a Region" onChange={handleChange} aria-label="region input" />}
        label="region selector"
      >
        {setOpen => (
          <div className={classes.paper}>
            <ListComponent />
            <Button className={classes.btn} onClick={() => setOpen(false)}>
              Done
            </Button>
          </div>
        )}
      </InputSelector>
    </div>
  );
};

export default RegionSelector;

import { get, set, map } from 'lodash';
import { ITRVL, JACADA, YELLOW_ZEBRA } from 'itrvl-types';
import logger from 'itrvl-logger';
const log = logger(__filename);

export const getIsDev = () => process.env.NODE_ENV === 'development';

// This is a stand in for a more full featured flagging system
export const FeatureFlags = {
  // editPayments allows the agent to edit payments for an itinerary
  editPayments: true,
  // Allows a custom deposit margin up to max % of the whole trip
  highDepositMargin: {
    agencies: [...YELLOW_ZEBRA],
    max: 50,
  },
  // editAmountsInModals allows the agent to edit sell and deposit amounts in the appropriate modals
  editAmountsInModals: true,
  // Allows agencies to have all agents be content managers
  allContentManagerAgencies: {
    agencies: [...ITRVL],
  },
  showJacadaActivities: {
    // Currently ~showAllActivities with activitySource=[wilderness,jacada].
    agencies: [...ITRVL, ...JACADA],
  },
  showDmcArrangedOnlyAccommodations: {
    allAgencies: true,
    agencies: [...ITRVL, ...JACADA],
  },
  showDmcArrangedOnlyAccommodationsROW: {
    agencies: [...ITRVL, ...JACADA],
  },
  // we need a reset function to get back to initial state
  // once we move all of this inside FeatureProvider, we can do away with this
  reset: () => {
    // Delete any feature flags here
  },
  can: key => {
    return key in FeatureFlags && FeatureFlags[key];
  },
  setupFeatures: ({ agency }) => {
    if (agency) {
      // Set any agency specific feature flags here
    }
  },
  // Load config
  loadConfig: async api => {
    // TODO: We probably want to setup retry for these or shim into the initial page load
    //       with a cache on the express server side
    try {
      const external = await api.loadConfig();
      if (get(external, 'data.err')) {
        log.warn('Error loading external config:', external.data.err);
      } else {
        log.debug('Loaded Config:', external.data);
        map(external.data, (v, k) => {
          if (k !== 'loadConfig') {
            set(FeatureFlags, k, v);
          } else {
            log.warn('Refusing to overwrite loadConfig:', k, v);
          }
        });
      }
    } catch (err) {
      log.warn('Failed to load external config', err);
    }
  },
};

export default FeatureFlags;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import AvailabilityIcon from './icons/Availability';
import { Chip } from '@mui/material';
import { useBuilderStore } from './store';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    cursor: 'pointer',
    '&:hover': {
      '& svg': {
        color: theme.palette.secondary.main,
      },
      '& .show-availability_chip': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '& svg': {
      color: theme.palette.primary.main,
      width: 60,
      willChange: 'color',
      transition: theme.transitions.create(['color']),
    },
    '& .show-availability_chip': {
      cursor: 'pointer',
      willChange: 'background-color',
      transition: theme.transitions.create(['background-color']),
    },
  },
}));

const ShowAvailabilityCard = ({ supplierCode }) => {
  const toggleSupplierAvailability = useBuilderStore(state => state.actions.toggleSupplierAvailability);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={classes.cta}
        onClick={() => toggleSupplierAvailability(supplierCode)}
        role="button"
        aria-label="show availability"
        data-test-id="show availability"
      >
        <AvailabilityIcon />
        <Chip label="Show availability" color="primary" className="show-availability_chip" />
      </div>
    </div>
  );
};

export default ShowAvailabilityCard;

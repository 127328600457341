export default function Section({ label, children, className }) {
  return (
    <section
      style={{
        border: '1px solid #E9E1D7',
        padding: '40px 20px 20px',
        position: 'relative',
        width: '100%',
      }}
      data-test-id={label}
      className={className}
    >
      <div
        style={{
          position: 'absolute',
          left: 20,
          top: 0,
          transform: 'translateY(-50%)',
          padding: '0 20px',
          fontSize: 18,
          fontWeight: 500,
          backgroundColor: 'rgb(247, 244, 241)',
        }}
      >
        {label}
      </div>
      {children}
    </section>
  );
}

import { useInfiniteQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

export function useRegionsSearchPagedQuery({ term, enabled = true, pageSize = 50 }) {
  const Api = useApi();
  return useInfiniteQuery({
    queryKey: ['regions', 'search', { term }],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await Api.regionSearch({
        limit: pageSize,
        skip: pageParam * pageSize,
        term,
      });
      return response?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined;
    },
    enabled,
  });
}

export default function getCampEndpoints(build) {
  return {
    getRegion: build.query({
      query: locationCode => {
        const filter = {
          where: { regionCode: locationCode },
        };
        return `/Regions/?filter=${JSON.stringify(filter)}`;
      },
    }),
  };
}

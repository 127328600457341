import { useMutation } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

export const useUploadMedia = () => {
  const Api = useApi();

  return useMutation(async ({ files, mediaMetadata }) => {
    const res = await Promise.all(
      files.map((file, index) => {
        const { name: userFilename, size, type: mimeType } = file;
        return Api.uploadFile('/MediaContent', {
          ...mediaMetadata[index],
          userFilename,
          size,
          mimeType,
        });
      }),
    );

    return res.map((result, index) => ({
      ...result.data,
      customizationType: mediaMetadata[index].customizationType,
    }));
  });
};

export const useUploadToS3 = () => {
  const Api = useApi();

  return useMutation(async files => {
    const res = await Promise.all(
      files.map(file =>
        Api.uploadToS3(file.data.s3PresignedUrl, file.file, {
          headers: { 'Content-Type': file.file.type },
        }),
      ),
    );
    return res;
  });
};

export const useUpdateMediaContent = () => {
  const Api = useApi();

  return useMutation(async mediaContents => {
    const res = await Promise.all(
      mediaContents.map(mediaContent => {
        const allowedKeys = [
          'id',
          'presentationVisibility',
          'status',
          'userFilename',
          'mimeType',
          'size',
          's3PresignedUrl',
          's3Bucket',
          's3Key',
          'agentId',
          'customizationType',
          'priority',
          'createdBy',
        ];
        const body = Object.keys(mediaContent).reduce((agg, key) => {
          if (allowedKeys.includes(key)) {
            agg[key] = mediaContent[key];
          }
          return agg;
        }, {});
        const { id } = mediaContent;
        return Api.updateMediaContent(id, body);
      }),
    );
    return res.map(result => result.data);
  });
};

export const useDeleteMediaContent = () => {
  const Api = useApi();
  return useMutation(mediaContentId => {
    return Api.deleteMediaContent(`/MediaContent/${mediaContentId}`);
  });
};

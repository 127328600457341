import Model from './Model';

export const NullAgency = {
  admins: [],
  agencyCode: '',
  archived: false,
  featuredPhoto: [],
  featuredVideo: [],
  featuredVideoPreview: [],
  logoDark: [],
  logoLight: [],
  name: '',
  phone: '',
  termsAndConditions: [],
};

export default class Agency extends Model {
  constructor(properties) {
    super(properties, NullAgency);
  }
  get agencyName() {
    return this.name;
  }
}

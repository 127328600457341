const totalObj = (obj, key) => {
  return Object.values(obj).reduce((t, i) => t + Number.parseFloat(key ? i[key] : i), 0);
};

const largestRemainder = (input, totalSeats, type) => {
  if (!Object.keys(input).length) {
    return [];
  }
  // Make sure seats is a number
  totalSeats = Number.parseFloat(totalSeats);

  let tally = [];

  // Get the total votes
  let total = totalObj(input);

  // choose a quota
  let quota;

  if (type === 'droop') {
    quota = Math.floor(total / (totalSeats + 1)) + 1;
  }
  let tooLow = quota <= totalSeats;

  if (type === 'hare' || tooLow) {
    quota = total / totalSeats;
  }

  if (type === 'hagenbach-bischoff') {
    quota = total / (1 + totalSeats);
  }

  if (type === 'imperiali') {
    quota = total / (2 + totalSeats);
  }

  if (tooLow) {
    type = 'hare (was droop)';
  }
  // For each party create an analysis object
  for (let i in input) {
    let votes = Number.parseFloat(input[i]);
    let percentage = votes / total;
    let distribution = percentage * totalSeats;
    let seats, remainder;
    if (type) {
      let seatsFloat = votes / quota;
      seats = Math.floor(seatsFloat);
      remainder = seatsFloat - seats;
    } else {
      seats = Math.floor(distribution);
      remainder = distribution - seats;
    }
    tally.push({
      type,
      party: i,
      votes,
      percentage,
      distribution,
      remainder,
      seats,
      error: distribution - seats,
    });
  }

  // sum the total seats
  total = totalObj(tally, 'seats');

  // check for a difference from the total
  let remainder = totalSeats - total;

  // sort by the largest remainder
  tally.sort((a, b) => {
    return b.remainder - a.remainder;
  });

  // increment up to the full total
  for (let j = 0; j < remainder; j++) {
    tally[j].seats++;
    tally[j].error = tally[j].seats - tally[j].distribution;
  }

  for (let k in tally) {
    tally[k].percentageSeats = tally[k].seats / totalSeats;
  }

  // Sort by allocated seats
  tally.sort((a, b) => {
    return b.percentage - a.percentage;
  });

  return tally;
};

export default largestRemainder;

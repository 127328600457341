export const AUDIT_TRAIL_ACTIONS = {
  CLIENT_LOGIN: 'client_login',
  CLIENT_ENQUIRED_TRIP: 'client_enquired_trip',
  CLIENT_VIEWED_ITINERARY_VIDEO: 'client_viewed_itinerary_video',
  CLIENT_VIEWED_ITINERARY: 'client_viewed_itinerary',
  CLIENT_MADE_ITINERARY_PAYMENT: 'client_made_itinerary_payment',
  CLIENT_DOWNLOADED_ITINERARY_PDF: 'client_downloaded_itinerary_pdf',
  SYSTEM_STATUS_CHANGE_ITINERARY: 'system_status_change_itinerary',
  SYSTEM_COST_CHANGE_ITINERARY: 'system_cost_change_itinerary',
  AGENT_ACCESSED_ITINERARY: 'agent_accessed_itinerary',
  AGENT_ACCESSED_CLIENT: 'agent_accessed_client',
  AGENT_CREATED_CLIENT: 'agent_created_client',
  AGENT_UPDATED_CLIENT: 'agent_updated_client',
  AGENT_ASSIGNED_AGENT: 'agent_assigned_agent',
};

export const AUDIT_TRAIL_TEXT = {
  [AUDIT_TRAIL_ACTIONS.CLIENT_LOGIN]: 'logged in',
  [AUDIT_TRAIL_ACTIONS.CLIENT_ENQUIRED_TRIP]: 'enquired about a trip',
  [AUDIT_TRAIL_ACTIONS.CLIENT_VIEWED_ITINERARY_VIDEO]: 'viewed itinerary video',
  [AUDIT_TRAIL_ACTIONS.CLIENT_VIEWED_ITINERARY]: 'viewed itinerary',
  [AUDIT_TRAIL_ACTIONS.CLIENT_MADE_ITINERARY_PAYMENT]: 'made itinerary payment',
  [AUDIT_TRAIL_ACTIONS.CLIENT_DOWNLOADED_ITINERARY_PDF]: 'downloaded itinerary pdf',
  [AUDIT_TRAIL_ACTIONS.SYSTEM_STATUS_CHANGE_ITINERARY]: 'itinerary status changed',
  [AUDIT_TRAIL_ACTIONS.SYSTEM_COST_CHANGE_ITINERARY]: 'itinerary cost changed',
  [AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_ITINERARY]: 'accessed itinerary',
  [AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_CLIENT]: 'accessed client',
  [AUDIT_TRAIL_ACTIONS.AGENT_CREATED_CLIENT]: 'created client',
  [AUDIT_TRAIL_ACTIONS.AGENT_UPDATED_CLIENT]: 'updated client',
  [AUDIT_TRAIL_ACTIONS.AGENT_ASSIGNED_AGENT]: 'assigned agent',
};

const AUDIT_TRAIL_ACTION_VALUES = Object.entries(AUDIT_TRAIL_ACTIONS).map(([, value]) => value);
const AGENT_ACTION_VALUES = AUDIT_TRAIL_ACTION_VALUES.filter(value => value.indexOf('agent') === 0);
const CLIENT_ACTION_VALUES = AUDIT_TRAIL_ACTION_VALUES.filter(value => value.indexOf('client') === 0);

export const isValidAuditAction = action => AUDIT_TRAIL_ACTION_VALUES.includes(action);

export const isValidAuditActionForRole = (action, role) => {
  switch (role.toLowerCase()) {
    case 'agent':
      return AGENT_ACTION_VALUES.includes(action);
    case 'client':
      return CLIENT_ACTION_VALUES.includes(action);
    case 'partner':
    case 'system':
      return AUDIT_TRAIL_ACTION_VALUES.includes(action);
    default:
      return false;
  }
};

export const isUserValidForAuditAction = (role, userId, agentId, clientId) => {
  switch (role.toLowerCase()) {
    case 'agent':
      return userId === agentId;
    case 'client':
      return userId === clientId;
    case 'partner':
    case 'system':
      return true;
    default:
      return false;
  }
};

import React from 'react';

const Bed = () => (
  <svg viewBox="0 0 420 291.2">
    <path
      d="M392,117.6V52.8C392,22.2,369.8,0,339.2,0H80.8C48.6,0,22.4,23.7,22.4,52.8v70.5C8.8,133,0,148,0,164.8V280
	c0,6.2,5,11.2,11.2,11.2c6.2,0,11.2-5,11.2-11.2v-28h375.2v28c0,6.2,5,11.2,11.2,11.2c6.2,0,11.2-5,11.2-11.2l0-115.2
	C420,143.3,409.1,126.1,392,117.6L392,117.6z M44.8,52.8c0-18.8,18.7-30.4,36-30.4h258.4c18.5,0,30.4,11.9,30.4,30.4V112H364V93.9
	c0-15.7-11.9-26.7-28.8-26.7l-106.8,0c-7.1,0-14.5,2-20.3,5.7c-4.9-3.6-11.1-5.7-18.5-5.7H82.7c-15.7,0-32.3,9.4-32.3,26.7V112h-5.6
	L44.8,52.8z M196,112H72.8V93.9c0-1.9,4.7-4.3,9.9-4.3h106.8c6.4,0,6.4,2.8,6.4,4.3L196,112z M218.4,93.9c0-1.9,4.7-4.3,9.9-4.3
	h106.8c6.4,0,6.4,2.8,6.4,4.3V112H218.4L218.4,93.9z M22.4,164.8c0-18.8,18.7-30.4,36-30.4h308.8c18.5,0,30.4,11.9,30.4,30.4l0,64.8
	H22.4L22.4,164.8z"
      fill="currentColor"
    />
  </svg>
);

export default Bed;

import React from 'react';

const Client = ({ color = 'currentColor', ...rest }) => (
  <svg width="25" height="25" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8.30637 8.61639C9.69145 8.61639 10.79 7.51788 10.79 6.13281C10.79 4.77162 9.66757 3.64923 8.30637 3.64923C6.94518 3.64923 5.84668 4.77162 5.84668 6.13281C5.8228 7.51788 6.94518 8.61639 8.30637 8.61639ZM8.30637 4.7955C9.04667 4.7955 9.64369 5.41639 9.64369 6.13281C9.64369 6.87311 9.04667 7.47012 8.30637 7.47012C7.58996 7.47012 6.96906 6.87311 6.96906 6.13281C6.96906 5.41639 7.58996 4.7955 8.30637 4.7955ZM12.9153 4.7955C13.9661 4.7955 14.8258 3.95968 14.8258 2.88505C14.8258 1.83431 13.9661 0.974609 12.9153 0.974609C11.8407 0.974609 11.0049 1.83431 11.0049 2.88505C11.0049 3.95968 11.8407 4.7955 12.9153 4.7955ZM9.5004 9.38057H7.13623C5.24966 9.38057 3.74519 10.8134 3.74519 12.5806C3.74519 12.9388 4.03175 13.2015 4.41384 13.2015H12.2228C12.6049 13.2015 12.9153 12.9388 12.9153 12.5806C12.9153 10.8134 11.387 9.38057 9.5004 9.38057ZM4.93922 12.0552C5.17802 11.1955 6.0616 10.5268 7.11235 10.5268H9.5004C10.5511 10.5268 11.4347 11.1955 11.6974 12.0552H4.93922ZM13.8467 5.55968H12.39C12.0795 5.55968 11.7929 5.6552 11.5303 5.7746C11.5303 5.894 11.5541 6.01341 11.5541 6.13281C11.5541 6.94475 11.2676 7.68505 10.7661 8.2343H15.5422C15.781 8.2343 15.972 8.04326 15.972 7.80445C15.972 6.56266 15.0168 5.55968 13.8467 5.55968ZM5.05862 6.13281C5.05862 6.01341 5.0825 5.894 5.10638 5.7746C4.84369 5.63132 4.55713 5.55968 4.24668 5.55968H2.78997C1.61982 5.55968 0.688477 6.56266 0.688477 7.80445C0.688477 8.04326 0.85564 8.2343 1.09445 8.2343H5.87056C5.36907 7.68505 5.05862 6.94475 5.05862 6.13281ZM3.74519 4.7955C4.79593 4.7955 5.65563 3.95968 5.65563 2.88505C5.65563 1.83431 4.79593 0.974609 3.74519 0.974609C2.67056 0.974609 1.83474 1.83431 1.83474 2.88505C1.83474 3.95968 2.67056 4.7955 3.74519 4.7955Z"
      fill={color}
    />
  </svg>
);

export default Client;

import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

import { editTransitDetermineFromTo, editTransitIndexSplit } from 'itrvl-types';

export const useTransitSegments = ({ _segment, segments, index, fromOverride, toOverride }) => {
  const Api = useApi();

  const { indexPrev, indexNext } = editTransitIndexSplit({ segments, index });
  const { codeFrom, typeFrom } = editTransitDetermineFromTo(segments[indexPrev], 'From');
  const { codeTo, typeTo } = editTransitDetermineFromTo(segments[indexNext], 'To');

  const query = useQuery({
    queryKey: ['transit-segment', { codeFrom, typeFrom, codeTo, typeTo, fromOverride, toOverride }],
    queryFn: async () => {
      let response;
      if (fromOverride) {
        response = await Api.getTransfersOrig({
          codeFrom,
          typeFrom,
        });
      }
      if (toOverride) {
        response = await Api.getTransfersOrig({
          codeTo,
          typeTo,
        });
      }
      if (!fromOverride && !toOverride && codeFrom && typeFrom && codeTo && typeTo) {
        response = await Api.getTransfersOrig({
          codeFrom,
          typeFrom,
          codeTo,
          typeTo,
        });
      }
      if (!response) return [];
      if (response.length === 1 && response[0] === undefined) return []; // How is Window.GetTransfers returning [undefined] on error?
      return response || [];
    },
    //staleTime: 0, //Number.Infinity,
    //cacheTime: 0, // For dev refresh.
    enabled: true, // Make this false for lazy load.
  });
  return query;
};

export const useTransitAccommodations = ({ _segment, segments, index }) => {
  const Api = useApi();

  const { indexPrev, indexNext } = editTransitIndexSplit({ segments, index, onlyAccommodation: true });
  let { codeFrom, typeFrom } = editTransitDetermineFromTo(segments[indexPrev], 'From');
  let { codeTo, typeTo } = editTransitDetermineFromTo(segments[indexNext], 'To');

  const query = useQuery({
    queryKey: ['transit-accommodation', { codeFrom, typeFrom, codeTo, typeTo }],
    queryFn: async () => {
      let response;
      if (codeFrom && typeFrom && codeTo && typeTo) {
        response = await Api.getTransfers({
          codeFrom,
          typeFrom,
          codeTo,
          typeTo,
        });
      }
      return response || {};
    },
    //staleTime: 0, //Number.Infinity,
    //cacheTime: 0, // For dev refresh.
  });
  return query;
};

export const useTransitPointsQuery = () => {
  const Api = useApi();

  const query = useQuery({
    queryKey: ['transit-points'],
    queryFn: async () => {
      let response;
      // Filter disabled:neq:true?
      response = await Api.transitPoints.fetchList();
      return response || {};
    },
    //staleTime: 0, //Number.Infinity,
    //cacheTime: 0, // For dev refresh.
  });
  return query;
};

export const useTransitPointsSuggestedQuery = ({ supplierCodes }) => {
  const Api = useApi();

  const query = useQuery({
    queryKey: ['transit-points', { supplierCodes }],
    queryFn: async () => {
      let response;
      // Filter disabled:neq:true?
      response = await Api.getCampTransitPoints(supplierCodes);
      return response || {};
    },
    //staleTime: 0, //Number.Infinity,
    //cacheTime: 0, // For dev refresh.
  });
  return query;
};

import React from 'react';

const Boxes = () => (
  <svg viewBox="0 0 14 14">
    <path
      d="M0.4375 2.21875C0.4375 1.45703 1.05273 0.8125 1.84375 0.8125H4.65625C5.41797 0.8125 6.0625 1.45703 6.0625 2.21875V5.03125C6.0625 5.82227 5.41797 6.4375 4.65625 6.4375H1.84375C1.05273 6.4375 0.4375 5.82227 0.4375 5.03125V2.21875ZM1.375 2.21875V5.03125C1.375 5.29492 1.58008 5.5 1.84375 5.5H4.65625C4.89062 5.5 5.125 5.29492 5.125 5.03125V2.21875C5.125 1.98438 4.89062 1.75 4.65625 1.75H1.84375C1.58008 1.75 1.375 1.98438 1.375 2.21875ZM0.4375 9.71875C0.4375 8.95703 1.05273 8.3125 1.84375 8.3125H4.65625C5.41797 8.3125 6.0625 8.95703 6.0625 9.71875V12.5312C6.0625 13.3223 5.41797 13.9375 4.65625 13.9375H1.84375C1.05273 13.9375 0.4375 13.3223 0.4375 12.5312V9.71875ZM1.375 9.71875V12.5312C1.375 12.7949 1.58008 13 1.84375 13H4.65625C4.89062 13 5.125 12.7949 5.125 12.5312V9.71875C5.125 9.48438 4.89062 9.25 4.65625 9.25H1.84375C1.58008 9.25 1.375 9.48438 1.375 9.71875ZM12.1562 0.8125C12.918 0.8125 13.5625 1.45703 13.5625 2.21875V5.03125C13.5625 5.82227 12.918 6.4375 12.1562 6.4375H9.34375C8.55273 6.4375 7.9375 5.82227 7.9375 5.03125V2.21875C7.9375 1.45703 8.55273 0.8125 9.34375 0.8125H12.1562ZM12.1562 1.75H9.34375C9.08008 1.75 8.875 1.98438 8.875 2.21875V5.03125C8.875 5.29492 9.08008 5.5 9.34375 5.5H12.1562C12.3906 5.5 12.625 5.29492 12.625 5.03125V2.21875C12.625 1.98438 12.3906 1.75 12.1562 1.75ZM7.9375 9.71875C7.9375 8.95703 8.55273 8.3125 9.34375 8.3125H12.1562C12.918 8.3125 13.5625 8.95703 13.5625 9.71875V12.5312C13.5625 13.3223 12.918 13.9375 12.1562 13.9375H9.34375C8.55273 13.9375 7.9375 13.3223 7.9375 12.5312V9.71875ZM8.875 9.71875V12.5312C8.875 12.7949 9.08008 13 9.34375 13H12.1562C12.3906 13 12.625 12.7949 12.625 12.5312V9.71875C12.625 9.48438 12.3906 9.25 12.1562 9.25H9.34375C9.08008 9.25 8.875 9.48438 8.875 9.71875Z"
      fill="currentColor"
    />
  </svg>
);

export default Boxes;

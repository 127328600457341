import React from 'react';

import CommonImage from 'common/components/Image';

import { getStaticMediaURL, placeholderUrl } from 'common/helpers/content';

const Image = ({ s3Key }) => {
  return <CommonImage src={s3Key ? getStaticMediaURL(s3Key) : placeholderUrl} />;
};

export default Image;

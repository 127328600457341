import moment from 'moment';
import memoize from 'memoize-one';
import { utcToZonedTime } from 'date-fns-tz';

const dateFormat = 'MMM D, YYYY';

export const momentFormat = (date, format) => moment(date).format(format);

export const formatDate = date => momentFormat(date, dateFormat);

export const formatTime = memoize(date => momentFormat(date, `dddd, MMMM Do YYYY, h:mm:ss a`));

export const utcDate = date => utcToZonedTime(date, 'UTC');

export const dateZero = dateString => {
  const date = new Date(dateString);
  const zeroTime = date.toISOString().slice(0, -14); // Take off `T...Z`.
  return new Date(zeroTime.split('-')[0], zeroTime.split('-')[1] - 1, zeroTime.split('-')[2], 0, 0, 0);
};

const { transform, isArray, camelCase, isObject, isDate, startCase } = require('lodash');

export const toTitleCase = obj => {
  return startCase(camelCase(obj));
};

export const toCamelCase = obj =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) && !isDate(value) ? toCamelCase(value) : value;
  });

import React from 'react';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmModal = ({
  open,
  onCancel,
  onSkip,
  onAccept,
  title,
  text,
  skipText,
  acceptText,
  children,
  inProgress = false,
  'data-test-id': dataTestId,
}) => (
  <Dialog
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={open}
    onClose={onCancel}
    disableEnforceFocus
    data-test-id={dataTestId}
  >
    <DialogTitle id="alert-dialog-title" data-test-id="confirm-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" data-test-id="confirm-dialog-description">
        {text}
      </DialogContentText>
      {children}
    </DialogContent>
    <DialogActions>
      {onCancel && (
        <Button data-test-id="confirm-dialog-cancel" onClick={onCancel} disabled={inProgress} color="secondary">
          Cancel
        </Button>
      )}
      {onSkip && (
        <Button data-test-id="confirm-dialog-skip" onClick={onSkip} disabled={inProgress} color="secondary">
          {skipText}
        </Button>
      )}
      {onAccept && (
        <Button data-test-id="confirm-dialog-accept" onClick={onAccept} disabled={inProgress} color="secondary" autoFocus>
          {acceptText ? acceptText : 'Ok'}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

export default ConfirmModal;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    gap: 10,
    background: '#525E6A',
    borderRadius: 4,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 0.7,
    color: '#FFFFFF',
    '& a': {
      color: '#fff',
    },
  },
});

const InfoBox = props => {
  const classes = useStyles();
  const { text } = props;
  return <div className={classes.root}>{text}</div>;
};

export default InfoBox;

import React from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { TextField } from '@mui/material';

const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: '#fff',
          },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '1px solid #5A5A59',
          },
          '&:after': {
            borderBottom: '2px solid #fff',
          },
        },
      },
      MuiInputLabel: {
        formControl: {
          color: '#fff',
        },
      },
      MuiInputBase: {
        input: {
          paddingLeft: 10,
          color: '#fff',
        },
      },
    },
  }),
);

const LightTextField = props => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <TextField {...props} />
    </ThemeProvider>
  </StyledEngineProvider>
);

export default LightTextField;

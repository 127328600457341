import React from 'react';
import ApiContext from 'common/context/ApiContext';

function withApi(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <ApiContext.Consumer>{context => <WrappedComponent {...this.props} {...context} />}</ApiContext.Consumer>;
    }
  };
}

export default withApi;

import Itinerary from './Itinerary';
import Model from './Model';

export const NullClient = {
  agency: {},
  agent: {},
  client: {},
  contact: {
    communicationPreferences: {
      email: false,
      phoneCall: false,
      videoCall: false,
    },
  },
  emergency: {},
  itineraries: [],
  itineraryOrder: [],
  passport: {},
  preferences: {},
};

export default class Client extends Model {
  constructor(properties) {
    super(properties, NullClient);

    if (this.itineraries) {
      this.itineraries = this.itineraries.map(itinerary => new Itinerary(itinerary));
    }
  }

  get phone() {
    return this.contact.phone;
  }
  get agentName() {
    return this.agent.fullName;
  }
}

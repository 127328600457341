import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { size } from 'lodash';

import ViewSelector from './ViewSelector';
import Search from './Search';
import AccommodationCount from './AccommodationCount';
import PinCount from './PinCount';
import ChangeStartDateSelector from './ChangeStartDateSelector';
import QuoteModeFilter from './QuoteModeFilter';
import CampFilters from './CampFilters';

import { useBuilderStore } from './store';

const useStyles = makeStyles(
  {
    root: {
      maxWidth: 1440,
      width: '100%',
      margin: '0 auto',
      padding: '10px 23px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 18,
      flexWrap: 'wrap-reverse',
      position: 'sticky',
      top: 0,
      backgroundColor: '#f7f4f1',
      zIndex: 2,
    },
    count: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.875,
      color: '#221F20',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      gap: 18,
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    pinChip: {
      // @todo: OpenSans font??
      fontWeight: 700,
      fontSize: 14,
    },
  },
  {
    name: 'SubFilters',
  },
);

const SubFilters = () => {
  const classes = useStyles();
  const pinCount = useBuilderStore(state => size(state.data.pinnedAccommodationMap));
  const term = useBuilderStore(state => state.ui.accommodations.term);
  return (
    <div className={classes.root}>
      {term ? <AccommodationCount /> : pinCount > 0 ? <PinCount /> : <AccommodationCount />}
      <div className={classes.controls}>
        {/* @todo: leaving these here in case we decide to readd them */}
        {/* <CalendarViewSelector /> */}
        {/* <SortBySelector /> */}
        <ChangeStartDateSelector />
        <QuoteModeFilter />
        <CampFilters />
        <ViewSelector />
        <Search />
      </div>
    </div>
  );
};

export default React.memo(SubFilters);
